export const timeoutCallbackWithPromise = (
  duration: number,
  callback: () => void,
): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      callback();
      resolve();
    }, duration);
  });

export const getUniqueValuesByKeyDeep = (obj: object, targetKeys: string[]) => {
  const valuesToReturn = new Set();
  const objsToCheck = [obj];

  while (objsToCheck.length) {
    const currentObj = objsToCheck.pop();

    if (typeof currentObj === 'object' && currentObj !== null) {
      const entries = Object.entries(currentObj);

      for (let i = 0; i < entries.length; i += 1) {
        const [key, value] = entries[i];
        if (targetKeys.includes(key)) valuesToReturn.add(value);
        if (typeof value === 'object' && value !== null)
          objsToCheck.push(value);
      }
    }
  }

  return [...valuesToReturn];
};
