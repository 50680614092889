import {
  POPUP_HARD_CLOSE_STATE,
  SUCCESS_SCREEN,
} from '../../helpers/constants';
import { setPopupCookie } from '../../helpers/events';

const SUCCESS_TIMEOUT = 4500;

export const getPopupElementById = (id: string): HTMLElement | null =>
  document.getElementById(id);

export const getPopupElementByQuerySelector = (
  querySelector: string,
): HTMLElement | null => document.querySelector(querySelector);

export const handleNextScreenTransition = (
  screen: string,
  screensList: string[],
  popupId: number,
  unmount: () => void,
) => {
  const currentScreenIndex = screensList.findIndex(
    (singleScreen: string) => singleScreen === screen,
  );

  const newScreen = screensList[currentScreenIndex + 1];

  // final popup screen has been accepted
  if (!newScreen || newScreen === SUCCESS_SCREEN) {
    setPopupCookie(POPUP_HARD_CLOSE_STATE, popupId);
  }

  if (!newScreen) {
    unmount();
    return;
  }

  // Hide success after
  if (newScreen === SUCCESS_SCREEN)
    setTimeout(() => unmount(), SUCCESS_TIMEOUT);

  return newScreen;
};
