import {
  BLOCK_TYPES,
  BlockPopup,
  getFinalStyleRulesForBlock,
  ScreenSize,
  STYLE_ELEMENT_TYPES,
  STYLE_RULE_NAMES,
} from '@stodge-inc/block-rendering';

export const getTeaserOffset = (
  popup: BlockPopup,
  viewport: ScreenSize,
  teaserWidth: number,
) => {
  const teaserBlock = popup.teaserBlocks.find(
    (b) => b.type === BLOCK_TYPES.TEASER_ROOT,
  );

  if (!teaserBlock) return undefined;

  const iframeInset = getFinalStyleRulesForBlock(
    teaserBlock,
    popup.theme,
    viewport,
  )?.[STYLE_ELEMENT_TYPES.BLOCK]?.[STYLE_RULE_NAMES.TEASER_IFRAME_INSET];
  const insetParts = iframeInset?.split(' ');

  if (!iframeInset || insetParts?.length !== 4) return undefined;

  const [top, right, bottom, left] = insetParts;
  const isCentered = left === '50%';

  return {
    top,
    right,
    bottom,
    left: isCentered ? `calc(50% - ${teaserWidth / 2}px)` : left,
  };
};
