import { html, TemplateResult } from 'lit';
import './style.css';
import { FADE_IN_CLASS, handlePopupKeyDown } from '../../../../../helpers/ui';
import { BLOCK_POPUP_CONTAINER_ID } from '../../constants';

const popupContainerTemplate = (
  content: TemplateResult<1>,
  onExit: unknown,
) => html`
  <div
    aria-label="Marketing popup"
    aria-modal="true"
    role="dialog"
    class=${FADE_IN_CLASS}
    id="${BLOCK_POPUP_CONTAINER_ID}"
    @keydown=${handlePopupKeyDown(onExit)}
  >
    <div id="ps-block-popup__background">
      <div id="ps-block-popup__click-background" @click=${onExit}></div>
      <div id="ps-block-popup__body-container">${content}</div>
    </div>
  </div>
`;

export default popupContainerTemplate;
