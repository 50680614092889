import { captureException } from '@sentry/browser';
import { Popup } from '../types/popup';
import { Theme } from '../types/theme';
import { commonServer, mobilePartial } from './themes';
import getCommonCssVariables from './themes/common';
import desktopBanner from './themes/desktopBanner';
import desktopFullscreen from './themes/desktopFullscreen';
import desktopModal from './themes/desktopModal';
import getDesktopSideImgVariantTheme from './themes/getDesktopSideImgVariantTheme';
import mobileFull from './themes/mobileFull';

const themeToCssVars = (theme: Theme) => {
  let cssVars = ';';
  Object.entries(theme).forEach(([key, val]) => {
    cssVars += `${key}: ${val};`;
  });
  return `:root {${cssVars}}`;
};

export type MapPopupPropertiesToCssVarsArgs = Pick<
  Popup,
  | 'buttonBackgroundColor'
  | 'buttonRadius'
  | 'buttonTextColor'
  | 'font'
  | 'headlineFontSize'
  | 'postHeadlineFontSize'
>;

const mapPopupPropertiesToCssVars = ({
  backgroundColor,
  buttonBackgroundColor,
  buttonRadius,
  buttonTextColor,
  font,
  headlineFontSize,
  popupSize,
  postHeadlineFontSize,
  popupType,
  sideImgUrl,
  styleDefaultVersion,
  textColor,
}: Popup): string | undefined => {
  const commonAndServerThemes = {
    // not deep cloning bc all the props are top-level primitives
    ...getCommonCssVariables(styleDefaultVersion),
    ...commonServer({
      backgroundColor,
      buttonBackgroundColor,
      buttonRadius,
      buttonTextColor,
      headlineFontSize,
      font,
      postHeadlineFontSize,
      textColor,
    }),
  };
  try {
    if (popupType === 'DESKTOP' && popupSize === 'Banner')
      return themeToCssVars({ ...desktopBanner, ...commonAndServerThemes });

    if (popupType === 'DESKTOP' && popupSize === 'Fullscreen')
      return themeToCssVars({ ...desktopFullscreen, ...commonAndServerThemes });

    if (popupType === 'DESKTOP' && sideImgUrl)
      return themeToCssVars({
        ...getDesktopSideImgVariantTheme(styleDefaultVersion),
        ...commonAndServerThemes,
      });

    if (popupType === 'DESKTOP' && !sideImgUrl)
      return themeToCssVars({ ...desktopModal, ...commonAndServerThemes });

    if (popupType === 'MOBILE' && popupSize === 'Full')
      return themeToCssVars({ ...mobileFull, ...commonAndServerThemes });

    if (popupType === 'MOBILE' && popupSize === 'Partial')
      return themeToCssVars({ ...mobilePartial, ...commonAndServerThemes });

    throw new Error('Unable to match theme variant.', {
      cause: `${JSON.stringify({
        popupSize,
        popupType,
        sideImgUrl,
      })}`,
    });
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    captureException(error);
  }
};

export default mapPopupPropertiesToCssVars;
