import { POPUP_NEW_COMPLIANCE_TEXT } from '../../../helpers/constants';
import { Popup } from '../../types/popup';
import { CommonVariantTheme } from '../../types/theme';
import { configStateStore } from '../helpers/stateManager';

// unmapped common CSS var JS theme
const getCommonCssVariables = (
  styleDefaultVersion: Popup['styleDefaultVersion'],
): CommonVariantTheme => {
  const { flags } = configStateStore.getState();
  const hasNewComplianceText = flags
    ?.split(',')
    .includes(POPUP_NEW_COMPLIANCE_TEXT);

  return {
    '--ps-popup-bubble-button-background-color':
      'var(--ps-popup-primary-color)',
    '--ps-popup-bubble-button-border-radius':
      'var(--ps-popup-button-border-radius)',
    '--ps-popup-bubble-button-text-color': 'var(--ps-popup-button-text-color)',
    '--ps-popup-button-gap': '0.25rem',
    '--ps-popup-button-opacity-active': '.6',
    '--ps-popup-button-opacity-hover': '.7',
    '--ps-popup-buttons-wrapper-margin-top': '1.25rem',
    '--ps-popup-close-button-background-color': 'transparent',
    '--ps-popup-container-margin':
      'var(--ps-popup-container-margin-top) var(--ps-popup-container-margin-right) var(--ps-popup-container-margin-bottom) var(--ps-popup-container-margin-left)',
    '--ps-popup-container-padding':
      'var(--ps-popup-container-padding-top) var(--ps-popup-container-padding-right) var(--ps-popup-container-padding-bottom) var(--ps-popup-container-padding-left)',
    '--ps-popup-deactivated-opacity': '.7',
    '--ps-popup-disclaimer-font-size': hasNewComplianceText ? '10px' : '11px',
    '--ps-popup-disclaimer-link-color': 'var(--ps-popup-text-color)',
    '--ps-popup-disclaimer-margin-top': '0.75rem',
    '--ps-popup-error-box-shadow':
      '0 0 0 0.125rem var(--ps-popup-primary-color)',
    '--ps-popup-field-background-color': 'rgba(255, 255, 255, 0.9)',
    '--ps-popup-field-background-color-focus': 'rgba(255, 255, 255, 1)',
    '--ps-popup-field-border':
      '.06125rem solid var(--ps-popup-field-border-color)',
    '--ps-popup-field-border-color': '#9ba6b6',
    '--ps-popup-field-color-checked': 'var(--ps-popup-primary-color, #000000)',
    '--ps-popup-field-exterior-label-text-color': 'var(--ps-popup-text-color)',
    '--ps-popup-field-interior-label-text-color': '#46434F',
    '--ps-popup-field-text-color': '#2F2C35',
    '--ps-popup-field-transition-duration': '.3s',
    '--ps-popup-focus-box-shadow': '0 0 0 0.125rem var(--ps-popup-text-color)',
    '--ps-popup-font-size': '1rem',
    '--ps-popup-form-margin-top': '1.25rem',
    '--ps-popup-header-margin-top': 'unset',
    '--ps-popup-logo-margin-bottom': '1rem',
    '--ps-popup-preheadline-font-size':
      'var(--ps-popup-postheadline-font-size)',
    '--ps-popup-question-gap': '1rem',
    '--ps-popup-submit-button-background-color':
      'var(--ps-popup-primary-color)',
    '--ps-popup-submit-button-text-color': 'var(--ps-popup-button-text-color)',
    '--ps-popup-error-message-font-size': '12px',
    ...(styleDefaultVersion === 2
      ? {
          '--ps-popup-buttons-wrapper-margin-top': '2rem',
          '--ps-popup-disclaimer-margin-top': '1rem',
          '--ps-popup-form-margin-top': '2rem',
          '--ps-popup-header-margin-top': '0.625rem',
          '--ps-popup-logo-margin-bottom': '1.25rem',
        }
      : {}),
  };
};

export default getCommonCssVariables;
