import { captureException } from '@sentry/browser';
import { PopupData } from '@stodge-inc/block-rendering';
import {
  POPUP_CLOSE_MESSAGE_TYPE,
  POPUP_IMPRESSION_MESSAGE_TYPE,
  POPUP_SUBMIT_MESSAGE_TYPE,
  SUBSCRIBER_CREATED_MESSAGE_TYPE,
} from '../../helpers/constants';
import { PopupPageQuestion } from '../types/popup';

const getFormattedPhoneNumber = (form: HTMLFormElement) => {
  const input = form.querySelector('ps-phone-input');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countryData = (input as any).getFormattedInputValue();
  return countryData.phone;
};

const getSubmittedValues = (
  event: SubmitEvent,
  questions: PopupPageQuestion[],
) => {
  const formData = new FormData(event.target as HTMLFormElement);
  const submittedValues: Record<string, FormDataEntryValue> = {};
  const questionNames = questions.map((question) => question.attributeKey);

  formData.forEach((value: FormDataEntryValue, key: string) => {
    // prevent submitting inputs that weren't configured by the shop (e.g. the OTP input)
    if (!questionNames.includes(key)) return;
    // prevent submitting empty values
    if (!formData.get(key)) return;

    if (key === 'phone') {
      submittedValues[key] = getFormattedPhoneNumber(
        event.target as HTMLFormElement,
      );
    } else {
      submittedValues[key] = value;
    }
  });

  return submittedValues;
};

const postProgrammabilityEvent = (
  type: string,
  popupId: number | string,
  popupName: string,
  data: Record<string, unknown> = {},
) => {
  window.parent.postMessage(
    {
      type,
      popupId,
      popupName,
      ...data,
    },
    '*',
  );
};

const postMessageToParent = (
  popupId: number | string,
  popupName: string,
  submittedValues: Record<string, FormDataEntryValue>,
) => {
  postProgrammabilityEvent(POPUP_SUBMIT_MESSAGE_TYPE, popupId, popupName, {
    values: submittedValues,
  });
};

const sendPopupSubmission = (
  getValues: () => Record<string, FormDataEntryValue>,
  popupId: number | string,
  popupName: string,
) => {
  try {
    const submittedValues = getValues();
    if (Object.keys(submittedValues).length > 0) {
      postMessageToParent(popupId, popupName, submittedValues);
    }
  } catch (error) {
    captureException(error);
  }
};

export const postOpenMessage = (
  popupId: number | string,
  popupName: string,
  isReopen: boolean,
) => {
  postProgrammabilityEvent(POPUP_IMPRESSION_MESSAGE_TYPE, popupId, popupName, {
    isReopen,
  });
};

export const postCloseMessage = (
  popupId: number | string,
  popupName: string,
  isHardClose: boolean,
) => {
  postProgrammabilityEvent(POPUP_CLOSE_MESSAGE_TYPE, popupId, popupName, {
    hard: isHardClose,
  });
};

export const postSubscriberCreatedMessage = (
  popupId: number | string,
  popupName: string,
  subscriberId: string,
  couponCode: string | null,
  cashbackUtmCode: string | null,
  autoApplyOfferEnabled: boolean | null,
) => {
  postProgrammabilityEvent(
    SUBSCRIBER_CREATED_MESSAGE_TYPE,
    popupId,
    popupName,
    {
      subscriberId,
      discountCode: couponCode,
      cashbackCode: cashbackUtmCode,
      autoApplyOfferEnabled,
    },
  );
};
export const postSubmitMessage = (
  event: SubmitEvent,
  questions: PopupPageQuestion[],
  popupId: number,
  popupName: string,
) => {
  sendPopupSubmission(
    () => getSubmittedValues(event, questions),
    popupId,
    popupName,
  );
};

interface PostSubmitMessageBlockPopupsProps {
  popupId: string;
  popupName: string;
  data: PopupData;
}

export const postSubmitMessageBlockPopup = ({
  popupId,
  popupName,
  data,
}: PostSubmitMessageBlockPopupsProps) => {
  sendPopupSubmission(
    () => {
      const { subscriberProperties, ...rest } = data;
      const merged = { ...rest, ...subscriberProperties };
      // Remove the 'otp' property if it exists
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { otp, ...finalData } = merged;
      return finalData;
    },
    popupId,
    popupName,
  );
};
