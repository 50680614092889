/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */

import { captureException } from '@sentry/browser';
import { BIS_POPUP_RENDERED_MESSAGE } from './constants';

// Leaving the popup open for longer than this amount of time counts as an engagement
const ENGAGEMENT_DELAY_THRESHOLD = 7000;

const isEngagementEvent = (
  target: HTMLElement,
  rootNode: HTMLElement,
): boolean => {
  if (target.dataset.popupEngagement) {
    return true;
  }

  if (
    target !== rootNode &&
    target.parentNode &&
    (target.parentNode as HTMLElement).tagName !== rootNode.tagName
  ) {
    return isEngagementEvent(target.parentNode as HTMLElement, rootNode);
  }

  return false;
};

export interface EngagementTracker {
  startSession: (rootNode: HTMLElement | null) => void;
  endSession: () => void;
  enableDebug: () => void;
}

export const createEngagementTracker = (
  handleEngagementEvent: () => void,
): EngagementTracker => {
  let rootNode: HTMLElement | null = null;
  let debug = false;
  let viewTimeEngagementTimeout: NodeJS.Timeout;

  const debugMessage = (message: string, ...rest: any[]) => {
    if (debug) {
      console.log(message, ...rest);
    }
  };

  const trackEngagementEvent = () => {
    debugMessage('PS - Engagement event triggered');
    handleEngagementEvent();
    endSession();
  };

  const handlePopupEvent = (clickEvent: MouseEvent | KeyboardEvent) => {
    debugMessage('PS - Popup event detected', clickEvent);

    if (
      rootNode &&
      isEngagementEvent(clickEvent.target as HTMLElement, rootNode)
    ) {
      trackEngagementEvent();
    }
  };

  const endSessionWhenBackInStockRenders = (event: any) => {
    if (event.data === BIS_POPUP_RENDERED_MESSAGE) {
      endSession();
    }
  };

  const startSession = (newRootNode: HTMLElement | null) => {
    rootNode = newRootNode;

    if (!rootNode) {
      const error = new Error(
        'No root node detected, unable to track engagement events.',
      );
      // eslint-disable-next-line no-console
      console.error(error);
      captureException(error);
      return;
    }

    viewTimeEngagementTimeout = setTimeout(
      trackEngagementEvent,
      ENGAGEMENT_DELAY_THRESHOLD,
    );
    rootNode.addEventListener('click', handlePopupEvent);
    rootNode.addEventListener('keydown', handlePopupEvent);
    window.addEventListener('message', endSessionWhenBackInStockRenders);
  };

  const endSession = () => {
    clearTimeout(viewTimeEngagementTimeout);
    rootNode?.removeEventListener('click', handlePopupEvent);
    rootNode?.removeEventListener('keydown', handlePopupEvent);
    window.removeEventListener('message', endSessionWhenBackInStockRenders);
  };

  const enableDebug = () => {
    debug = true;
  };

  return {
    startSession,
    endSession,
    enableDebug,
  };
};
