import { html, TemplateResult } from 'lit';
import { FADE_IN_CLASS } from '../../../../../helpers/ui';
import { BLOCK_POPUP_CONTAINER_ID } from '../../constants';

const teaserContainer = (content: TemplateResult<1>) => html`
  <div id="${BLOCK_POPUP_CONTAINER_ID}" class="${FADE_IN_CLASS} teaser">
    ${content}
  </div>
`;

export default teaserContainer;
