import { captureException } from '@sentry/browser';
import { BlockPopup, CustomFont } from '@stodge-inc/block-rendering';
import { CLOUDFLARE_BUSINESS_PLAN_URL } from '../../../../helpers/utility';
import { camelCaseKeysDeep } from '../../../../services/popup/helper';
import { MayHaveSplitTest } from '../../../../services/popup/types';
import { PageEventTypes, PopupEventTypes } from '../types';

const ANALYTICS_SERVICE_URL = process.env.POPUP_ANALYTICS_SERVICE_URL;

export type PlatformType = 'MOBILE' | 'DESKTOP';

const adaptForV2TargetingLogic = (
  blockPopup: BlockPopup,
): BlockPopup & MayHaveSplitTest => {
  if (blockPopup.splitTest === undefined || blockPopup.splitTest === null) {
    return {
      ...blockPopup,
      splitTest: blockPopup.splitTest,
    };
  }

  return {
    ...blockPopup,
    splitTest: {
      ...blockPopup.splitTest,
      split_test_id: blockPopup.splitTest.id,
      weight: blockPopup.splitTest.distributionPercentage,
    },
  };
};

export const getBlockPopups = async (
  shopId: number,
): Promise<(MayHaveSplitTest & BlockPopup)[]> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/public/block_popups/${shopId}`,
    );
    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }

    const data = await response.json();
    const popups: BlockPopup[] = data.popups.map((p: unknown) =>
      camelCaseKeysDeep(p),
    );

    return popups.map(adaptForV2TargetingLogic);
  } catch (error) {
    captureException(
      new TypeError('Failed to load block popups', { cause: error }),
    );

    return [];
  }
};

export const getCustomFonts = async (shopId: number): Promise<CustomFont[]> => {
  try {
    const response = await fetch(
      `${CLOUDFLARE_BUSINESS_PLAN_URL}/v2/public/block_popups/custom_fonts/${shopId}`,
    );
    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}`);
    }

    const data = await response.json();

    return data.custom_fonts.map((p: CustomFont) => camelCaseKeysDeep(p));
  } catch (error) {
    captureException(
      new TypeError('Failed to load custom fonts', { cause: error }),
    );

    return [];
  }
};

type EventData = {
  country: string | undefined;
  event: PopupEventTypes;
  platform: PlatformType;
  popup_id: string;
  shop_id: number;
  split_test_id?: string;
};

export const postPopupEvent = (
  eventType: PopupEventTypes,
  shopId: number,
  popupId: string,
  country: string | null,
  platform: PlatformType,
  splitTestId?: string,
) => {
  const url = `${ANALYTICS_SERVICE_URL}/api/v1/events`;
  const data: EventData = {
    country: country?.toUpperCase(),
    event: eventType,
    platform,
    popup_id: popupId,
    shop_id: shopId,
  };

  if (splitTestId !== undefined) {
    data.split_test_id = splitTestId;
  }

  // Note for debugging, requests sent with sendBeacon do not appear in the fetch/xhr section of network tab
  // They're shown as separate requests with a type of "ping"
  navigator.sendBeacon(url, JSON.stringify(data));
};

type PageEventData = {
  event: PageEventTypes;
  platform: PlatformType;
  shop_id: number;
};

export const postPageEvent = (
  eventType: PageEventTypes,
  platform: PlatformType,
  shopId: number,
) => {
  const url = `${ANALYTICS_SERVICE_URL}/api/v1/page_events`;
  const data: PageEventData = {
    event: eventType,
    platform,
    shop_id: shopId,
  };

  navigator.sendBeacon(url, JSON.stringify(data));
};
