/* eslint-disable @typescript-eslint/no-explicit-any */

import { html, render } from 'lit';
import { setTag } from '@sentry/browser';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import {
  BIS_POPUP_RENDERED_MESSAGE,
  DEFAULT_EXCLUDED_HOSTS,
  PARTIAL_POPUP_HEIGHT,
  PARTIAL_POPUP_SIZE,
  PARTIAL_POPUP_WIDTH,
  POPUP_EVENT_TYPES,
  POPUP_HARD_CLOSE_STATE,
  POPUP_MOBILE_WIDGET_CONTAINER,
  POPUP_SOFT_CLOSE_STATE,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  SUCCESS_SCREEN,
} from '../../../helpers/constants';
import {
  hideIframe,
  makeIframeVisible,
  removePopupFocusTrap,
  requestFocusedElement,
  resetIframe,
  resizeIframeForFullScreen,
  resizeIframeForSoftClose,
  restoreFocusedElement,
  sendPopupCloseEvent,
  showIframe,
  trapFocusInPopup,
} from '../../../helpers/iframe';
import {
  delayUntilPopupOpen,
  fadeOut,
  focusFirstPopUpInput,
} from '../../../helpers/ui';
import {
  getPopupElementByQuerySelector,
  handleNextScreenTransition,
} from '../../helpers/next-screen-helper';
import {
  impressionEvent,
  setPopupCookie,
  trackPopupEvent,
} from '../../../helpers/events';
import dynamicStyles from '../components/dynamicStyles';
import closedBubble from '../components/closedBubble';
import { clearActivePopupContext } from '../../common/helpers/popupBase';
import {
  generateScreensList,
  getValidCountryNumber,
  loadFont,
} from '../../../helpers/utility';
import { createEngagementTracker } from '../../../helpers/engagementTracker';
import main from '../components/main';
import {
  getFriendlyNameForPopupStatus,
  popupStateStore,
} from '../../common/helpers/stateManager';
import { getPopupForPreview } from '../../../services/popup/helper';
import { getCookieValueByKey } from '../../../helpers/utils';

let lastFocusedElementId: any;
let hasBackInStockPopupRendered = false;

const INITIAL_BUBBLE_SIZE_TIMEOUT = 500;
const MOBILE_BODY_CLASS = 'ps-mobile-widget-close__body';
const MOBILE_BUTTON_CLASS = 'ps-mobile-widget-close__button';

const storeLastFocusedElementId = (elementId: any) => {
  lastFocusedElementId = elementId;
};

const setOpenPopupIframeSize = (popupSize: any) => {
  if (popupSize === PARTIAL_POPUP_SIZE) {
    resetIframe(PARTIAL_POPUP_HEIGHT, PARTIAL_POPUP_WIDTH, {
      top: 'auto',
      left: '0',
      bottom: '0',
      right: 'auto',
    });
  } else {
    resizeIframeForFullScreen(true);
  }
};

const listenForBackInStockPopupMessage = () => {
  window.addEventListener('message', (event) => {
    if (event.data === BIS_POPUP_RENDERED_MESSAGE) {
      hasBackInStockPopupRendered = true;
      hideIframe();
    }
  });
};

const restoreParentFocus = (isOpen: boolean) => {
  if (isOpen) {
    removePopupFocusTrap();
    restoreFocusedElement(lastFocusedElementId);
  }
};

export const renderV2MobilePopup = (args: any) => {
  const {
    isOpen = true,
    shopId,
    currentCountry,
    closeAction,
    bubbleText,
    popupSize,
    screensList,
    screen,
    id: popupId,
    name: popupName,
    engagementTracker,
  } = args;

  const showSuccess = screen === SUCCESS_SCREEN;

  const reRender = (state: any, skipFadeOut = false) => {
    const closeCallback = () => {
      engagementTracker.endSession();
      hideIframe();
      restoreParentFocus(state?.isOpen ?? isOpen);
    };

    if (state === null && skipFadeOut) return closeCallback();

    if (state === null) return fadeOut('ps__container', closeCallback);

    render(renderV2MobilePopup(state), document.body);

    (state.isOpen
      ? () => setOpenPopupIframeSize(popupSize)
      : () =>
          resizeIframeForSoftClose(MOBILE_BODY_CLASS, MOBILE_BUTTON_CLASS))();
  };

  const unmount = () => reRender(null);

  const nextScreen = () => {
    const newScreen = handleNextScreenTransition(
      screen,
      screensList,
      popupId,
      unmount,
    );

    if (!newScreen) return;

    reRender({
      ...args,
      screen: newScreen,
    });

    setTimeout(
      () =>
        focusFirstPopUpInput(
          getPopupElementByQuerySelector(POPUP_MOBILE_WIDGET_CONTAINER),
        ),
      0,
    );
  };

  const handleExit = (unmount: any, skipFadeOut?: boolean) => {
    engagementTracker.endSession();

    if (closeAction === 'Exit' || unmount === true) {
      setPopupCookie(POPUP_HARD_CLOSE_STATE, popupId);
      sendPopupCloseEvent(popupId, popupName, true);
      reRender(null, skipFadeOut);
      return;
    }

    setPopupCookie(POPUP_SOFT_CLOSE_STATE, popupId);
    sendPopupCloseEvent(popupId, popupName, false);

    reRender({
      ...args,
      isOpen: false,
    });
    restoreParentFocus(isOpen);
  };

  const onOpen = () => {
    if (lastFocusedElementId) {
      requestFocusedElement(storeLastFocusedElementId);
    }

    reRender({
      ...args,
      isOpen: true,
    });

    impressionEvent(shopId, currentCountry, popupId, popupName, true);
    trapFocusInPopup();
    delayUntilPopupOpen(() =>
      focusFirstPopUpInput(
        getPopupElementByQuerySelector(POPUP_MOBILE_WIDGET_CONTAINER),
      ),
    );
  };

  const customStyles = dynamicStyles(args);

  // Show closed state
  if (!isOpen) {
    return html`
      ${customStyles}
      ${closedBubble({
        text: bubbleText,
        onOpen,
        onExit: () => handleExit(true),
      })}
    `;
  }

  return html`
    ${customStyles}
    ${main({
      nextScreen,
      handleExit,
      showSuccess,
      ...args,
    })}
  `;
};

export const initializeV2MobilePopup = async (
  popup: any,
  shopId: any,
  statuses: any,
  sessionId: any,
  previewData: any,
  currentCountry: any,
) => {
  clearActivePopupContext();
  listenForBackInStockPopupMessage();

  let isOpen = true;

  setTag('popup_id', popup.id);

  const {
    font,
    collectionAttributes,
    phoneNumberDict = {},
    popupSize,
    excludedHosts = DEFAULT_EXCLUDED_HOSTS,
    id: popupId,
    name: popupName,
  } = popup;

  // Excluded hosts allow us to prevent popup rendering on certain sites
  if (excludedHosts.includes(window.location.host)) return;

  const shortCode = phoneNumberDict.us_short_code;

  const currentPopupStatus = getCookieValueByKey(
    POPUP_STATUS_COOKIE_NAME_PREFIX,
    popupId,
    statuses,
  );

  if (collectionAttributes && collectionAttributes[0] === 'closed') {
    isOpen = false;
  }

  const countryPhoneNumber = getValidCountryNumber(
    currentCountry,
    shortCode,
    phoneNumberDict,
  );

  if (countryPhoneNumber === false) return;

  loadFont(font);

  if (currentPopupStatus === POPUP_SOFT_CLOSE_STATE) {
    isOpen = false;
  }

  const engagementTracker = createEngagementTracker(() =>
    trackPopupEvent(
      POPUP_EVENT_TYPES.ENGAGEMENT,
      shopId,
      popupId,
      currentCountry,
    ),
  );

  // There's not enough room for this mobile popup and the back in stock popup so back in stock takes precedence.
  // This handles the case where a user navigates to an out of stock variant while waiting for delayTimeout
  if (hasBackInStockPopupRendered) {
    return;
  }

  const state = {
    ...popup,
    screen: collectionAttributes[0],
    screensList: generateScreensList(popup),
    countryPhoneNumber,
    shopId,
    isOpen,
    currentCountry,
    sessionId,
    engagementTracker,
    terms: popup.terms ? html`${unsafeHTML(popup.terms)}` : undefined,
  };

  render(renderV2MobilePopup(state), document.body);
  if (isOpen) {
    const popupRootNode = document.querySelector('main');
    engagementTracker.startSession(popupRootNode);
    impressionEvent(shopId, currentCountry, popupId, popupName);
    requestFocusedElement(storeLastFocusedElementId);
    trapFocusInPopup();
    setOpenPopupIframeSize(popupSize);
    delayUntilPopupOpen(() =>
      focusFirstPopUpInput(
        getPopupElementByQuerySelector(POPUP_MOBILE_WIDGET_CONTAINER),
      ),
    );
  }

  if (!isOpen)
    setTimeout(
      () => resizeIframeForSoftClose(MOBILE_BODY_CLASS, MOBILE_BUTTON_CLASS),
      previewData ? 0 : INITIAL_BUBBLE_SIZE_TIMEOUT,
    );

  showIframe();
  makeIframeVisible();
  popupStateStore.updateState({
    id: popup.id,
    status: getFriendlyNameForPopupStatus(currentPopupStatus),
    engagementTracker,
  });
};

export const renderV2MobilePopupPreview = (
  previewData: any,
  shopId: any,
  status: any,
  sessionId: any,
) => {
  const popup = getPopupForPreview(previewData);
  const currentCountry = null;
  initializeV2MobilePopup(
    popup,
    shopId,
    status,
    sessionId,
    previewData,
    currentCountry,
  );
};
