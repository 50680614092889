import {
  INCLUSION_CRITERIA_OPTIONS,
  PLATFORM,
  SCREEN_SIZES,
} from '@stodge-inc/block-rendering';
import {
  POPUP_HARD_CLOSE_STATE,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  SUBSCRIBER_COHORTS,
} from '../../../../helpers/constants';
import { checkPageIncluded } from '../../../../helpers/utility';
import { getCookieValueByKey } from '../../../../helpers/utils';
import { PopupFilter } from './types';
import { isBlockPopup } from '../typeguards';
import { isUserInAllowedCountry } from '../../../../helpers/targeting-utility';

export const pageIncludedFilter: PopupFilter = (popup, context) => {
  const [includedPagesAsString, excludedPagesAsString] = isBlockPopup(popup)
    ? [popup.includedPages.join(','), popup.excludedPages.join(',')]
    : [popup.includedPages, popup.excludedPages];

  return checkPageIncluded(
    context.origin,
    includedPagesAsString,
    excludedPagesAsString,
  );
};

export const countryFilter: PopupFilter = (popup, context) => {
  const { currentCountry } = context;

  if (isBlockPopup(popup)) {
    const { countriesFilter } = popup;

    if (countriesFilter.items.length === 0) {
      return true;
    }

    const isCurrentCountryIncludedInFilterItems =
      countriesFilter.items.includes(currentCountry);

    return countriesFilter.criteria === INCLUSION_CRITERIA_OPTIONS.INCLUDED
      ? isCurrentCountryIncludedInFilterItems
      : !isCurrentCountryIncludedInFilterItems;
  }

  return isUserInAllowedCountry(currentCountry, popup.countriesAllowed);
};

export const statusFilter: PopupFilter = (popup, context) => {
  const status = getCookieValueByKey(
    POPUP_STATUS_COOKIE_NAME_PREFIX,
    popup.id,
    context.statuses,
  );
  return status !== POPUP_HARD_CLOSE_STATE;
};

export const subscriberCohortFilter: PopupFilter = (popup, context) => {
  const { subscriberId } = context;
  const { subscriberCohort } = popup;

  if (subscriberCohort === null) return true;

  const isNewCohort = subscriberCohort === SUBSCRIBER_COHORTS.NEW_SMS_USERS;
  const isExistingCohort =
    subscriberCohort === SUBSCRIBER_COHORTS.EXISTING_SMS_SUBSCRIBERS;

  /* Invalid scenarios:
    - cohort is for new subscribers and the user is already subscribed
    - cohort is for existing subscribers and the user is not subscribed
  */
  const isInvalid =
    (subscriberId && isNewCohort) || (!subscriberId && isExistingCohort);

  return !isInvalid;
};

export const platformFilter: PopupFilter = (popup, context) => {
  if (!isBlockPopup(popup)) {
    // Legacy popups do not have platform targeting, as that's performed at the request level
    return true;
  }
  return (
    popup.platform === PLATFORM.ALL ||
    (popup.platform === PLATFORM.DESKTOP &&
      context.viewport === SCREEN_SIZES.DESKTOP) ||
    (popup.platform === PLATFORM.MOBILE &&
      context.viewport === SCREEN_SIZES.MOBILE)
  );
};

export const generateTriggerTypeFilter =
  (triggerType: string): PopupFilter =>
  (popup) =>
    isBlockPopup(popup)
      ? popup.trigger.type === triggerType
      : !popup.trigger || popup.trigger === triggerType;
