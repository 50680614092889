/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import {
  Block,
  PopupData,
  StepData,
  SDKBlockHelpers,
  RESERVED_DATA_NAMES,
  OMITTED_RESERVED_NAMES,
} from '@stodge-inc/block-rendering';
import { Error, SubmitData, SubmitDataAggregateResult } from '../types';
import { BlockPopup } from '../../../../types/blockPopup';

export const getStepData = (
  popup: BlockPopup,
  popupData: PopupData,
  stepId: string,
): StepData => {
  const blocksOnStep = popup.stepBlocks.filter(
    (block) => block.stepId === stepId,
  );

  return blocksOnStep.reduce<StepData>((acc, block) => {
    const { dataName } = block.config ?? {};
    if (!dataName) return acc;

    const blockData =
      dataName === RESERVED_DATA_NAMES.PHONE ||
      dataName === RESERVED_DATA_NAMES.OTP
        ? popupData[dataName]
        : popupData.subscriberProperties[dataName];

    if (blockData) acc[block.id] = blockData;

    return acc;
  }, {});
};

export const getValidBlockDataOrError = (
  block: Block,
  stepData: StepData,
): { blockData: StepData | null; blockError: string | null } => {
  const { dataName } = block.config ?? {};

  const blockData: StepData = {};

  const blockFormData = stepData[block.id];
  if (dataName && blockFormData) blockData[dataName] = blockFormData;

  // Error
  const blockError = SDKBlockHelpers.validateBlockData(block, blockFormData);

  return {
    blockData: !blockError && Object.keys(blockData).length ? blockData : null,
    blockError,
  };
};

export const validateStepData = (
  blocksOnStep: Block[],
  stepData: StepData,
): {
  data: PopupData;
  errors: Error;
} => {
  const data: PopupData = {
    subscriberProperties: {},
  };

  const errors: Error = {};

  blocksOnStep.forEach((block) => {
    const { blockData, blockError } = getValidBlockDataOrError(block, stepData);
    if (blockError) errors[block.id] = blockError;

    if (blockData) {
      const dataName = Object.keys(blockData)[0];

      const isSubscriberProperty =
        dataName !== RESERVED_DATA_NAMES.PHONE &&
        dataName !== RESERVED_DATA_NAMES.OTP;

      const isOmittedProperty = Object.values<string>(
        OMITTED_RESERVED_NAMES,
      ).includes(dataName);

      if (!isOmittedProperty) {
        if (isSubscriberProperty) {
          data.subscriberProperties[dataName] = blockData[dataName];
        } else {
          data[dataName] = blockData[dataName];
        }
      }
    }
  });

  return {
    data,
    errors,
  };
};

/**
 * Resolves with true if we determine there were serverside errors while
 * fetching, or false if all relevant promises were resolved successfully.
 * Assumes that OTP and phone blocks are not on the same step.
 */
export const submitAndRouteToStep = async ({
  confirmMaskedPhoneNumberFn,
  confirmNetworkEmail,
  optInFn,
  persistAttributesFn,
  validatedStepData = { subscriberProperties: {} },
  verifyOtpFn,
  searchOptInNetwork,
  matchToken,
}: SubmitData): Promise<SubmitDataAggregateResult> => {
  const { phone, otp, subscriberProperties } = validatedStepData;
  const submitDataResult: SubmitDataAggregateResult = {
    hasError: false,
    data: {},
  };

  const promises = [];
  if (Object.keys(subscriberProperties).length)
    promises.push(persistAttributesFn(subscriberProperties));
  if (otp) promises.push(verifyOtpFn(otp));
  if (phone && !matchToken) promises.push(optInFn(phone));
  if (phone && matchToken) promises.push(confirmMaskedPhoneNumberFn());
  if (subscriberProperties.email) {
    // Potential PSOIN actions. Note that these may no-op depending on how the popup is configured.
    promises.push(confirmNetworkEmail());
    promises.push(searchOptInNetwork(subscriberProperties.email));
  }

  const results = await Promise.allSettled(promises);

  results.forEach((result) => {
    if (result.status === 'fulfilled' && result.value) {
      if (result.value.hasError) submitDataResult.hasError = true;
      if (result.value.data) {
        submitDataResult.data = {
          ...submitDataResult.data,
          ...result.value.data,
        };
      }
    }
  });

  return submitDataResult;
};
