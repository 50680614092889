import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, BUTTON_STYLE_ELEMENT, BUTTON_TEXT_ELEMENT, DEFAULT_COLORS, INPUT_STYLE_ELEMENT, OTP_LENGTH_ERROR_MESSAGE, PARAGRAPH_TEXT_ELEMENT, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLES, TEXT_STYLE_ELEMENT, } from '../constants';
import { getSanitizedOtpValue, validateStringLength } from '../utils/data';
import { renderError, renderResendOtpSuccessMessage, } from '../utils/errorOrSuccess';
import { getDraggableAttributeValue } from '../utils/dragdrop';
const MAX_LENGTH = 4;
const DASHES = '–'.repeat(MAX_LENGTH);
const OTP_INPUT_TEXT_ELEMENTS = Object.assign(Object.assign({}, PARAGRAPH_TEXT_ELEMENT), BUTTON_TEXT_ELEMENT);
const OTP_INPUT_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), BUTTON_STYLE_ELEMENT), INPUT_STYLE_ELEMENT), TEXT_STYLE_ELEMENT);
const defaultStyles = {
    [OTP_INPUT_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 8px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [OTP_INPUT_STYLE_ELEMENTS.BUTTON]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '8px 0px 8px 0px',
            [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'underline',
            // private
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
        },
    },
    [OTP_INPUT_STYLE_ELEMENTS.INPUT]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.INPUT_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.LETTER_SPACING]: '0.45em',
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
    [OTP_INPUT_STYLE_ELEMENTS.TEXT]: {
        mobile: {
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.updateStepData = (otp) => {
        popupActions.updateStepData(block.id, otp);
    };
    // eslint-disable-next-line no-param-reassign
    blockActions.getNode = popupActions.getNode;
    // eslint-disable-next-line no-param-reassign
    blockActions.resendOtp = () => { var _a; return (_a = popupActions.resendOtp) === null || _a === void 0 ? void 0 : _a.call(popupActions, block); };
    // eslint-disable-next-line no-param-reassign
    blockActions.goBackToPhoneNumberStep = () => { var _a; return (_a = popupActions.goBackToPhoneNumberStep) === null || _a === void 0 ? void 0 : _a.call(popupActions); };
};
const validateBlockData = (_, data = '') => {
    const lengthError = validateStringLength(getSanitizedOtpValue(data), MAX_LENGTH, OTP_LENGTH_ERROR_MESSAGE);
    return lengthError !== null && lengthError !== void 0 ? lengthError : null;
};
function render(renderData) {
    var _a, _b, _c, _d, _e, _f;
    const { block, blockActions, classes, content, currentStep, environment, popupData, state, } = renderData;
    const inputId = `input-${block.id}`;
    const lastFourDigitsOfPhoneNumber = (_c = (_b = ((_a = popupData === null || popupData === void 0 ? void 0 : popupData.phone) !== null && _a !== void 0 ? _a : popupData === null || popupData === void 0 ? void 0 : popupData.phoneNumberLastFour)) === null || _b === void 0 ? void 0 : _b.slice(-4)) !== null && _c !== void 0 ? _c : '';
    const { block: blockClasses, button: buttonClasses, input: inputClasses, text: textClasses, } = classes;
    const paragraphContent = (_d = content[OTP_INPUT_TEXT_ELEMENTS.PARAGRAPH]) !== null && _d !== void 0 ? _d : '';
    const buttonContent = (_e = content[OTP_INPUT_TEXT_ELEMENTS.BUTTON]) !== null && _e !== void 0 ? _e : '';
    const handleOtpChange = (event) => {
        var _a, _b;
        event.preventDefault();
        if (!event.target)
            return;
        const input = event.target;
        // Remove non-digits, limit to 4 characters
        const formattedValue = input.value.replace(/\D/g, '').slice(0, MAX_LENGTH);
        const hasRequiredCharacters = formattedValue.length === MAX_LENGTH;
        blockActions.updateStepData(formattedValue);
        input.value = formattedValue;
        if (hasRequiredCharacters) {
            (_b = (_a = input
                .closest('form')) === null || _a === void 0 ? void 0 : _a.querySelector('[type="submit"]')) === null || _b === void 0 ? void 0 : _b.click();
        }
    };
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(getDraggableAttributeValue(environment, blockActions))}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${(currentStep === null || currentStep === void 0 ? void 0 : currentStep.name) !== 'Opt-in Network Verify'
        ? html `
            <p
              class=${classMap(textClasses)}
              id="${inputId}-onsite-resend-wrapper"
            >
              <span style="display: block;">${paragraphContent}</span>
              <span style="display: block;">
                <button
                  class=${classMap(buttonClasses)}
                  data-popup-engagement="true"
                  data-skip-initial-focus="true"
                  id="${inputId}-resend-code-button"
                  type="button"
                  @click=${ifDefined(!environment.isDevelopment && blockActions.resendOtp
            ? blockActions.resendOtp
            : undefined)}
                >
                  ${buttonContent}
                </button>
                ${renderResendOtpSuccessMessage(state, inputId)}
              </span>
            </p>
          `
        : html `
            <p
              class=${classMap(textClasses)}
              id="${inputId}-confirmation-message"
              style="margin-bottom: 12px;"
            >
              We&apos;ve sent you a confirmation code to verify your phone
              number${lastFourDigitsOfPhoneNumber
            ? ` ending in ${lastFourDigitsOfPhoneNumber}.`
            : '.'}
            </p>
            <p class=${classMap(textClasses)} id="${inputId}-fix-phone-wrapper">
              <span>Not your phone number?</span>
              <span>
                <button
                  class=${classMap(buttonClasses)}
                  data-popup-engagement="true"
                  id="${inputId}-fix-phone-button"
                  type="button"
                  @click=${ifDefined(!environment.isDevelopment &&
            blockActions.goBackToPhoneNumberStep
            ? blockActions.goBackToPhoneNumberStep
            : undefined)}
                  style="margin: 0"
                >
                  Enter it now
                </button>
                ${renderResendOtpSuccessMessage(state, inputId)}
              </span>
            </p>
            <p
              class=${classMap(textClasses)}
              id="${inputId}-onsite-resend-wrapper"
            >
              <span>${paragraphContent}</span>
              <span>
                <button
                  class=${classMap(buttonClasses)}
                  data-popup-engagement="true"
                  id="${inputId}-resend-code-button"
                  type="button"
                  @click=${ifDefined(!environment.isDevelopment && blockActions.resendOtp
            ? blockActions.resendOtp
            : undefined)}
                  style="margin-top: 0; margin-bottom: 18px"
                >
                  ${buttonContent}
                </button>
                ${renderResendOtpSuccessMessage(state, inputId)}
              </span>
            </p>
          `}
      <input
        aria-describedby=${ifDefined((state === null || state === void 0 ? void 0 : state.error)
        ? `${inputId}-error`
        : `${inputId}-onsite-resend-wrapper`)}
        aria-invalid=${ifDefined(!!(state === null || state === void 0 ? void 0 : state.error))}
        aria-label="We sent you a four digit one-time code to enter below"
        autocomplete="one-time-code"
        class=${classMap(inputClasses)}
        data-popup-engagement="true"
        id=${inputId}
        inputmode="numeric"
        name=${inputId}
        pattern="[0-9]{${MAX_LENGTH}}"
        placeholder=${DASHES}
        type="text"
        @input=${handleOtpChange}
        required=${ifDefined(((_f = block.config) === null || _f === void 0 ? void 0 : _f.required) ? true : undefined)}
      />
      ${renderError(state, inputId)}
    </div>
  `;
}
const otpInputBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: OTP_INPUT_STYLE_ELEMENTS,
    textElements: OTP_INPUT_TEXT_ELEMENTS,
    validateBlockData,
};
export default otpInputBlockHelpers;
