/* eslint-disable no-underscore-dangle */
import { html, LitElement, nothing, TemplateResult } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { DEFAULT_TERMS } from '../../helpers/constants';
import { isKeywordMessageOptIn } from '../helpers/optInMethods';
import {
  PopupPage,
  PopupPageQuestion,
  PopupTemplateProps,
  V2OptInSource,
} from '../types/popup';
import {
  CUSTOM_CSS_SELECTOR_PREFIX,
  CUSTOM_EVENTS,
  ONE_TIME_PASSCODE_CLOSE_BUTTON_TEXT,
  ONE_TIME_PASSCODE_SUBMIT_BUTTON_TEXT,
  QUESTION_TYPES,
  STATIC_PAGE_AUTO_APPLY_TIMEOUT_DURATION,
  STATIC_PAGE_TIMEOUT_DURATION,
} from './constants';
import './customForm.css';
import { CustomFormSubmitController } from './customFormSubmitController';
import './inputs/monthAndDayInput';
import './inputs/phoneInput';
import './inputs/radioGroup';
import './inputs/sdkOnly/oneTimePasscodeInput';
import './inputs/selectInput';
import './inputs/button';
import './inputs/textInput';
import './loader';
import './staticPage';
import { defineElementSafely } from './utils';
import { postSubmitMessage } from './integration';

export class CustomForm extends LitElement {
  declare customFormSubmitController: CustomFormSubmitController;
  declare hasInvalidField: boolean;
  declare onExit: () => void;
  declare page: PopupPage;
  declare popupTemplateProps: PopupTemplateProps;
  declare showDisclaimerText: boolean;
  declare source: V2OptInSource;

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  constructor() {
    super();
    this.hasInvalidField = false;
  }

  static get properties() {
    return {
      hasInvalidField: { type: Boolean },
      onExit: { type: Object },
      page: { type: Object },
      popupTemplateProps: { type: Object },
      showDisclaimerText: { state: true },
      source: { type: String },
    };
  }

  get fields() {
    return this.renderRoot.querySelectorAll('[name]');
  }

  get isSubmittable() {
    return (
      !this.hasInvalidField && !this.customFormSubmitController.isSubmitting
    );
  }

  get submitButtonPrimaryText() {
    return this.customFormSubmitController.isOneTimePasscodeVisible
      ? ONE_TIME_PASSCODE_SUBMIT_BUTTON_TEXT
      : this.page.submitButtonText;
  }

  get submitButtonSecondaryText() {
    return this.customFormSubmitController.isOneTimePasscodeVisible
      ? undefined
      : this.page.secondarySubmitButtonText;
  }

  get closeButtonPrimaryText() {
    return this.customFormSubmitController.isOneTimePasscodeVisible
      ? ONE_TIME_PASSCODE_CLOSE_BUTTON_TEXT
      : this.page.closeButtonText;
  }

  getComponentForQuestionType(
    question: PopupPageQuestion,
  ): TemplateResult | typeof nothing {
    switch (question.type) {
      case QUESTION_TYPES.EMAIL:
        return html`<ps-input
          class="${CUSTOM_CSS_SELECTOR_PREFIX}email-question"
          type="email"
          .question="${question}"
        ></ps-input>`;

      case QUESTION_TYPES.PHONE:
        return isKeywordMessageOptIn(this.popupTemplateProps)
          ? nothing
          : html`<ps-phone-input
              class="${CUSTOM_CSS_SELECTOR_PREFIX}phone-question"
              .question="${question}"
              countryCode="${this.popupTemplateProps.currentCountry}"
              ariaDescribedBy="${CUSTOM_CSS_SELECTOR_PREFIX}disclaimer-text"
              popupType="${this.popupTemplateProps.popupType}"
            ></ps-phone-input>`;

      case QUESTION_TYPES.RADIO:
        return html`<ps-radio-group
          class="${CUSTOM_CSS_SELECTOR_PREFIX}radio-question"
          .question="${question}"
        ></ps-radio-group>`;

      case QUESTION_TYPES.MONTH_AND_DAY:
        return html`<ps-month-and-day-input
          class="${CUSTOM_CSS_SELECTOR_PREFIX}month-and-day-question"
          .question="${question}"
        ></ps-month-and-day-input>`;

      case QUESTION_TYPES.TEXT:
        return html`<ps-input
          class="${CUSTOM_CSS_SELECTOR_PREFIX}text-question"
          type="text"
          .question="${question}"
        ></ps-input>`;
    }
  }

  getSortedQuestions(): PopupPageQuestion[] {
    return this.page.questions.sort((a, b) => {
      if (a.type === QUESTION_TYPES.PHONE) {
        return 1;
      }
      if (b.type === QUESTION_TYPES.PHONE) {
        return -1;
      }

      return 0;
    });
  }

  /**
   * We have a custom event which signals to this component to imperatively
   * validate without user submission or error messages. We use this for custom
   * validation patterns like initially deactivating the submit button on render.
   */
  async handleValidateFormEvent(event: Event) {
    event.stopPropagation();

    const hasInvalidField = Array.from(this.fields).some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (field: any) => !field.checkValidity(),
    );

    this.hasInvalidField = hasInvalidField;
  }

  handleSubmit(event: SubmitEvent) {
    // message post must be first since handleSubmit navigates to the next screen
    postSubmitMessage(
      event,
      this.page.questions,
      this.popupTemplateProps.id as number,
      this.popupTemplateProps.name as string,
    );
    this.customFormSubmitController.handleSubmit(event, this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.id = `${CUSTOM_CSS_SELECTOR_PREFIX}form-wrapper`;

    this.customFormSubmitController = new CustomFormSubmitController(this, {
      page: this.page,
      popupTemplateProps: this.popupTemplateProps,
    });

    this.addEventListener(
      CUSTOM_EVENTS.VALIDATE_FORM,
      this.handleValidateFormEvent,
    );
  }

  protected updated(
    _changedProperties: Map<string | number | symbol, unknown>,
  ): void {
    if (
      _changedProperties.has('page') ||
      _changedProperties.has('popupTemplateProps')
    ) {
      this.customFormSubmitController = new CustomFormSubmitController(this, {
        page: this.page,
        popupTemplateProps: this.popupTemplateProps,
      });
      /* customFormSubmitController's will reinstantiate when its props change,
      however this component still reflects the previous state without
      imperatively rerendering. */
      this.requestUpdate();
    }

    this.showDisclaimerText =
      this.page?.questions.some((q) => q.type === QUESTION_TYPES.PHONE) &&
      !this.customFormSubmitController.isOneTimePasscodeVisible;
  }

  render() {
    return html`
      <header id="ps-widget__header">
        ${this.popupTemplateProps.logoUrl &&
        html`<img
          id="${CUSTOM_CSS_SELECTOR_PREFIX}logo-image"
          src="${this.popupTemplateProps.logoUrl}"
          alt="popup logo"
        />`}
        ${this.page.preHeadline &&
        html`<p id="${CUSTOM_CSS_SELECTOR_PREFIX}pre-headline">
          ${this.page.preHeadline}
        </p>`}
        ${this.page.headline &&
        html`<h4 id="${CUSTOM_CSS_SELECTOR_PREFIX}headline">
          ${this.page.headline}
        </h4>`}
        ${this.page.postHeadline &&
        html`<p id="${CUSTOM_CSS_SELECTOR_PREFIX}post-headline">
          ${this.page.postHeadline}
        </p>`}
      </header>

      <form
        id=${`${CUSTOM_CSS_SELECTOR_PREFIX}form`}
        ?data-static-page-visible=${!!this.customFormSubmitController
          .staticPageMessage}
        @submit="${this.handleSubmit}"
      >
        <fieldset
          id=${`${CUSTOM_CSS_SELECTOR_PREFIX}form-questions-wrapper`}
          ?disabled=${this.customFormSubmitController.isSubmitting}
        >
          ${this.customFormSubmitController.isOneTimePasscodeVisible
            ? html`<ps-one-time-passcode-input
                .hasValidationError=${this.customFormSubmitController
                  .hasValidationError}
                .hasGeneralError=${this.customFormSubmitController
                  .hasGeneralError}
                .shopId=${this.popupTemplateProps.shopId}
                .phoneNumber=${this.customFormSubmitController.phoneNumber}
                .isResendCodeSuccessVisible=${this.customFormSubmitController
                  .isResendCodeSuccessVisible}
              />`
            : repeat(
                this.getSortedQuestions(),
                (question) => question.attributeKey,
                (question) => this.getComponentForQuestionType(question),
              )}
        </fieldset>

        ${this.showDisclaimerText
          ? html`<small id="${CUSTOM_CSS_SELECTOR_PREFIX}disclaimer-text"
              >${this.popupTemplateProps.terms ??
              DEFAULT_TERMS(window.parentHostname as string)}</small
            >`
          : nothing}

        <div id=${`${CUSTOM_CSS_SELECTOR_PREFIX}form-ctas-wrapper`}>
          <ps-button
            .id=${`${CUSTOM_CSS_SELECTOR_PREFIX}submit-button`}
            .isDisabled=${!this.isSubmittable}
            .isLoading=${this.customFormSubmitController.isSubmitting}
            .primaryText=${this.submitButtonPrimaryText}
            .secondaryText=${this.submitButtonSecondaryText}
            .type=${'submit'}
          ></ps-button>

          ${this.page.closeButtonText
            ? html`<ps-button
                .id=${`${CUSTOM_CSS_SELECTOR_PREFIX}close-button`}
                .isDisabled=${this.customFormSubmitController.isSubmitting}
                .isEngagement=${false}
                .primaryText=${this.closeButtonPrimaryText}
                @click=${this.onExit}
              ></ps-button>`
            : nothing}
        </div>

        ${this.customFormSubmitController.staticPageMessage
          ? html`<ps-static-page
              .message=${this.customFormSubmitController.staticPageMessage}
              .nextScreen=${this.popupTemplateProps.nextScreen}
              .timeoutDuration=${this.popupTemplateProps.autoApplyOfferEnabled
                ? STATIC_PAGE_AUTO_APPLY_TIMEOUT_DURATION
                : STATIC_PAGE_TIMEOUT_DURATION}
            ></ps-static-page>`
          : nothing}
      </form>
    `;
  }
}

defineElementSafely('ps-popup-form', CustomForm);
