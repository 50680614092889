import { SCREEN_SIZES } from '@stodge-inc/block-rendering';
import {
  initializeV2ExitIntentPopup,
  initializeV2TimeDelayPopup,
} from '../common/helpers/popupBase';
import { initializeIframeGlobalState } from '../helpers/initialize-popup-helper';
import './desktop.css';
import {
  initializeV2DesktopPopup,
  renderV2DesktopPopupPreview,
} from './render/v2';
import fetchAndInitializeBlockPopups from '../common/block-popups/utils/initializePopups';
import { addSdkEventListeners } from '../common/helpers/sdkEvents';
import { getAllPopups } from '../common/helpers/api';

(async function render() {
  const {
    shopId,
    status: statuses,
    origin,
    sessionId,
    previewData,
    splitTestCookies,
    currentCountry,
    subscriberId,
    uniqueImpressionCookies,
  } = await initializeIframeGlobalState();

  // If previewing in popup editor, we use the previewData to render the popup
  if (previewData) {
    renderV2DesktopPopupPreview(previewData, shopId, statuses, sessionId);
    return;
  }

  const [blockPopups, legacyPopups] = await getAllPopups(
    shopId,
    SCREEN_SIZES.DESKTOP,
  );

  addSdkEventListeners({
    currentCountry,
    origin,
    popups: {
      block: blockPopups,
      legacy: legacyPopups,
    },
    screenSize: SCREEN_SIZES.DESKTOP,
    sessionId,
    shopId,
    splitTestCookies,
    statuses,
    subscriberId,
    uniqueImpressionCookies,
  });

  if (
    await fetchAndInitializeBlockPopups(
      blockPopups,
      statuses,
      currentCountry,
      origin,
      sessionId,
      subscriberId,
      shopId,
      SCREEN_SIZES.DESKTOP,
      splitTestCookies,
      uniqueImpressionCookies,
    )
  )
    return;

  // Fetch all v2 desktop popups
  if (legacyPopups.length === 0) {
    return;
  }

  const openV2Popup = (popup) =>
    initializeV2DesktopPopup(
      popup,
      shopId,
      statuses,
      sessionId,
      previewData,
      currentCountry,
    );

  // intentionally not awaiting here to allow for parallel initialization
  initializeV2TimeDelayPopup(
    legacyPopups,
    statuses,
    origin,
    currentCountry,
    subscriberId,
    splitTestCookies,
    openV2Popup,
  );

  initializeV2ExitIntentPopup(
    legacyPopups,
    statuses,
    origin,
    currentCountry,
    subscriberId,
    splitTestCookies,
    openV2Popup,
  );
})();
