import { TemplateResult, html } from 'lit';
import { FADE_IN_CLASS, handlePopupKeyDown } from '../../../helpers/ui';

export const modalPopupContainer = (
  content: TemplateResult<1>,
  onExit: unknown,
) => html`
  <div
    id="ps-desktop-widget__container"
    class="${FADE_IN_CLASS}"
    aria-modal="true"
    role="dialog"
    aria-labelledby="ps-desktop-widget__headline ps-widget__header"
    aria-describedby="desktop-widget__aria-description"
    @keydown=${handlePopupKeyDown(onExit)}
  >
    <div id="ps-desktop-widget__background">
      <div id="ps-desktop-width__click-background" @click=${onExit}></div>
      <div id="ps-desktop-widget__body-container">${content}</div>
    </div>
  </div>
`;
