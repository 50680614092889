export const timeoutCallbackWithPromise = (
  duration: number,
  callback: () => void,
): Promise<void> =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      callback();
      resolve();
    }, duration);
  });
