/* eslint-disable no-underscore-dangle */

import { LitElement, html } from 'lit';
import { defineElementSafely } from './utils';
import './staticPage.css';
import { PopupTemplateProps } from '../types/popup';

/**
 * Animates in and out. Disconnects by calling nextScreen after timeout.
 */
class StaticPage extends LitElement {
  declare message: string;
  declare nextScreen: PopupTemplateProps['nextScreen'];
  declare timeoutDuration: number;

  private _timeout: number | undefined;

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  static get properties() {
    return {
      message: { type: String },
      nextScreen: { type: Function },
      timeoutDuration: { type: Number },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    // Keeps css animation in sync with timeout
    this.style.setProperty(
      '--_static-page-timeout-duration',
      `${this.timeoutDuration}ms`,
    );

    // Start timeout to call disconnect
    this._timeout = window.setTimeout(() => {
      this.nextScreen();
    }, this.timeoutDuration);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    /* Cleanup timeout if popup is dismissed while the static page is mounted.
    Without this, nextScreen can be called when the timeout ends and force the
    popup to reopen on the next page. */
    if (this._timeout) window.clearTimeout(this._timeout);
  }

  render() {
    return html`<p role="alert">${this.message}</p>`;
  }
}

defineElementSafely('ps-static-page', StaticPage);
