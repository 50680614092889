import { captureException } from '@sentry/browser';
import {
  CASHBACK_CODE_APPLIED_NAME,
  CASHBACK_CODE_EVENT_NAME,
} from '../../helpers/constants';
import { WAIT_FOR_AUTO_APPLY_TIMEOUT_DURATION } from '../forms/constants';

export const attemptAutoApplyFondueCashback = async (cashbackUtmCode: string) =>
  new Promise<void>((resolve) => {
    let fondueTimeout: NodeJS.Timeout;

    const handleFondueSuccessfullyAutoApplied = (event: MessageEvent) => {
      if (event.data === CASHBACK_CODE_APPLIED_NAME) {
        clearTimeout(fondueTimeout);
        resolve();
      }
    };

    // We are about to kick off the process of applying the Fondue promotion, which
    // involves posting messages back and forth between the iframe and the parent
    // page. We need to set a timeout to ensure we don't hang indefinitely if
    // something goes wrong.
    fondueTimeout = setTimeout(() => {
      captureException(
        'Did not receive auto apply success event from Fondue within 2000ms.',
      );
      window.removeEventListener(
        'message',
        handleFondueSuccessfullyAutoApplied,
      );
      resolve();
    }, WAIT_FOR_AUTO_APPLY_TIMEOUT_DURATION);

    // Create the event listener in preparation for the response message from the parent
    window.addEventListener('message', handleFondueSuccessfullyAutoApplied, {
      once: true,
    });

    // Post message to parent to attempt to apply Fondue promotion
    window.parent.postMessage(
      {
        type: CASHBACK_CODE_EVENT_NAME,
        code: cashbackUtmCode,
      },
      '*',
    );
  });
