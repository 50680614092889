import { Marked } from 'marked';
import DOMPurify from 'dompurify';
import { createDirectives, presetDirectiveConfigs, } from 'marked-directive';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { RICH_TEXT_CONTENT_FEATURE_FLAG } from '../constants';
export const textStyleMarkdownDirective = {
    level: 'inline',
    marker: ':',
    renderer(token) {
        var _a, _b, _c, _d, _e;
        const { meta, attrs, text, tokens = [] } = token;
        const styles = {};
        const name = (_a = meta.name) !== null && _a !== void 0 ? _a : '';
        if (name === 'text-style') {
            if (attrs === null || attrs === void 0 ? void 0 : attrs.color) {
                styles.color = ((_b = attrs === null || attrs === void 0 ? void 0 : attrs.color) !== null && _b !== void 0 ? _b : '');
            }
            if (attrs === null || attrs === void 0 ? void 0 : attrs.backgroundColor) {
                styles['background-color'] = ((_c = attrs === null || attrs === void 0 ? void 0 : attrs.backgroundColor) !== null && _c !== void 0 ? _c : '');
            }
            if (attrs === null || attrs === void 0 ? void 0 : attrs.fontSize) {
                styles['font-size'] = `${(_d = attrs === null || attrs === void 0 ? void 0 : attrs.fontSize) !== null && _d !== void 0 ? _d : ''}px`;
            }
            if (attrs === null || attrs === void 0 ? void 0 : attrs.fontFamily) {
                styles['font-family'] = ((_e = attrs === null || attrs === void 0 ? void 0 : attrs.fontFamily) !== null && _e !== void 0 ? _e : '');
            }
        }
        const styleString = Object.entries(styles)
            .reduce((acc, [name, value]) => [...acc, `${name}: ${value}`], [])
            .join('; ');
        const content = tokens.length ? this.parser.parseInline(tokens) : text;
        return styleString.length
            ? `<span style="${styleString}">${content}</span>`
            : false;
    },
};
export const paragraphStyleMarkdownDirective = {
    level: 'container',
    marker: ':::',
    renderer(token) {
        var _a;
        const { meta, attrs, text, tokens = [] } = token;
        const styles = {
            display: 'block',
        };
        const name = (_a = meta.name) !== null && _a !== void 0 ? _a : '';
        if (name === 'paragraph-style') {
            if (attrs === null || attrs === void 0 ? void 0 : attrs.textAlign) {
                styles['text-align'] = attrs.textAlign;
            }
        }
        const styleString = Object.entries(styles)
            .reduce((acc, [name, value]) => [...acc, `${name}: ${value}`], [])
            .join('; ');
        const content = tokens.length ? this.parser.parse(tokens) : text;
        return styleString.length
            ? `<span style="${styleString}">${content}</span>`
            : false;
    },
};
const createMarkedInstance = (id) => {
    const markedInstance = new Marked()
        .use(createDirectives([
        ...presetDirectiveConfigs,
        textStyleMarkdownDirective,
        paragraphStyleMarkdownDirective,
    ]))
        .use({
        renderer: {
            link({ href, text, tokens }) {
                return `<a href="${href}" class="link-${id}" target="_blank">${tokens.length ? this.parser.parseInline(tokens) : text}</a>`;
            },
            paragraph({ tokens }) {
                // Resets the browser-default styling of top/bottom margins on paragraphs
                return `<span style="display: block">${this.parser.parseInline(tokens)}</span>\n`;
            },
        },
        tokenizer: {
            // The following tokenizers are to override the default processing of standard markdown that we're not
            // supporting.
            //
            // When `marked` processes markdown, it tokenizes all of the individual statements into tokens
            // indicating the type, then uses that set of tokens to render each token into HTML.  As part of
            // that tokenization, it will accept a line like `> my content` and convert it to a block quote token
            // with the text content of "my content", removing the MD syntax for indicating it's a block quote.
            //
            // Unfortunately, since we're only supporting a subset of markdown, we need to override the default
            // handling of the unsupported tokens to prevent changing user content.
            //
            // For example:
            //   Without these overrides: `> value` would become "value"
            //   With these overrides: '> value' would become "&gt; value"
            blockquote() {
                return undefined;
            },
            heading() {
                return undefined;
            },
            hr() {
                return undefined;
            },
            list() {
                return undefined;
            },
        },
    });
    return markedInstance;
};
export const markdownToHtml = (markdown, blockId) => {
    const marked = createMarkedInstance(blockId);
    const parsedHtml = marked.parse(markdown, {
        async: false,
        breaks: true,
    });
    const sanitizedHtml = DOMPurify.sanitize(parsedHtml, {
        ALLOWED_TAGS: [
            'a',
            'br',
            'code',
            'del',
            'em',
            'span',
            'strong',
            'p',
            'div',
        ],
        ADD_ATTR: ['target'],
    });
    return sanitizedHtml;
};
export const renderMarkdownContent = (content, block, environment) => {
    var _a;
    const canRenderRichText = !!((_a = environment.enabledFeatureFlags) === null || _a === void 0 ? void 0 : _a.includes(RICH_TEXT_CONTENT_FEATURE_FLAG));
    return canRenderRichText
        ? unsafeHTML(markdownToHtml(content, block.id))
        : unsafeHTML(content);
};
