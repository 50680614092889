import { BlockStateMap } from '@stodge-inc/block-rendering';
import { BlockPopupStatus, BlockPopupStatuses, PopupState } from '../types';
import {
  POPUP_HARD_CLOSE_STATE,
  POPUP_SOFT_CLOSE_STATE,
} from '../../../../helpers/constants';
import { BlockPopup } from '../../../../types/blockPopup';

export const cookieStatusToPopupStatus = (cookieStatus: string | null) => {
  let status: BlockPopupStatus = BlockPopupStatuses.OPEN;

  if (cookieStatus === POPUP_HARD_CLOSE_STATE) {
    status = BlockPopupStatuses.CLOSED;
  }

  if (cookieStatus === POPUP_SOFT_CLOSE_STATE) {
    return BlockPopupStatuses.TEASER;
  }

  return status;
};

const createInitialBlockState = (popup: BlockPopup) => {
  const blockState: BlockStateMap = {};

  // TODO: why are teaser blocks added to initial state?
  popup.teaserBlocks.forEach((block) => {
    blockState[block.id] = {
      error: null,
      isResendOtpSuccessVisible: false,
      selected: false,
    };
  });

  return blockState;
};

export const getInitialPopupState = (
  popup: BlockPopup,
  status: string | null,
): PopupState => {
  const initialBlockState = createInitialBlockState(popup);
  const firstStep =
    popup.steps.find((step) => step.order === 0) ?? popup.steps[0];

  return {
    currentStepId: firstStep.id,
    status: cookieStatusToPopupStatus(status),
    blockState: initialBlockState,
    popupData: {
      subscriberProperties: {},
    },
    stepData: {},
  };
};
