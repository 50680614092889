var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const loadCustomFontFamilies = (fontFamiliesUsedInPopup, customFonts) => __awaiter(void 0, void 0, void 0, function* () {
    const usedCustomFonts = customFonts.filter((c) => fontFamiliesUsedInPopup.includes(c.name));
    const styleTagContent = usedCustomFonts.map((f) => `
        @font-face {
            font-family: '${f.name}';
            src: url('${f.src}');
        }
    `);
    const styleTag = document.createElement('style');
    styleTag.innerText = styleTagContent.join('\n');
    document.head.appendChild(styleTag);
});
