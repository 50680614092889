import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { DEFAULT_COLORS, STYLE_ELEMENT_TYPES, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLES, } from '../../constants';
import { getFlattenedViewportProps, removeSpacesAndLineBreaks, } from '../../utils';
import { getRotationForText } from '../../utils/spinToWin';
export const defaultStyles = {
    [STYLE_ELEMENT_TYPES.TEXT]: {
        mobile: {
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.INSET]: '50% auto auto 50%',
            [STYLE_RULE_NAMES.MARGIN]: 'unset',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
            [STYLE_RULE_NAMES.TRANSFORM_ORIGIN]: 'left center',
            [STYLE_RULE_NAMES.WIDTH]: '50%',
        },
    },
};
const spinToWinText = ({ finalStyleRules, psCssClasses, sections, viewport, }) => {
    var _a, _b, _c, _d, _e;
    const centerRadius = `calc(${(_a = finalStyleRules[STYLE_ELEMENT_TYPES.SPIN_TO_WIN_CENTER]) === null || _a === void 0 ? void 0 : _a.width} / 2)`;
    const mainWheelBorderStyle = (_c = (_b = finalStyleRules[STYLE_ELEMENT_TYPES.SPIN_TO_WIN_MAIN_WHEEL]) === null || _b === void 0 ? void 0 : _b.borderStyle) !== null && _c !== void 0 ? _c : 'none';
    const mainWheelBorderWidth = mainWheelBorderStyle !== 'none'
        ? (_e = (_d = finalStyleRules[STYLE_ELEMENT_TYPES.SPIN_TO_WIN_MAIN_WHEEL]) === null || _d === void 0 ? void 0 : _d.borderWidth) !== null && _e !== void 0 ? _e : '0px'
        : '0px';
    return html `
    ${sections.map(({ styles, text }, index) => {
        var _a;
        /* The order of transform properties in the style attr is important. The
      rotation must come after the translation. */
        const { color = DEFAULT_COLORS.TEXT, fontSize = '16px' } = (_a = getFlattenedViewportProps(viewport, styles)) !== null && _a !== void 0 ? _a : {};
        return html `
        <p
          class=${classMap(psCssClasses)}
          style=${removeSpacesAndLineBreaks(`
            color: ${color};
            font-size: ${fontSize};
            padding-left: calc(${centerRadius} + 1ch);
            padding-right: calc(${mainWheelBorderWidth} + 1ch);
            transform: translate(0, -50%) rotate(${getRotationForText(index, sections)}deg);
          `)}
        >
          ${text}
        </p>
      `;
    })}
  `;
};
export default spinToWinText;
