import { captureException } from '@sentry/browser';
import { POPUP_SUBMIT_MESSAGE_TYPE } from '../../helpers/constants';
import { PopupPageQuestion } from '../types/popup';

const getFormattedPhoneNumber = (form: HTMLFormElement) => {
  const input = form.querySelector('ps-phone-input');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countryData = (input as any).getFormattedInputValue();
  return countryData.phone;
};

const getSubmittedValues = (
  event: SubmitEvent,
  questions: PopupPageQuestion[],
) => {
  const formData = new FormData(event.target as HTMLFormElement);
  const submittedValues: Record<string, FormDataEntryValue> = {};
  const questionNames = questions.map((question) => question.attributeKey);

  formData.forEach((value: FormDataEntryValue, key: string) => {
    // prevent submitting inputs that weren't configured by the shop (e.g. the OTP input)
    if (!questionNames.includes(key)) return;
    // prevent submitting empty values
    if (!formData.get(key)) return;

    if (key === 'phone') {
      submittedValues[key] = getFormattedPhoneNumber(
        event.target as HTMLFormElement,
      );
    } else {
      submittedValues[key] = value;
    }
  });

  return submittedValues;
};

const postMessageToParent = (
  popupId: number,
  popupName: string,
  submittedValues: Record<string, FormDataEntryValue>,
) => {
  window.parent.postMessage(
    {
      type: POPUP_SUBMIT_MESSAGE_TYPE,
      popupId,
      popupName,
      values: {
        ...submittedValues,
      },
    },
    '*',
  );
};

export const postSubmitMessage = (
  event: SubmitEvent,
  questions: PopupPageQuestion[],
  popupId: number,
  popupName: string,
) => {
  try {
    const submittedValues = getSubmittedValues(event, questions);
    if (Object.keys(submittedValues).length > 0) {
      postMessageToParent(popupId, popupName, submittedValues);
    }
  } catch (error) {
    captureException(error);
  }
};
