import { POPUP_TYPES } from '../../helpers/constants';
import { QUESTION_OPT_IN_METHODS, QUESTION_TYPES } from '../forms/constants';
import { getQuestionWithKeyVal } from '../forms/utils';
import { Popup, PopupPage, QuestionOptInMethod } from '../types/popup';

export const getOptInMethod = (popup: Popup): QuestionOptInMethod => {
  // Legacy desktop popups (without pages) will have `twoTouchEnabled` set to true so we need to explicitly check the type here
  const legacyOptInMethod =
    popup.twoTouchEnabled && popup.popupType === POPUP_TYPES.MOBILE
      ? QUESTION_OPT_IN_METHODS.TWO_TOUCH
      : QUESTION_OPT_IN_METHODS.DOUBLE_OPT_IN;

  const allPopupQuestions = (popup?.pages as unknown as PopupPage[])?.flatMap(
    (page) => page?.questions,
  );
  const phoneQuestion = getQuestionWithKeyVal({
    questions: allPopupQuestions,
    key: 'type',
    value: QUESTION_TYPES.PHONE,
  });

  return phoneQuestion?.optInMethod ?? legacyOptInMethod;
};

export const isTwoTouchPopup = (popup: Popup): boolean =>
  getOptInMethod(popup) === QUESTION_OPT_IN_METHODS.TWO_TOUCH;

export const isKeywordMessageOptIn = (popup: Popup): boolean => {
  const optInMethod = getOptInMethod(popup);
  return (
    optInMethod === QUESTION_OPT_IN_METHODS.TWO_TOUCH ||
    optInMethod === QUESTION_OPT_IN_METHODS.LINK_CLICK
  );
};
