import { BlockPopup, ScreenSize } from '@stodge-inc/block-rendering';
import { Popup } from '../../types/popup';
import { getBlockPopups } from '../block-popups/utils/api';
import { getAllPopupsByType } from '../../../services/popup/popupService';

export const getAllPopups = async (
  shopId: number,
  screenSize: ScreenSize,
): Promise<[BlockPopup[], Popup[]]> => {
  let blockPopups: BlockPopup[] = [];
  try {
    blockPopups = await getBlockPopups(shopId);
  } catch (err) {
    // Fail silently
  }

  let legacyPopups: Popup[] = [];
  try {
    legacyPopups = await getAllPopupsByType(shopId, screenSize);
  } catch (err) {
    // Fail silently
  }

  return [blockPopups, legacyPopups];
};
