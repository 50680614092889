/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import {
  Block,
  BlockPopup,
  PopupData,
  StepData,
  SDKBlockHelpers,
  RESERVED_DATA_NAMES,
} from '@stodge-inc/block-rendering';
import { Error, SubmitData, SubmitDataResult } from '../types';

export const getStepData = (
  popup: BlockPopup,
  popupData: PopupData,
  stepId: string,
): StepData => {
  const blocksOnStep = popup.stepBlocks.filter(
    (block) => block.stepId === stepId,
  );

  return blocksOnStep.reduce<StepData>((acc, block) => {
    const { dataName } = block.config ?? {};
    if (!dataName) return acc;

    const blockData =
      dataName === RESERVED_DATA_NAMES.PHONE ||
      dataName === RESERVED_DATA_NAMES.OTP
        ? popupData[dataName]
        : popupData.subscriberProperties[dataName];

    if (blockData) acc[block.id] = blockData;

    return acc;
  }, {});
};

export const getValidBlockDataOrError = (
  block: Block,
  stepData: StepData,
): { blockData: StepData | null; blockError: string | null } => {
  const { dataName } = block.config ?? {};

  const blockData: StepData = {};

  const blockFormData = stepData[block.id];
  if (dataName && blockFormData) blockData[dataName] = blockFormData;

  // Error
  const blockError = SDKBlockHelpers.validateBlockData(block, blockFormData);

  return {
    blockData: !blockError && Object.keys(blockData).length ? blockData : null,
    blockError,
  };
};

export const validateStepData = (
  blocksOnStep: Block[],
  stepData: StepData,
): {
  data: PopupData;
  errors: Error;
} => {
  const data: PopupData = {
    subscriberProperties: {},
  };

  const errors: Error = {};

  blocksOnStep.forEach((block) => {
    const { blockData, blockError } = getValidBlockDataOrError(block, stepData);
    if (blockError) errors[block.id] = blockError;

    if (blockData) {
      const dataName = Object.keys(blockData)[0];

      const isSubscriberProperty =
        dataName !== RESERVED_DATA_NAMES.PHONE &&
        dataName !== RESERVED_DATA_NAMES.OTP;

      if (isSubscriberProperty) {
        data.subscriberProperties[dataName] = blockData[dataName];
      } else {
        data[dataName] = blockData[dataName];
      }
    }
  });

  return {
    data,
    errors,
  };
};

/**
 * Resolves with true if we determine there were serverside errors while
 * fetching, or false if all relevant promises were resolved successfully.
 * Assumes that OTP and phone blocks are not on the same step.
 */
export const submitAndRouteToStep = async ({
  defaultNextStep,
  optInFn,
  persistAttributesFn,
  validatedStepData = { subscriberProperties: {} },
  verifyOtpFn,
}: SubmitData): Promise<SubmitDataResult> => {
  const { phone, otp, subscriberProperties } = validatedStepData;

  /* TODO(subscriber properties): we will likely have unique functions for each
  button action. If we refactor SubmitDataResult, and pass more state manager
  data to the handlers, including which block is being clicked, we can probably
  get away from the need to have a default next step argument and subsequent
  overrides. We ended up with this approach in part due to 1) our desire (at one
  time) to have a single function that submitted all data, and 2) the fact that
  being an existing subscriber changes where you go next. */
  let submitDataResult: SubmitDataResult = {
    hasError: false,
    nextStep: defaultNextStep,
  };

  /* Unless an unexpected exception is thrown (e.g. a network error), all of
  these will fulfill. Order is important here, until we can refactor to
  determine next step and errors more directly in this function. */
  const promises = [];
  if (Object.keys(subscriberProperties).length)
    promises.push(persistAttributesFn(subscriberProperties));
  if (otp) promises.push(verifyOtpFn(otp));
  if (phone) promises.push(optInFn(phone));

  const results = await Promise.allSettled(promises);

  results.forEach((result) => {
    if (result.status === 'fulfilled' && result.value)
      submitDataResult = result.value;
  });

  return submitDataResult;
};
