import { Popup } from '../../types/popup';
import { ServerTheme } from '../../types/theme';

export const pxToRem = (px: string | number) => {
  /* Using numeric 0 in calc can be problematic, so we're converting to 0rem as
  a general rule. */
  if (px === 0 || px === '0') return '0rem';

  // non-infinity, non-NaN number
  if (
    typeof px === 'number' &&
    !Number.isNaN(px) &&
    px !== Number.POSITIVE_INFINITY &&
    px !== Number.NEGATIVE_INFINITY
  )
    return `${px / 16}rem`;

  // non-empty, non-whitespaced string
  if (typeof px === 'string' && px.length > 0)
    return `${parseFloat(px.replace('[^0-9.]', '')) / 16}rem`;

  return '';
};

const getVwFontSize = (fontSize?: number, fallback = ''): string =>
  fontSize ? `${fontSize}vw` : fallback;

// server-mapped CSS JS theme (all popups)
const commonServer = ({
  backgroundColor,
  buttonBackgroundColor,
  buttonRadius,
  buttonTextColor,
  headlineFontSize,
  font,
  postHeadlineFontSize,
  textColor,
}: Partial<Popup>): ServerTheme => ({
  '--ps-popup-background-color': backgroundColor ?? '',
  '--ps-popup-button-border-radius': pxToRem(buttonRadius ?? ''),
  '--ps-popup-button-text-color': buttonTextColor ?? '',
  '--ps-popup-headline-font-size': getVwFontSize(headlineFontSize, '3rem'),
  '--ps-popup-postheadline-font-size': getVwFontSize(
    postHeadlineFontSize,
    '1.5rem',
  ),
  '--ps-popup-font': `${font?.split(':')[0]}, sans-serif`,
  '--ps-popup-primary-color': buttonBackgroundColor ?? '',
  '--ps-popup-text-color': textColor ?? '',
});

export default commonServer;
