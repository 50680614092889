import { SCREEN_SIZES, loadFontsForPopup } from '@stodge-inc/block-rendering';
import {
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  POPUP_HARD_CLOSE_STATE,
  POPUP_UNIQUE_IMPRESSION_COOKIE_NAME,
  POPUP_CONTAINER_ID,
  POPUP_DESKTOP_WIDGET_CONTAINER,
} from '../../../../helpers/constants';
import {
  getCookieValueByKey,
  extractCookieValuesByPrefix,
  preloadImages,
} from '../../../../helpers/utils';
import {
  CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE,
  ACTIVE_POPUP_BEHAVIORS,
} from '../../../../sdk/core/popups/constants';
import { PopupOpenOptions } from '../../../../sdk/core/popups/types';
import { BlockPopupStateManager } from '../../block-popups/BlockPopupStateManager';
import { getCustomFonts } from '../../block-popups/utils/api';
import {
  ActivePopupContext,
  POPUP_STATUS_FRIENDLY_NAMES,
  popupStateStore,
  configStateStore,
} from '../stateManager';
import { FADE_OUT_CLASS } from '../../../../helpers/ui';
import { initializeV2DesktopPopup } from '../../../desktop/render/v2';
import { initializeV2MobilePopup } from '../../../mobile/render/v2';
import { SDKEventListenerConfig } from './types';
import { Popup } from '../../../types/popup';
import { isBlockPopup } from '../typeguards';
import { BlockPopup } from '../../../../types/blockPopup';

const UUID_LENGTH = 36;
const FORCED_OPEN_POPUP_STATUS = '';

const isCustomPopupTriggerMessage = (event: MessageEvent): boolean => {
  if (
    event?.data?.type !== CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE ||
    !event?.data?.id
  ) {
    return false;
  }

  return true;
};

const isPopupTriggerAllowed = (
  option: PopupOpenOptions,
  activePopupState: Partial<ActivePopupContext>,
): boolean => {
  if (
    !activePopupState.id ||
    activePopupState.status === POPUP_STATUS_FRIENDLY_NAMES.HARD_CLOSE
  ) {
    return true;
  }

  switch (option.activePopupBehavior) {
    case ACTIVE_POPUP_BEHAVIORS.ALWAYS_DISMISS:
      return true;
    case ACTIVE_POPUP_BEHAVIORS.DISMISS_WHEN_SOFT_CLOSED:
      return activePopupState.status === POPUP_STATUS_FRIENDLY_NAMES.SOFT_CLOSE;
    case ACTIVE_POPUP_BEHAVIORS.NEVER_DISMISS:
      return false;
  }
};

const removeFadeOutClass = (): void => {
  // The class is present on entirely different elements on mobile and desktop
  const containerWithFadeOutClass =
    document.getElementById(POPUP_CONTAINER_ID) ??
    document.getElementById(POPUP_DESKTOP_WIDGET_CONTAINER);

  containerWithFadeOutClass?.classList.remove(FADE_OUT_CLASS);
};

const openLegacyPopup = async (
  popup: Popup,
  options: PopupOpenOptions,
  config: SDKEventListenerConfig,
) => {
  const { sessionId, shopId, statuses, screenSize } = config;
  if (config.popups.legacy.length === 0) {
    return;
  }

  const { logoUrl, sideImgUrl, backgroundImage } = popup;
  preloadImages([logoUrl, sideImgUrl, backgroundImage]);

  /* If a popup is configured to hard close on exit, .fade-out is added a
    container to animate the popup closing. On mobile, the class is removed
    after a period of time, but on desktop it persists. If you try to
    programmatically open another desktop popup afterwards, it will immediately
    disappear because the container will still have the .fade-out class on it.
    So, to be safe, we preemptively attempt to remove that class before
    initializing a popup via programmatic trigger (regardless of mobile vs.
    desktop). */
  removeFadeOutClass();

  const initializePopup =
    screenSize === SCREEN_SIZES.DESKTOP
      ? initializeV2DesktopPopup
      : initializeV2MobilePopup;

  initializePopup(
    popup,
    shopId,
    options.respectPopupStatus ? statuses : FORCED_OPEN_POPUP_STATUS,
    sessionId,
    null,
    config.currentCountry,
  );
};

const openBlockPopup = async (
  popup: BlockPopup,
  options: PopupOpenOptions,
  config: SDKEventListenerConfig,
) => {
  const {
    sessionId,
    subscriberId,
    shopId,
    statuses,
    screenSize,
    uniqueImpressionCookies,
    currentCountry,
  } = config;

  const { disclaimer, flags } = configStateStore.getState();

  if (!disclaimer) {
    return;
  }

  loadFontsForPopup(popup, screenSize, await getCustomFonts(shopId));

  const uniqueImpressionCookiesObject = extractCookieValuesByPrefix(
    POPUP_UNIQUE_IMPRESSION_COOKIE_NAME,
    uniqueImpressionCookies,
  );

  // The BlockPopupStateManager handles opening of block popups.  We don't need continued access to the
  // instance (just to create it), but SonarCloud complains about instantiating a class without using it.
  // In lieue of just creating an uassigned instance, we'll use an unused variable to silence the warning.

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const _ = new BlockPopupStateManager({
    currentCountry,
    disclaimer,
    enabledFeatureFlags: flags?.split(',') ?? [],
    origin,
    popup,
    sessionId,
    shopId,
    status: options.respectPopupStatus
      ? getCookieValueByKey(
          POPUP_STATUS_COOKIE_NAME_PREFIX,
          popup.id,
          statuses,
        ) ?? null
      : null,
    subscriberId,
    theme: popup.theme,
    viewport: screenSize,
    uniqueImpressionCookies: uniqueImpressionCookiesObject,
  });
};

const isBlockPopupId = (id: string | number): id is string =>
  typeof id === 'string' && id.length === UUID_LENGTH;

const getPopupFromConfig = <T extends string | number>(
  id: T,
  config: SDKEventListenerConfig,
) => {
  if (isBlockPopupId(id)) {
    return config.popups.block.find((popup) => popup.id === id);
  }
  return config.popups.legacy.find((popup) => popup.id === id);
};

const isPopupStatusHardClose = (
  popup: Popup | BlockPopup,
  config: SDKEventListenerConfig,
) => {
  const currentPopupStatus = getCookieValueByKey(
    POPUP_STATUS_COOKIE_NAME_PREFIX,
    popup.id,
    config.statuses,
  );
  return currentPopupStatus === POPUP_HARD_CLOSE_STATE;
};

export const generateOpenPopupListener =
  (config: SDKEventListenerConfig): ((event: MessageEvent) => Promise<void>) =>
  async (event) => {
    const { data: { id, options } = {} } = event;

    if (
      !isCustomPopupTriggerMessage(event) ||
      !isPopupTriggerAllowed(options, popupStateStore.getState())
    ) {
      return;
    }

    const popup = getPopupFromConfig(id, config);

    if (!popup) {
      // eslint-disable-next-line no-console
      console.error(`Popup with id ${id} was not found.`);
      return;
    }

    if (options.respectPopupStatus && isPopupStatusHardClose(popup, config)) {
      return;
    }

    if (isBlockPopup(popup)) {
      await openBlockPopup(popup, options, config);
    } else {
      await openLegacyPopup(popup, options, config);
    }
  };
