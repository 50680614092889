import { html } from 'lit';
import { FADE_IN_CLASS, handlePopupKeyDown } from '../../../helpers/ui';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../../forms/constants';
import '../../forms/customForm';
import email from './email';
import exitButton from './exitButton';
import phone from './phone';
import { sideImage } from './sideImage';
import { modalPopupContainer } from './container';
import { V2_OPT_IN_SOURCES } from '../../../helpers/constants';

// success screen uses phone render function
// because of conditional logic :shrug:
const screenMap = {
  success: phone,
  phone,
  email,
};

const main = ({ screen = 'phone', ...props }) => {
  const { onExit, popupSize } = props;
  const customPage = props.pages?.find((p) => screen === `page_${p.id}`);
  const renderFunction = customPage
    ? (props) =>
        html`<main id="${CUSTOM_CSS_SELECTOR_PREFIX}container">
          ${exitButton({ onClick: props.onExit })}
          <ps-popup-form
            .onExit=${props.onExit}
            .page=${customPage}
            .popupTemplateProps=${props}
            source="${V2_OPT_IN_SOURCES.DESKTOP}"
          ></ps-popup-form>
        </main>`
    : screenMap[screen || 'phone'];

  if (popupSize === 'Banner') {
    return html`
      <div
        id="ps-desktop-widget__container"
        class="${FADE_IN_CLASS}"
        aria-modal="true"
        role="dialog"
        aria-labelledby="ps-desktop-widget__headline ps-widget__header"
        aria-describedby="desktop-widget__aria-description"
        @keydown=${handlePopupKeyDown(onExit)}
      >
        <div
          id="ps-desktop-widget__body"
          style="position: absolute; bottom: 0; left: 0; width: 100vw; max-width: 100vw; margin: 0;"
        >
          <div
            style="display: flex; flex-direction: column; width: 50vw; margin: 0 auto; text-align: center;"
            data-cy="desktop-banner-popup-container"
          >
            ${renderFunction(props)}
          </div>
        </div>
      </div>
    `;
  }

  const renderedModalPopup = html`
    ${sideImage(props)}
    <div id="ps-desktop-widget__body" data-cy="desktop-popup-container">
      ${renderFunction(props)}
    </div>
  `;

  return modalPopupContainer(renderedModalPopup, onExit);
};

export default main;
