import { html, LitElement } from 'lit';
import { PopupPageQuestion } from '../../types/popup';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../constants';
import { defineElementSafely } from '../utils';
import './selectInput.css';

export interface SelectOption {
  label: string;
  value: string;
}

class SelectInput extends LitElement {
  declare question: PopupPageQuestion;
  declare id: string;
  declare label: string;
  declare options: SelectOption[];
  declare required: boolean;
  declare disabled: boolean;
  declare handleChange: (event: Event) => void;

  static get properties() {
    return {
      question: { type: Object },
      id: { type: String },
      label: { type: String },
      options: { type: Array },
      handleChange: { type: Function },
      required: { type: Boolean },
      disabled: { type: Boolean },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.classList.add(`${CUSTOM_CSS_SELECTOR_PREFIX}select-wrapper`);
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  onChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    target.dataset.value = target.value;
    this.handleChange?.(event);
  }

  render() {
    return html`
      <select
        class="${CUSTOM_CSS_SELECTOR_PREFIX}select-input"
        id=${this.id}
        data-popup-engagement="true"
        data-value=""
        @change=${this.onChange}
        ?required=${this.required}
        ?disabled=${this.disabled}
      >
        <option value="">--- Select a ${this.label.toLowerCase()} ---</option>
        ${this.options.map(
          (option) =>
            html`<option value="${option.value}">${option.label}</option>`,
        )}
      </select>
      <label class="${CUSTOM_CSS_SELECTOR_PREFIX}select-label" for="${this.id}"
        >${this.label}</label
      >
      <svg
        class="${CUSTOM_CSS_SELECTOR_PREFIX}select-arrow"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 10"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M.49 2.207a.733.733 0 0 1 1.06.04L5 6.088l3.45-3.84a.733.733 0 0 1 1.06-.04.793.793 0 0 1 .04 1.095l-4 4.45A.739.739 0 0 1 5 8a.739.739 0 0 1-.55-.248l-4-4.45a.793.793 0 0 1 .04-1.095Z"
          clip-rule="evenodd"
        />
      </svg>
    `;
  }
}

defineElementSafely('ps-select', SelectInput);
