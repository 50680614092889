import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, DEFAULT_COLORS, LINK_STYLE_ELEMENT, PARAGRAPH_TEXT_ELEMENT, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLES, TEXT_ELEMENT_TYPES, TEXT_STYLE_ELEMENT, } from '../constants';
import { getDraggableAttributeValue } from '../utils/dragdrop';
import { renderMarkdownContent } from '../utils/markdown';
const TEXT_BLOCK_TEXT_ELEMENTS = Object.assign({}, PARAGRAPH_TEXT_ELEMENT);
const TEXT_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign({}, TEXT_STYLE_ELEMENT), BLOCK_STYLE_ELEMENT), LINK_STYLE_ELEMENT);
const defaultStyles = {
    [TEXT_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [TEXT_BLOCK_STYLE_ELEMENTS.TEXT]: {
        mobile: {
            // default variant uses the following styles
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
        },
    },
    [TEXT_BLOCK_STYLE_ELEMENTS.LINK]: {
        mobile: {
            [STYLE_RULE_NAMES.COLOR]: 'inherit',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.onClick = () => popupActions.handleButtonClick(block);
};
function render(renderData) {
    var _a;
    const { block, blockActions, content, classes, environment } = renderData;
    const { block: blockClasses, text: textClasses } = classes;
    const textContent = (_a = content[TEXT_ELEMENT_TYPES.PARAGRAPH]) !== null && _a !== void 0 ? _a : '';
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(getDraggableAttributeValue(environment, blockActions))}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      <p class=${classMap(textClasses)}>
        ${renderMarkdownContent(textContent, block, environment)}
      </p>
    </div>
  `;
}
const textBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: TEXT_BLOCK_STYLE_ELEMENTS,
    textElements: TEXT_BLOCK_TEXT_ELEMENTS,
};
export default textBlockHelpers;
