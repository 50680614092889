import { PSOIN_MASKED_NUMBER_PREFIX, VIEWPORT_HIERARCHY } from '../constants';
export const camelToKebab = (str) => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
export const removeSpacesAndLineBreaks = (str) => str.replace(/\n\s+/g, '');
/**
 * Flattens the values of nested objects that are keyed by a viewport size in
 * our viewport hierarchy in a singles object (the equivalent of a Map). Stops
 * after flattening a target viewport. If no unique key vals exist after all
 * flattening null is returned instead.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFlattenedViewportProps = (targetViewport, viewportSpecificProps = {}) => {
    var _a;
    let flatProps = {};
    for (let i = 0; i < VIEWPORT_HIERARCHY.length; i += 1) {
        const viewport = VIEWPORT_HIERARCHY[i];
        flatProps = Object.assign(Object.assign({}, flatProps), ((_a = viewportSpecificProps === null || viewportSpecificProps === void 0 ? void 0 : viewportSpecificProps[viewport]) !== null && _a !== void 0 ? _a : {}));
        if (viewport === targetViewport)
            break;
    }
    return Object.keys(flatProps).length ? flatProps : null;
};
export const isPsoinMaskedPhoneNumber = (phoneNumber) => (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.startsWith(PSOIN_MASKED_NUMBER_PREFIX)) &&
    (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.length) === 10;
