import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
const DRAGGING_OVER = 'dragging-over';
const renderDropZone = (renderContext, blockId, dropIndex) => {
    const { popupActions: { handleAddDrop, handleReorderDrop }, environment: { isDraggingAddBlock, isDraggingReorderBlock }, } = renderContext;
    // The drop event won't work without preventing the default for this event too
    const handleDragOver = (event) => event.preventDefault();
    const handleDragEnter = (event) => {
        event.currentTarget.classList.add(DRAGGING_OVER);
    };
    const handleDragLeave = (event) => {
        event.currentTarget.classList.remove(DRAGGING_OVER);
    };
    const handleDrop = (event) => {
        event.currentTarget.classList.remove(DRAGGING_OVER);
        if (isDraggingAddBlock)
            handleAddDrop === null || handleAddDrop === void 0 ? void 0 : handleAddDrop(blockId, dropIndex);
        if (isDraggingReorderBlock)
            handleReorderDrop === null || handleReorderDrop === void 0 ? void 0 : handleReorderDrop(blockId, dropIndex);
    };
    return html `
    <div
      @dragover=${handleDragOver}
      @dragenter=${handleDragEnter}
      @dragleave=${handleDragLeave}
      @drop=${handleDrop}
      class=${classMap({
        dragging: !!(isDraggingAddBlock || isDraggingReorderBlock),
        'drop-zone': true,
    })}
    ></div>
  `;
};
export default renderDropZone;
