import { html } from 'lit';

const emailInput = () => html`
  <div id="ps-desktop-widget__email-input-wrapper" style="width: 100%">
    <input
      aria-label="Enter your email"
      id="ps-desktop-widget__email-input"
      placeholder="your@email.com"
      name="email"
      type="email"
      data-cy="email-input"
      data-popup-engagement="true"
    />
  </div>
`;

export default emailInput;
