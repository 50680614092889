import { PopupPageQuestion } from '../types/popup';

export const defineElementSafely = (
  name: string,
  constructor: CustomElementConstructor,
  options?: ElementDefinitionOptions | undefined,
): void => {
  if (customElements.get(name)) {
    return;
  }

  customElements.define(name, constructor, options);
};

/**
 * Returns the first question with a specific key value pair. Returns
 * `undefined` if none do, or `questions` is `undefined`.
 */
export const getQuestionWithKeyVal = <T extends keyof PopupPageQuestion>({
  key,
  value,
  questions = [],
}: {
  key: T;
  value: PopupPageQuestion[T];
  questions?: PopupPageQuestion[];
}) => questions.find((question) => key in question && question[key] === value);
