import { html, TemplateResult } from 'lit';
import { acceptEmailEvent, collectedEmailEvent } from '../../helpers/events';

interface Props {
  content: TemplateResult;
  currentCountry: string;
  formId: string;
  id: number;
  shopId: number;
  sessionId: string;
  source: string;
  nextScreen: () => void;
}

/**
 * A thin wrapper around the form so we can consolidate submission logic. The markup
 * varies between desktop, mobile partial, and mobile fullscreen, so we're requiring the
 * consumer to pass in all of the inner markup.
 */
const emailForm = ({
  content,
  currentCountry,
  formId,
  id: popupId,
  nextScreen,
  sessionId,
  shopId,
  source,
}: Props) => {
  const onEmailSubmit = (event: SubmitEvent) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const emailValue = formData.get('email') as string;

    acceptEmailEvent(
      shopId,
      emailValue,
      sessionId,
      currentCountry,
      popupId,
      source,
    );

    collectedEmailEvent(emailValue);

    nextScreen();
  };

  return html`
    <form @submit=${onEmailSubmit} id="${formId}">${content}</form>
  `;
};

export default emailForm;
