import { FindPopupConfig, PopupFilter } from './types';
import { filterSplitTestPopups } from '../../../../services/popup/helper';
import { MayHaveSplitTest } from '../../../../services/popup/types';
import { Popup } from '../../../types/popup';
import { BlockPopup } from '../../../../types/blockPopup';

export const findBlockPopup = (
  popups: (MayHaveSplitTest & BlockPopup)[],
  filters: PopupFilter[],
  config: FindPopupConfig,
): BlockPopup | undefined => {
  const filteredSplitTestPopups = filterSplitTestPopups(
    popups,
    config.splitTestCookies,
  );

  const context = {
    ...config,
  };

  const filteredPopups = filteredSplitTestPopups.filter((popup) =>
    filters.every((filter) => filter(popup, context)),
  );

  // Prioritize popups which explicitly configured included pages
  const popupConfiguredExplicitlyForCurrentPage = filteredPopups.find(
    (popup) => popup.includedPages.length > 0,
  );

  return popupConfiguredExplicitlyForCurrentPage ?? filteredPopups[0];
};

export const findLegacyPopup = (
  popups: (MayHaveSplitTest & Popup)[],
  filters: PopupFilter[],
  config: FindPopupConfig,
): Popup | undefined => {
  const filteredSplitTestPopups = filterSplitTestPopups(
    popups,
    config.splitTestCookies,
  );

  const context = {
    ...config,
  };

  const filteredPopups = filteredSplitTestPopups.filter((popup) =>
    filters.every((filter) => filter(popup, context)),
  );

  // Find the first one that has excluded/included pages set then
  // Prefer popups that are explicitly included on the current page
  const popupConfiguredExplicitlyForCurrentPage = filteredPopups.find(
    (popup) => popup.includedPages,
  );

  return popupConfiguredExplicitlyForCurrentPage ?? filteredPopups[0];
};
