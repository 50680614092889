export const POPUP_SDK_INITIALIZED = 'popupSdkInitialized';

export const EVENT_NAME = 'postscriptPopup';
export const CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE = 'triggerPopup';
export const POPUP_FIND_MESSAGE_TYPE = 'findPopup';
export const POPUP_FOUND_MESSAGE_TYPE = 'foundPopup';

export const ACTIVE_POPUP_BEHAVIORS = {
  ALWAYS_DISMISS: 'ALWAYS_DISMISS',
  NEVER_DISMISS: 'NEVER_DISMISS',
  DISMISS_WHEN_SOFT_CLOSED: 'DISMISS_WHEN_SOFT_CLOSED',
} as const;
