/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import {
  Block,
  BlockPopup,
  PopupData,
  Step,
  StepData,
  SDKBlockHelpers,
  SubmitDataResult,
  RESERVED_DATA_NAMES,
  isSubscriberPropertyDataName,
  removeSubscriberPropertyPrefix,
} from '@stodge-inc/block-rendering';

export const getStepData = (
  popup: BlockPopup,
  popupData: PopupData,
  stepId: string,
): StepData => {
  const blocksOnStep = popup.stepBlocks.filter(
    (block) => block.stepId === stepId,
  );

  return blocksOnStep.reduce<StepData>((acc, block) => {
    const { dataName } = block.config ?? {};
    if (!dataName) return acc;

    const blockData = popupData[dataName];
    if (blockData) acc[block.id] = blockData;

    return acc;
  }, {});
};

export const validateStepData = (
  blocksOnStep: Block[],
  stepData: StepData,
): {
  errors: Record<string, string>;
  data: Record<string, any>;
} => {
  const errors: Record<string, string> = {};
  const data: Record<string, any> = {};

  blocksOnStep.forEach((block) => {
    const blockData = stepData[block.id];
    const blockValidationError = SDKBlockHelpers.validateBlockData(
      block,
      blockData,
    );

    if (blockValidationError) {
      errors[block.id] = blockValidationError;
    } else {
      const { dataName } = block.config ?? {};
      if (dataName && blockData) {
        data[dataName] = blockData;
      }
    }
  });

  return {
    errors,
    data,
  };
};

/**
 * Resolves with true if we determine there were serverside errors while
 * fetching, or false if all relevant promises were resolved successfully.
 * Assumes that OTP and phone blocks are not on the same step.
 */
export const submitData = async ({
  defaultNextStep,
  optInFn,
  persistAttributesFn,
  validatedStepData,
  verifyOtpFn,
}: {
  defaultNextStep: Step | null;
  optInFn: (phone: string) => Promise<SubmitDataResult>;
  persistAttributesFn: (
    subscriberProperties: Record<string, string>,
  ) => Promise<SubmitDataResult>;
  validatedStepData: Record<string, any>;
  verifyOtpFn: (otp: string) => Promise<SubmitDataResult>;
}): Promise<SubmitDataResult> => {
  let otp: string | null = null;
  let phone: string | null = null;
  const subscriberProperties: Record<string, string> = {};

  Object.entries(validatedStepData).forEach(([dataName, value]) => {
    /* If entry is empty string, it means user was able to skip the block.
    Thusly, it's not phone or otp data, and it does not need added to the
    subscriber properties object since we don't want to call the subscriber
    attributes endpoint with empty string entries. */
    if (value === '') return;

    // Phone / OTP
    if (dataName === RESERVED_DATA_NAMES.PHONE) phone = value;
    if (dataName === RESERVED_DATA_NAMES.OTP) otp = value;

    // Email and custom subscriber properties
    if (isSubscriberPropertyDataName(dataName)) {
      const trimmedDataName = removeSubscriberPropertyPrefix(dataName);
      subscriberProperties[trimmedDataName] = value;
    }
  });

  let submitDataResult: SubmitDataResult = {
    hasError: false,
    nextStep: defaultNextStep,
  };

  if (Object.keys(subscriberProperties).length > 0)
    submitDataResult = await persistAttributesFn(subscriberProperties);

  if (phone) submitDataResult = await optInFn(phone);

  if (otp) submitDataResult = await verifyOtpFn(otp);

  return submitDataResult;
};
