import { html } from 'lit';
import closeButton from './closeButton';
import exitButton from './exitButton';
import legalText from './legalText';
import logoHeader from './logoHeader';
import phoneInput from './phoneInput';
import submitButton from './submitButton';
import { FADE_IN_CLASS } from '../../../helpers/ui';

const phone = ({
  headlineComponent,
  onExit,
  logoUrl,
  onSubmit,
  showSuccess,
  buttonText,
  closeText,
  popupSize,
  collectionAttributes,
  terms,
}) => html`
  <main
    class="${FADE_IN_CLASS} ${popupSize === 'Partial' &&
    collectionAttributes.includes('email') &&
    'animate-height-in'}"
  >
    ${exitButton({ onClick: onExit })} ${logoHeader({ logoUrl })}
    ${headlineComponent}
    <form @submit=${onSubmit}>
      ${phoneInput({ isVisible: !showSuccess })}
      ${legalText({ isVisible: !showSuccess, terms })}
      ${submitButton({ text: buttonText, isVisible: !showSuccess })}
      ${closeButton({
        text: !showSuccess ? closeText : 'Got It!',
        onClick: !showSuccess ? onExit : () => onExit(true),
      })}
    </form>
  </main>
`;

export default phone;
