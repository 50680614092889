/* eslint-disable no-underscore-dangle */

import { LitElement, html } from 'lit';
import './loader.css';
import { defineElementSafely } from './utils';
import { CUSTOM_CSS_SELECTOR_PREFIX } from './constants';

export class Loader extends LitElement {
  declare color;
  /**
   * For screen readers. Defaults to "Submitting, please wait"
   */
  declare screenReaderMessage;

  constructor() {
    super();
    this.color = 'currentColor';
    this.screenReaderMessage = 'Submitting, please wait';
  }

  static get properties() {
    return {
      color: { type: String },
      screenReaderMessage: { type: String },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.classList.add(`${CUSTOM_CSS_SELECTOR_PREFIX}loader-wrapper`);
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  render() {
    return html`
      <svg
        aria-label=${this.screenReaderMessage}
        class=${`${CUSTOM_CSS_SELECTOR_PREFIX}loader`}
        fill="none"
        role="alert"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#ps_popup_loader_clip_paths)">
          <path
            fill="url(#ps_popup_loader_clip_path_1)"
            fill-rule="evenodd"
            d="M9 16A7 7 0 1 0 9 2a7 7 0 0 0 0 14Zm0 2A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z"
            clip-rule="evenodd"
          />
          <path
            fill="url(#ps_popup_loader_clip_path_2)"
            fill-rule="evenodd"
            d="M9 16A7 7 0 1 0 9 2a7 7 0 0 0 0 14Zm0 2A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z"
            clip-rule="evenodd"
          />
          <path fill=${this.color} d="M9 18a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
        </g>
        <defs>
          <linearGradient
            id="ps_popup_loader_clip_path_1"
            x1="9"
            x2="9"
            y1="0"
            y2="18"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color=${this.color} />
            <stop offset=".135" stop-color=${this.color} />
            <stop offset=".839" stop-color=${this.color} stop-opacity="0" />
            <stop offset="1" stop-color=${this.color} stop-opacity="0" />
          </linearGradient>
          <linearGradient
            id="ps_popup_loader_clip_path_2"
            x1="10.5"
            x2="7"
            y1="0"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".187" stop-color=${this.color} />
            <stop offset=".358" stop-color=${this.color} />
            <stop offset=".424" stop-color=${this.color} stop-opacity="0" />
            <stop offset="1" stop-color=${this.color} stop-opacity="0" />
          </linearGradient>
          <clipPath id="ps_popup_loader_clip_paths">
            <path fill=${this.color} d="M0 0h18v18H0z" />
          </clipPath>
        </defs>
      </svg>
    `;
  }
}

defineElementSafely('ps-loader', Loader);
