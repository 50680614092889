import { html, LitElement } from 'lit';
import { PopupPageQuestion } from '../../types/popup';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../constants';
import { defineElementSafely } from '../utils';
import './radioGroup.css';

class RadioGroup extends LitElement {
  declare question: PopupPageQuestion;

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  static get properties() {
    return {
      question: { type: Object },
    };
  }

  render() {
    const options = (this.question.options ?? []) as string[];

    return html`
      <fieldset data-popup-engagement="true">
        <legend class="${CUSTOM_CSS_SELECTOR_PREFIX}radio-question-legend">
          ${this.question.text}
        </legend>
        ${options.map(
          (option) => html`
            <label class="${CUSTOM_CSS_SELECTOR_PREFIX}radio-question-label">
              <input
                class="${CUSTOM_CSS_SELECTOR_PREFIX}radio-question-input"
                type="radio"
                value="${option}"
                name="${this.question.attributeKey}"
              />
              ${option}
            </label>
          `,
        )}
      </fieldset>
    `;
  }
}

defineElementSafely('ps-radio-group', RadioGroup);
