import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, BLOCK_TYPES, DEFAULT_COLORS, LOGO_STYLE_ELEMENT, PLATFORM_SYNC, STYLE_RULE_NAMES, } from '../constants';
import { getFlattenedViewportProps } from '../utils';
import { getDraggableAttributeValue } from '../utils/dragdrop';
const LOGO_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), LOGO_STYLE_ELEMENT);
const defaultStyles = {
    [LOGO_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.ALIGN_SELF]: 'center',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.WIDTH]: '100px',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [LOGO_BLOCK_STYLE_ELEMENTS.LOGO]: {
        mobile: {
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.HEIGHT]: 'auto',
            [STYLE_RULE_NAMES.MAX_WIDTH]: '100%',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'block',
            [STYLE_RULE_NAMES.PLATFORM_SYNC]: PLATFORM_SYNC.ENABLED,
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
};
function render(renderData) {
    var _a, _b, _c, _d;
    const { blockActions, classes, environment, block, sharedBlockConfig } = renderData;
    const { block: blockClasses, logo: logoClasses } = classes;
    // Prefer instance-level overrides, but if not present, use the shared block config.
    const sharedConfig = getFlattenedViewportProps(environment.viewport, sharedBlockConfig.find((config) => config.type === BLOCK_TYPES.LOGO));
    const src = (_b = (_a = block.config) === null || _a === void 0 ? void 0 : _a.src) !== null && _b !== void 0 ? _b : sharedConfig === null || sharedConfig === void 0 ? void 0 : sharedConfig.src;
    const altText = (_d = (_c = block.config) === null || _c === void 0 ? void 0 : _c.altText) !== null && _d !== void 0 ? _d : sharedConfig === null || sharedConfig === void 0 ? void 0 : sharedConfig.altText;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(getDraggableAttributeValue(environment, blockActions))}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      <img
        class=${classMap(logoClasses)}
        src=${ifDefined(src)}
        alt=${altText !== null && altText !== void 0 ? altText : ''}
      />
    </div>
  `;
}
const logoBlockHelpers = {
    defaultStyles,
    render,
    styleElements: LOGO_BLOCK_STYLE_ELEMENTS,
};
export default logoBlockHelpers;
