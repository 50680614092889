import {
  BlockStateMap,
  PopupData,
  Step,
  StepData,
} from '@stodge-inc/block-rendering';
import { POPUP_EVENT_TYPES } from '../constants';

export type PopupEventTypes =
  typeof POPUP_EVENT_TYPES[keyof typeof POPUP_EVENT_TYPES];

export const BlockPopupStatuses = {
  CLOSED: 'closed',
  OPEN: 'visible',
  TEASER: 'teaser',
} as const;

export type BlockPopupStatus =
  typeof BlockPopupStatuses[keyof typeof BlockPopupStatuses];

export type PopupState = {
  currentStepId: string; // pageId UUID
  status: BlockPopupStatus;
  blockState: BlockStateMap;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepData: StepData;
  popupData: PopupData;
};

/**
 * block id key, message value
 */
export type Error = Record<string, string>;

export type SubmitDataResult = {
  hasError: boolean;
  nextStep: Step | null;
};

export type SubmitData = {
  defaultNextStep: Step | null;
  optInFn: (phone: string) => Promise<SubmitDataResult>;
  persistAttributesFn: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscriberProperties: StepData,
  ) => Promise<SubmitDataResult>;
  validatedStepData: PopupData;
  verifyOtpFn: (otp: string) => Promise<SubmitDataResult>;
};
