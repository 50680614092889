import { html } from 'lit';
import {
  handleKeyDownForClickableDivs,
  FADE_IN_CLASS,
} from '../../../helpers/ui';

const closedBubble = ({ text, onExit, onOpen }) => html`
  <div id="ps-mobile-widget__container" class="close ${FADE_IN_CLASS}">
    <div id="ps-mobile-widget-close__body">
      <div style="position:initial">
        <div
          id="ps-mobile-widget-close__text"
          tabindex="0"
          role="button"
          data-cy="popup-open-button"
          @click=${onOpen}
          @keydown=${handleKeyDownForClickableDivs}
        >
          ${text}
        </div>
        <div
          id="ps-mobile-widget-close__button"
          tabindex="0"
          role="button"
          data-cy="popup-dismiss-button"
          @click=${onExit}
          @keydown=${handleKeyDownForClickableDivs}
          aria-label="Dismiss marketing prompt"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
`;

export default closedBubble;
