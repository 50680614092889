/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import { render } from 'lit';
import {
  Block,
  BlockPopup,
  Step,
  RenderContext,
  StepData,
  Theme,
  ScreenSize,
  Disclaimer,
  BlockState,
  renderPopup,
  SubmitDataResult,
  BLOCK_TYPES,
  CLOSE_BEHAVIORS,
  CONFIG_ONCLICK_ACTIONS,
  RESERVED_DATA_NAMES,
  SCREEN_SIZES,
  getFinalStyleRulesForBlock,
  STYLE_ELEMENT_TYPES,
  STYLE_RULE_NAMES,
} from '@stodge-inc/block-rendering';
import {
  collectedEmailEvent,
  configureSubscriptionEnvironment,
  sendAttributesEvent,
  setPopupCookie,
} from '../../../../helpers/events';
import {
  POPUP_HARD_CLOSE_STATE,
  POPUP_SOFT_CLOSE_STATE,
  POPUP_TRIGGER_TYPES,
  SHOPIFY_DISCOUNT_CODE_COOKIE_NAME,
} from '../../../../helpers/constants';
import {
  blockPopupOptIn,
  resendOTP,
  validateOTP,
} from '../../../../sdk/core/onsite-opt-in/service';

import {
  EngagementTracker,
  createEngagementTracker,
} from '../../../../helpers/engagementTracker';
import {
  resizeIframeForSoftClose,
  resizeIframeForFullScreen,
  showIframe,
  makeIframeVisible,
  hideIframe,
  setCookieOnParentDocument,
  requestFocusedElement,
  restoreFocusedElement,
  trapFocusInPopup,
  removePopupFocusTrap,
} from '../../../../helpers/iframe';
import { attemptAutoApplyFondueCashback } from '../../../helpers/fondue-helpers';
import popupContainerTemplate from '../components/popupContainer';
import teaserContainerTemplate from '../components/teaserContainer';
import {
  BLOCK_POPUP_CONTAINER_ID,
  CLOSE_POPUP_STEP_ID,
  POPUP_EVENT_TYPES,
} from '../constants';
import { getOtpVerifyErrors } from '../../../otpUtils';
import {
  ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR,
  ONE_TIME_PASSCODE_INPUT_VALIDATION_ERROR,
} from '../../../constants';
import { submitFingerprintData } from '../../../../helpers/fingerprint';
import { BlockPopupStatuses, PopupEventTypes, PopupState } from '../types';
import { focusableElementsSelector } from '../../../../helpers/ui';
import { getStepData, validateStepData, submitData } from '../utils/data';
import { getInitialPopupState } from '../utils/popup';
import { hasAnyNextStep, getNextStep } from '../utils/steps';
import { getOptInSource } from '../utils/environment';
import { postPopupEvent } from '../utils/api';
import { timeoutCallbackWithPromise } from '../utils';
import { getTeaserOffset } from '../utils/teaser';

type BlockPopupStateManagerArgs = {
  currentCountry: string | null;
  disclaimer: Disclaimer;
  origin: string;
  popup: BlockPopup;
  sessionId: string;
  shopId: number;
  status: string | null;
  subscriberId: string | null;
  theme: Theme;
  viewport: ScreenSize;
};
/*
  This class represents basically the entire state of the popup.
  We determine here if we should show the popup, the teaser, or nothing. If we show the popup,
  we also determine which page to render, and we track all data that the user types into
  the popup here, among other things. If you need to make changes to how block popups function as a whole, you are almost
  certainly in the right place. If you need to make changes to how a specific block works, you
  probably want to look at the block's implementation file instead.
*/

export class BlockPopupStateManager {
  private previousState: PopupState | null;

  currentCountry: string | null;
  disclaimer: Disclaimer;
  engagementTracker: EngagementTracker;
  isLoading = false;
  lastFocusedIframeElementId: string | null = null;
  origin: string;
  popupContainer: HTMLDivElement | null = null;
  popup: BlockPopup;
  sessionId: string;
  shopId: number;
  state: PopupState;
  subscriberId: string | null;
  viewport: ScreenSize;

  constructor({
    currentCountry,
    disclaimer,
    origin,
    popup,
    sessionId,
    shopId,
    status,
    subscriberId,
    viewport,
  }: BlockPopupStateManagerArgs) {
    this.currentCountry = currentCountry;
    this.disclaimer = disclaimer;
    this.engagementTracker = createEngagementTracker(() =>
      this.trackAnalyticsEvent(POPUP_EVENT_TYPES.ENGAGEMENT),
    );
    this.origin = origin;
    this.popup = popup;
    this.previousState = null;
    this.sessionId = sessionId;
    this.shopId = shopId;
    this.state = getInitialPopupState(popup, status);
    this.subscriberId = subscriberId;
    this.viewport = viewport;

    this.preloadImages();
    this.prepareTrigger();
  }

  setState(stateUpdates: Partial<PopupState>) {
    this.previousState = { ...this.state };
    this.state = {
      ...this.state,
      ...stateUpdates,
    };

    this.render();
  }

  setBlockState(blockId: string, newState: Partial<BlockState>) {
    const newBlockStateMap = { ...this.state.blockState };
    newBlockStateMap[blockId] = { ...newBlockStateMap[blockId], ...newState };
    this.setState({ blockState: newBlockStateMap });
  }

  setBlockErrors(errors: Record<string, string | null>) {
    const newBlockStateMap = { ...this.state.blockState };
    Object.entries(errors).forEach(([blockId, error]) => {
      newBlockStateMap[blockId] = {
        error,
        isResendOtpSuccessVisible: false,
        selected: false,
      };
    });
    this.setState({ blockState: newBlockStateMap });
  }

  preloadImages() {
    // No op for now
  }

  prepareTrigger() {
    const { type, config } = this.popup.trigger;

    if (type === POPUP_TRIGGER_TYPES.DELAY) {
      setTimeout(() => {
        this.render();
      }, config?.delay ?? 0);
    }
  }

  get blocksToRender() {
    if (this.state.status === BlockPopupStatuses.TEASER) {
      return this.popup.teaserBlocks;
    }

    return this.popup.stepBlocks.filter(
      (block) => block.stepId === this.state.currentStepId,
    );
  }

  get renderContext(): RenderContext {
    return {
      blocks: this.blocksToRender,
      blockState: this.state.blockState,
      disclaimer: this.disclaimer,
      environment: {
        currentCountry: this.currentCountry ?? undefined,
        viewport: this.viewport,
      },
      popupActions: {
        getNode: (selector: string) => document.querySelector(selector),
        handleButtonClick: (block) => this.handleButtonClick(block),
        handleTeaserClick: () => this.setStatusToOpen(),
        hardClose: () => this.setStatusToClosed(),
        resendOtp: (block: Block) => this.resendOtp(block),
        updateStepData: (dataName, dataValue) =>
          this.updateStepData(dataName, dataValue),
      },
      theme: this.popup.theme,
    };
  }

  resendOtp = async (block: Block) => {
    this.setBlockState(block.id, { isResendOtpSuccessVisible: false });

    const { success } = await resendOTP(
      this.shopId,
      this.state.popupData[RESERVED_DATA_NAMES.PHONE],
    );

    if (success)
      this.setBlockState(block.id, { isResendOtpSuccessVisible: true });
  };

  updateStepData = (dataName: string, dataValue: any) => {
    this.setState({
      stepData: {
        ...this.state.stepData,
        [dataName]: dataValue,
      },
    });
  };

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setStatusToOpen() {
    this.setState({
      status: BlockPopupStatuses.OPEN,
    });
  }

  setStatusToClosed() {
    this.engagementTracker.endSession();
    setPopupCookie(POPUP_HARD_CLOSE_STATE, this.popup.id);
    this.setState({
      status: BlockPopupStatuses.CLOSED,
    });
    removePopupFocusTrap();
    restoreFocusedElement(this.lastFocusedIframeElementId ?? '');
    this.setPopupContainerInstance(null);
  }

  setStatusToTeaser() {
    this.engagementTracker.endSession();
    setPopupCookie(POPUP_SOFT_CLOSE_STATE, this.popup.id);
    this.setState({
      status: BlockPopupStatuses.TEASER,
    });
    removePopupFocusTrap();
    restoreFocusedElement(this.lastFocusedIframeElementId ?? '');
    this.setPopupContainerInstance(null);
  }

  startEngagementTracker() {
    this.engagementTracker.startSession(this.popupContainer);
  }

  closePopup(forceHardClose = false) {
    const isEndOfPopup = forceHardClose
      ? true
      : !hasAnyNextStep({
          currentStepId: this.state.currentStepId,
          stepBlocks: this.popup.stepBlocks,
          steps: this.popup.steps,
        });
    const shouldHardClose =
      forceHardClose ||
      isEndOfPopup ||
      this.popup.closeBehavior === CLOSE_BEHAVIORS.HARD_CLOSE;

    if (shouldHardClose) {
      this.setStatusToClosed();
    } else {
      this.setStatusToTeaser();
    }
  }

  changeLocation(block: Block) {
    const { href } = block.config?.onClick ?? {};

    const isEndOfPopup = !hasAnyNextStep({
      currentStepId: this.state.currentStepId,
      stepBlocks: this.popup.stepBlocks,
      steps: this.popup.steps,
    });

    if (isEndOfPopup) this.closePopup(true);
    if (href) window.setParentLocation(href);
  }

  routeToStep(step: Step, validatedStepData?: StepData) {
    if (step.id === CLOSE_POPUP_STEP_ID) {
      this.closePopup(true);
    }

    const newStepData = getStepData(this.popup, this.state.popupData, step.id);

    this.setState({
      currentStepId: step.id,
      stepData: newStepData,
      popupData: {
        ...this.state.popupData,
        ...(validatedStepData ?? {}),
      },
    });
  }

  // TODO(spin): address reduced motion
  animateSpinToWin(): Promise<void> {
    const blockToSpin = this.blocksToRender.find(
      (b) => b.type === BLOCK_TYPES.SPIN_TO_WIN,
    );
    if (!blockToSpin) return Promise.resolve();

    const duration = parseInt(
      getFinalStyleRulesForBlock(
        blockToSpin,
        this.popup.theme,
        this.viewport,
      )?.[STYLE_ELEMENT_TYPES.BLOCK]?.[STYLE_RULE_NAMES.SPIN_TO_WIN_DURATION] ??
        '1000ms',
      10,
    );

    this.setBlockState(blockToSpin.id, { isSpinning: true });

    return timeoutCallbackWithPromise(duration, () => {
      this.setBlockState(blockToSpin.id, { isSpinning: false });
    });
  }

  handleButtonClick = async (buttonBlock: Block) => {
    if (this.isLoading) return;
    this.setIsLoading(true);

    const { action } = buttonBlock.config?.onClick ?? {};
    const hasCloseAction = action === CONFIG_ONCLICK_ACTIONS.CLOSE;
    const hasRouteToStepAction =
      action === CONFIG_ONCLICK_ACTIONS.ROUTE_TO_STEP;
    const hasSubmitAndRouteToStepAction =
      action === CONFIG_ONCLICK_ACTIONS.SUBMIT_AND_ROUTE_TO_STEP;
    const hasChangeLocationAction =
      action === CONFIG_ONCLICK_ACTIONS.CHANGE_LOCATION;

    if (hasChangeLocationAction) {
      this.changeLocation(buttonBlock);
      return;
    }

    if (hasCloseAction) {
      this.closePopup();
      this.setIsLoading(false);
      return;
    }

    if (hasRouteToStepAction) {
      const nextStep =
        getNextStep({
          block: buttonBlock,
          currentStepId: this.state.currentStepId,
          steps: this.popup.steps,
        }) ?? null;

      await this.animateSpinToWin();
      if (nextStep) this.routeToStep(nextStep);
      this.setIsLoading(false);
      return;
    }

    if (hasSubmitAndRouteToStepAction) {
      const { errors: validationErrors, data: validatedStepData } =
        validateStepData(this.blocksToRender, this.state.stepData);

      if (Object.keys(validationErrors).length > 0) {
        this.setBlockErrors(validationErrors);
        this.setIsLoading(false);
        return;
      }

      const spinToWinPromise = this.animateSpinToWin();
      const persistAttributesFn = async (
        attrs: any,
      ): Promise<SubmitDataResult> => {
        sendAttributesEvent({
          popup_id: this.popup.id,
          popup_type: 'BLOCK',
          shop_id: this.shopId,
          source: getOptInSource(this.viewport),
          session_id: this.sessionId,
          subscriber_id: (window as any).ps__subscriber_id,
          token: (window as any).ps__token,
          server_id: (window as any).ps__server_id,
          ...attrs,
        });

        const email = attrs[RESERVED_DATA_NAMES.EMAIL];
        if (email) {
          (window as any).ps__email = email;
          collectedEmailEvent(email);
          this.trackAnalyticsEvent(POPUP_EVENT_TYPES.SUBMIT_EMAIL);
        }

        return {
          hasError: false,
          nextStep:
            getNextStep({
              block: buttonBlock,
              currentStepId: this.state.currentStepId,
              steps: this.popup.steps,
            }) ?? null,
        };
      };

      const optInFn = async (phone: string): Promise<SubmitDataResult> => {
        const { id: phoneBlockId } =
          this.blocksToRender.find(
            ({ type }) => type === BLOCK_TYPES.PHONE_INPUT,
          ) ?? {};
        if (!phoneBlockId) throw new Error();

        const { subscriberId, success } = await blockPopupOptIn({
          country: this.currentCountry,
          phoneNumber: phone,
          popupId: this.popup.id,
          sessionId: this.sessionId,
          shopId: this.shopId,
          source: getOptInSource(this.viewport),
        });
        const hasGeneralError = success === false;

        if (hasGeneralError) {
          this.setBlockErrors({
            [phoneBlockId]: ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR,
          });
        } else {
          this.setBlockState(phoneBlockId, {
            error: null,
            isResendOtpSuccessVisible: false,
          });
        }

        return {
          hasError: hasGeneralError,
          nextStep:
            getNextStep({
              block: buttonBlock,
              currentStepId: this.state.currentStepId,
              isExistingSubscriber: !!subscriberId,
              steps: this.popup.steps,
            }) ?? null,
        };
      };

      const verifyOtpFn = async (otp: string): Promise<SubmitDataResult> => {
        const phone = this.state.popupData[RESERVED_DATA_NAMES.PHONE];
        const { id: otpBlockid } =
          this.blocksToRender.find(
            ({ type }) => type === BLOCK_TYPES.OTP_INPUT,
          ) ?? {};
        if (!otpBlockid) throw new Error();

        const verifyOtpResponse = await validateOTP(this.shopId, phone, otp);

        const { general, verification } = getOtpVerifyErrors(verifyOtpResponse);
        const hasError = !!general || !!verification;

        if (hasError || !verifyOtpResponse?.data) {
          if (general)
            this.setBlockErrors({
              [otpBlockid]: ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR,
            });

          if (!general && verification)
            this.setBlockErrors({
              [otpBlockid]: ONE_TIME_PASSCODE_INPUT_VALIDATION_ERROR,
            });
        } else {
          const {
            cashback_utm_code: cashBackUtmCode,
            coupon_code: couponCode,
            subscriber_id: subscriberId,
          } = verifyOtpResponse.data;
          const hasAutoApplyOfferEnabled = this.popup.autoApplyOfferEnabled;

          this.setBlockErrors({ [otpBlockid]: null });

          configureSubscriptionEnvironment({
            subscriberId: +subscriberId,
          });

          submitFingerprintData(
            true,
            this.shopId?.toString(),
            subscriberId.toString(),
          );

          if (hasAutoApplyOfferEnabled && cashBackUtmCode)
            await attemptAutoApplyFondueCashback(cashBackUtmCode);

          if (hasAutoApplyOfferEnabled && couponCode)
            setCookieOnParentDocument(
              SHOPIFY_DISCOUNT_CODE_COOKIE_NAME,
              couponCode,
            );
        }

        return {
          hasError,
          nextStep:
            getNextStep({
              block: buttonBlock,
              currentStepId: this.state.currentStepId,
              steps: this.popup.steps,
            }) ?? null,
        };
      };

      const defaultNextStep =
        getNextStep({
          block: buttonBlock,
          currentStepId: this.state.currentStepId,
          steps: this.popup.steps,
        }) ?? null;

      const { hasError, nextStep } = await submitData({
        defaultNextStep,
        optInFn,
        persistAttributesFn,
        validatedStepData,
        verifyOtpFn,
      });

      /* Wait for the spinner to stop before removing loading state so you can't
      resubmit the form while it's spinning */
      await spinToWinPromise;
      this.setIsLoading(false);

      if (!hasError && nextStep) this.routeToStep(nextStep, validatedStepData);
    }

    this.setIsLoading(false);
  };

  private trackAnalyticsEvent(eventType: PopupEventTypes) {
    const platform =
      this.viewport === SCREEN_SIZES.DESKTOP ? 'DESKTOP' : 'MOBILE';

    postPopupEvent(
      eventType,
      +this.shopId,
      this.popup.id,
      this.currentCountry,
      platform,
      this.popup.splitTest?.id,
    );
  }

  private setPopupContainerInstance(value?: null) {
    this.popupContainer =
      value === null
        ? null
        : document.querySelector(`#${BLOCK_POPUP_CONTAINER_ID}`);
  }

  private focusFirstElement() {
    this.popupContainer
      ?.querySelector<HTMLElement>(
        `:is(${focusableElementsSelector}):not([data-block-type=${BLOCK_TYPES.CLOSE_BUTTON}], .iti__selected-country)`,
      )
      ?.focus({ preventScroll: true });
  }

  private renderHardClosed() {
    render(null, document.body);
  }

  private renderTeaser() {
    const teaserContent = renderPopup(this.renderContext);
    const teaserContainer = teaserContainerTemplate(teaserContent);
    render(teaserContainer, document.body);
  }

  private renderPopup() {
    const popupContent = renderPopup(this.renderContext);
    const popupContainer = popupContainerTemplate(popupContent, () => {
      this.closePopup();
    });
    render(popupContainer, document.body);
  }

  private prerender() {
    const isTeaserRendered =
      this.previousState?.status === BlockPopupStatuses.TEASER;
    const isPopupRendered =
      this.previousState?.status === BlockPopupStatuses.OPEN;
    const isIframeVisible = isTeaserRendered || isPopupRendered;

    const aboutToRenderPopup = this.state.status === BlockPopupStatuses.OPEN;
    const aboutToRenderTeaser = this.state.status === BlockPopupStatuses.TEASER;
    const aboutToClosePopup = this.state.status === BlockPopupStatuses.CLOSED;

    if (!isIframeVisible && (aboutToRenderPopup || aboutToRenderTeaser)) {
      showIframe();
    }

    // Note that we also call resizeIframeForSoftClose in postRender. If the teaser is shown immediately
    // we need to set some initial sizing on the iframe, otherwise the button text will wrap and the call
    // in postRender will compute the size incorrectly.
    if (!this.previousState && aboutToRenderTeaser) {
      resizeIframeForSoftClose('', '', (_, teaserWidth) =>
        getTeaserOffset(this.popup, this.viewport, teaserWidth),
      ); // Empty strings so that the default teaser size is used
    }

    if (!isPopupRendered && aboutToRenderPopup) {
      requestFocusedElement((elementId: string | null) => {
        this.lastFocusedIframeElementId = elementId;
      });
      resizeIframeForFullScreen();
    }

    if (aboutToClosePopup) {
      hideIframe();
    }
  }

  private postrender() {
    // const justRenderedAnotherStep =
    //   this.previousState?.status === BlockPopupStatuses.OPEN &&
    //   this.state.status === BlockPopupStatuses.OPEN;
    const justRenderedPopup =
      this.previousState?.status !== BlockPopupStatuses.OPEN &&
      this.state.status === BlockPopupStatuses.OPEN;
    const justRenderedTeaser =
      this.previousState?.status !== BlockPopupStatuses.TEASER &&
      this.state.status === BlockPopupStatuses.TEASER;
    const wasPreviouslyTeaser =
      this.previousState?.status === BlockPopupStatuses.TEASER;
    const wasInitialRender = !this.previousState;

    // Rendered open from close, teaser, initial render
    if (justRenderedPopup) {
      this.setPopupContainerInstance();
      this.trackAnalyticsEvent(POPUP_EVENT_TYPES.IMPRESSION);
      trapFocusInPopup();

      /* Focus first element after popup animates in on render. Only occurs for
      initial render or when transitioning from teaser to popup. */
      if (wasInitialRender || wasPreviouslyTeaser) {
        this.popupContainer?.addEventListener(
          'animationend',
          () => {
            this.focusFirstElement();
          },
          {
            once: true,
          },
        );
      }

      if (wasPreviouslyTeaser) {
        this.trackAnalyticsEvent(POPUP_EVENT_TYPES.ENGAGEMENT);
      } else {
        this.startEngagementTracker();
      }
    }

    // Focus first element on steps other than initial render
    // if (justRenderedAnotherStep) {
    //   setTimeout(() => {
    //     this.focusFirstElement();
    //   }, 100);
    // }

    if (justRenderedPopup || justRenderedTeaser) {
      makeIframeVisible();
    }

    if (justRenderedTeaser) {
      const teaserRootId = this.popup.teaserBlocks.find(
        (b) => b.type === BLOCK_TYPES.TEASER_ROOT,
      )?.id;
      const teaserId = this.popup.teaserBlocks.find(
        (b) => b.type === BLOCK_TYPES.TEASER,
      )?.id;
      setTimeout(() => {
        resizeIframeForSoftClose(
          `teaser-${teaserId}`,
          `teaser-${teaserRootId}`,
          (_, teaserWidth) =>
            getTeaserOffset(this.popup, this.viewport, teaserWidth),
        );
      }, 0);
    }
  }

  render() {
    this.prerender();

    if (this.state.status === BlockPopupStatuses.CLOSED) {
      this.renderHardClosed();
    } else if (this.state.status === BlockPopupStatuses.TEASER) {
      this.renderTeaser();
    } else {
      this.renderPopup();
    }

    this.postrender();
  }
}
