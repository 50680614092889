import { VariantTheme } from '../../types/theme';

// unmapped mobile + full size CSS var JS theme
const mobileFull: VariantTheme = {
  '--ps-popup-close-button-text-color': 'var(--ps-popup-text-color)',
  '--ps-popup-container-height': '100vh',
  '--ps-popup-container-margin-top': '0rem',
  '--ps-popup-container-margin-right': '0rem',
  '--ps-popup-container-margin-bottom': '0rem',
  '--ps-popup-container-margin-left': '0rem',
  '--ps-popup-container-width': '100%',
  '--ps-popup-container-padding-top': '3rem',
  '--ps-popup-container-padding-right': '2.25rem',
  '--ps-popup-container-padding-bottom': '3rem',
  '--ps-popup-container-padding-left': '2.25rem',
  '--ps-popup-form-max-height':
    'calc(100vh - var(--ps-popup-container-padding-top) - var(--ps-popup-container-padding-bottom))',
};

export default mobileFull;
