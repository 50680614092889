import { marked } from 'marked';
import DOMPurify from 'dompurify';
export const markdownToHtml = (markdown, blockId) => {
    const defaultRenderer = new marked.Renderer();
    defaultRenderer.link = ({ href, text }) => `<a href="${href}" class="link-${blockId}" target="_blank">${text}</a>`;
    const parsedHtml = marked.parseInline(markdown, {
        async: false,
        breaks: true,
        renderer: defaultRenderer,
    });
    const sanitizedHtml = DOMPurify.sanitize(parsedHtml, {
        ALLOWED_TAGS: ['a', 'br', 'code', 'del', 'em', 'span', 'strong'],
        ADD_ATTR: ['target'],
    });
    return sanitizedHtml;
};
