import { html } from 'lit';
import { captureException } from '@sentry/browser';
import phoneFullscreenOptInSuccess from './phoneFullscreenOptInSuccess';
import phonePartialOptInSuccess from './phonePartialOptInSuccess';
import exitButton from './exitButton';
import {
  PARTIAL_POPUP_SIZE,
  FULL_POPUP_SIZE,
  SUCCESS_SCREEN,
  V2_OPT_IN_SOURCES,
} from '../../../helpers/constants';
import { handlePopupKeyDown, FADE_IN_CLASS } from '../../../helpers/ui';
import '../../forms/customForm';
import { isTwoTouchPopup } from '../../helpers/optInMethods';

const screenMap = {
  [PARTIAL_POPUP_SIZE]: {
    singleOptIn: {
      success: phonePartialOptInSuccess,
    },
  },
  [FULL_POPUP_SIZE]: {
    singleOptIn: {
      success: phoneFullscreenOptInSuccess,
    },
  },
};

const main = ({ screen, ...props }) => {
  const { popupSize, handleExit } = props;
  const isClosedInEditorPreview = screen === 'closed';
  const customPage = props.pages?.find((p) => screen === `page_${p.id}`);
  const phoneCollectionMethod = isTwoTouchPopup(props)
    ? 'twoTouch'
    : 'singleOptIn';

  const legacyRenderFunction =
    screenMap[popupSize]?.[phoneCollectionMethod]?.[screen];

  if (!legacyRenderFunction && !customPage && !isClosedInEditorPreview) {
    captureException(
      new Error(`No render function found for screen: ${screen}`),
    );
    return;
  }

  const renderFunction = customPage
    ? (props) => html`<div
        id="${popupSize === FULL_POPUP_SIZE
          ? 'ps-mobile-widget-overlay__container'
          : 'ps-mobile-widget-partial__container'}"
        aria-modal="true"
        role="dialog"
        aria-labelledby="ps-widget__header"
      >
        ${popupSize === FULL_POPUP_SIZE
          ? html`
              <div id="ps-mobile-widget-overlay__close-container">
                ${exitButton({
                  id: 'ps-mobile-widget-overlay__close-button',
                  onClick: handleExit,
                })}
              </div>
            `
          : exitButton({
              id: 'ps-mobile-widget-partial__close-button',
              onClick: handleExit,
            })}
        <ps-popup-form
          .onExit=${handleExit}
          .page=${customPage}
          .popupTemplateProps=${props}
          source="${V2_OPT_IN_SOURCES.MOBILE}"
        ></ps-popup-form>
      </div>`
    : legacyRenderFunction;

  return html`
    <div @click="${handleExit}" style="height:100%;">
      <main
        id="ps__container"
        class=${`${screen} ${
          props.showSuccess && SUCCESS_SCREEN
        } ${FADE_IN_CLASS}`}
        data-cy="mobile-popup-container"
        @keydown=${handlePopupKeyDown(props.handleExit)}
        @click=${(e) => e.stopPropagation()}
      >
        ${renderFunction({ ...props })}
      </main>
    </div>
  `;
};

export default main;
