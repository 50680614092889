import { Marked } from 'marked';
import DOMPurify from 'dompurify';
import { createDirectives, presetDirectiveConfigs, } from 'marked-directive';
export const textStyleMarkdownDirective = {
    level: 'inline',
    marker: ':',
    renderer(token) {
        var _a, _b, _c, _d, _e;
        const { meta, attrs, text, tokens = [] } = token;
        const styles = {};
        const name = (_a = meta.name) !== null && _a !== void 0 ? _a : '';
        if (name === 'text-style') {
            if (attrs === null || attrs === void 0 ? void 0 : attrs.color) {
                styles.color = ((_b = attrs === null || attrs === void 0 ? void 0 : attrs.color) !== null && _b !== void 0 ? _b : '');
            }
            if (attrs === null || attrs === void 0 ? void 0 : attrs.backgroundColor) {
                styles['background-color'] = ((_c = attrs === null || attrs === void 0 ? void 0 : attrs.backgroundColor) !== null && _c !== void 0 ? _c : '');
            }
            if (attrs === null || attrs === void 0 ? void 0 : attrs.fontSize) {
                styles['font-size'] = `${(_d = attrs === null || attrs === void 0 ? void 0 : attrs.fontSize) !== null && _d !== void 0 ? _d : ''}px`;
            }
            if (attrs === null || attrs === void 0 ? void 0 : attrs.fontFamily) {
                styles['font-family'] = ((_e = attrs === null || attrs === void 0 ? void 0 : attrs.fontFamily) !== null && _e !== void 0 ? _e : '');
            }
        }
        const styleString = Object.entries(styles)
            .reduce((acc, [name, value]) => [...acc, `${name}: ${value}`], [])
            .join('; ');
        const content = tokens.length ? this.parser.parseInline(tokens) : text;
        return styleString.length
            ? `<span style="${styleString}">${content}</span>`
            : false;
    },
};
const createMarkedInstance = (id) => {
    const markedInstance = new Marked()
        .use(createDirectives([
        ...presetDirectiveConfigs,
        textStyleMarkdownDirective,
    ]))
        .use({
        renderer: {
            link({ href, text, tokens }) {
                return `<a href="${href}" class="link-${id}" target="_blank">${tokens.length ? this.parser.parseInline(tokens) : text}</a>`;
            },
        },
    });
    return markedInstance;
};
export const markdownToHtml = (markdown, blockId) => {
    const marked = createMarkedInstance(blockId);
    const parsedHtml = marked.parseInline(markdown, {
        async: false,
        breaks: true,
    });
    const sanitizedHtml = DOMPurify.sanitize(parsedHtml, {
        ALLOWED_TAGS: ['a', 'br', 'code', 'del', 'em', 'span', 'strong'],
        ADD_ATTR: ['target'],
    });
    return sanitizedHtml;
};
