import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, DEFAULT_COLORS, HEADING_STYLE_ELEMENT, HEADING_TEXT_ELEMENT, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLES, } from '../constants';
import { getDraggableAttributeValue } from '../utils/dragdrop';
import { renderMarkdownContent } from '../utils/markdown';
const HEADING_BLOCK_TEXT_ELEMENTS = Object.assign({}, HEADING_TEXT_ELEMENT);
const HEADING_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign({}, HEADING_STYLE_ELEMENT), BLOCK_STYLE_ELEMENT);
const defaultStyles = {
    [HEADING_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [HEADING_BLOCK_STYLE_ELEMENTS.HEADING]: {
        mobile: {
            // default variant uses the following styles
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '30px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'bold',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'center',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.1',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
        },
    },
};
function render(renderData) {
    var _a;
    const { block, blockActions, classes, content, environment } = renderData;
    const { block: blockClasses, heading: headingClasses } = classes;
    const headingContent = (_a = content[HEADING_BLOCK_TEXT_ELEMENTS.HEADING]) !== null && _a !== void 0 ? _a : '';
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(getDraggableAttributeValue(environment, blockActions))}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      <h2 class=${classMap(headingClasses)}>
        ${renderMarkdownContent(headingContent, block, environment)}
      </h2>
    </div>
  `;
}
const headingBlockHelpers = {
    defaultStyles,
    render,
    styleElements: HEADING_BLOCK_STYLE_ELEMENTS,
    textElements: HEADING_BLOCK_TEXT_ELEMENTS,
};
export default headingBlockHelpers;
