import {
  Block,
  BlockStateMap,
  PopupData,
  ScreenSize,
  Step,
  StepData,
} from '@stodge-inc/block-rendering';
import { PAGE_EVENT_TYPES, POPUP_EVENT_TYPES } from '../constants';
import { BlockPopup } from '../../../../types/blockPopup';

export type PopupEventTypes =
  typeof POPUP_EVENT_TYPES[keyof typeof POPUP_EVENT_TYPES];

export type PageEventTypes =
  typeof PAGE_EVENT_TYPES[keyof typeof PAGE_EVENT_TYPES];

export const BlockPopupStatuses = {
  CLOSED: 'closed',
  OPEN: 'visible',
  TEASER: 'teaser',
} as const;

export type BlockPopupStatus =
  typeof BlockPopupStatuses[keyof typeof BlockPopupStatuses];

export type PopupState = {
  currentStepId: string; // pageId UUID
  status: BlockPopupStatus;
  blockState: BlockStateMap;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepData: StepData;
  popupData: PopupData;
};

/**
 * block id key, message value
 */
export type Error = Record<string, string>;

export type EmptySubmitDataResult = Record<string, never>;

export type PhoneSubmitDataResult = {
  subscriberId?: number;
  isExistingSubscriber: boolean;
};

export type PsoinMatchDataResult = {
  hasNetworkMatch: boolean;
  matchToken?: string;
  phoneNumberFirstSix?: string;
  phoneNumberLastFour?: string;
};

export type PsoinEmailDataResult = {
  email?: string;
};

export type SubmitDataHandlerResult<T extends object> = {
  hasError: boolean;
  data: T;
};

export type SubmitDataAggregateResult = {
  hasError: boolean;
  data: Partial<PhoneSubmitDataResult & PsoinMatchDataResult>;
};

export type SubmitData = {
  confirmNetworkEmail: () => Promise<
    SubmitDataHandlerResult<EmptySubmitDataResult>
  >;
  confirmMaskedPhoneNumberFn: () => Promise<
    SubmitDataHandlerResult<EmptySubmitDataResult>
  >;
  optInFn: (
    phone: string,
  ) => Promise<SubmitDataHandlerResult<PhoneSubmitDataResult>>;
  persistAttributesFn: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscriberProperties: StepData,
  ) => Promise<SubmitDataHandlerResult<EmptySubmitDataResult>>;
  validatedStepData: PopupData;
  verifyOtpFn: (
    otp: string,
  ) => Promise<SubmitDataHandlerResult<PsoinEmailDataResult>>;
  searchOptInNetwork: (
    email: string,
  ) => Promise<SubmitDataHandlerResult<PsoinMatchDataResult>>;
  matchToken?: string;
};

export type ApplyToOfferAndRouteToData = {
  block: Block;
  persistAttributesFn: (
    attrs: StepData,
  ) => Promise<SubmitDataHandlerResult<EmptySubmitDataResult>>;
  popup: BlockPopup;
  routeToStep: (step: Step, validatedStepData?: StepData) => void;
  shopId: number;
  state: PopupState;
  viewport: ScreenSize;
};
