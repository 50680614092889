import { html, LitElement, nothing } from 'lit';
import { defineElementSafely } from '../../utils';
import './oneTimePasscodeInputError.css';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../../constants';
import {
  ONE_TIME_PASSCODE_INPUT_VALIDATION_ERROR,
  ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR,
} from '../../../constants';

class OneTimePasscodeInputError extends LitElement {
  declare hasValidationError: boolean;
  declare hasGeneralError: boolean;

  static get properties() {
    return {
      hasValidationError: { type: Boolean },
      hasGeneralError: { type: Boolean },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.classList.add(`${CUSTOM_CSS_SELECTOR_PREFIX}onsite-error-wrapper`);
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  render() {
    return html`
      ${this.hasValidationError
        ? html`<p role="alert">${ONE_TIME_PASSCODE_INPUT_VALIDATION_ERROR}</p>`
        : nothing}
      ${this.hasGeneralError
        ? html`<p role="alert">${ONE_TIME_PASSCODE_INPUT_GENERAL_ERROR}</p>`
        : nothing}
    `;
  }
}

defineElementSafely(
  'ps-one-time-passcode-input-error',
  OneTimePasscodeInputError,
);
