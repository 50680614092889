/* eslint-disable no-underscore-dangle */

import { ReactiveControllerHost } from 'lit';
import {
  PopupPage,
  PopupTemplateProps,
  StaticPageMessageOrNull,
} from '../types/popup';
import { QUESTION_TYPES } from './constants';
import { CustomForm } from './customForm';
import {
  handleCustomAttributes,
  handleOptInEndpoint,
  handleKeywordMessageOptIn,
  trackEmailConversion,
  handleOtpValidation,
} from './customFormSubmitHandler';
import { getQuestionWithKeyVal } from './utils';
import { FormSubmitCommand } from '../../helpers/types';

export class CustomFormSubmitController {
  declare host: ReactiveControllerHost;
  declare props: {
    page: PopupPage;
    popupTemplateProps: PopupTemplateProps;
  };

  private _isSubmitting = false;
  private _isOneTimePasscodeVisible = false;
  private _phoneNumber = '';
  private _hasValidationError = false;
  private _hasGeneralError = false;
  private _isResendCodeSuccessVisible = false;
  private _staticPageMessage: StaticPageMessageOrNull = null;

  constructor(
    host: ReactiveControllerHost,
    props: {
      page: PopupPage;
      popupTemplateProps: PopupTemplateProps;
    },
  ) {
    this.host = host;
    this.props = props;
  }

  // State
  static get properties() {
    return {
      _isOneTimePasscodeVisible: { type: Boolean },
      _isSubmitting: { type: Boolean },
      _hasValidationError: { type: Boolean },
      _hasGeneralError: { type: Boolean },
      _isResendCodeSuccessVisible: { type: Boolean },
      _staticPageMessage: { type: String || null },
    };
  }

  get staticPageMessage() {
    return this._staticPageMessage;
  }

  set staticPageMessage(newState: StaticPageMessageOrNull) {
    this._staticPageMessage = newState;
    this.host.requestUpdate();
  }

  get isOneTimePasscodeVisible() {
    return this._isOneTimePasscodeVisible;
  }

  set isOneTimePasscodeVisible(newState: boolean) {
    this._isOneTimePasscodeVisible = newState;
    this.host.requestUpdate();
  }

  get phoneNumber() {
    return this._phoneNumber;
  }

  /**
   * Note: not every submit condition involves async logic.
   */
  get isSubmitting() {
    return this._isSubmitting;
  }

  set isSubmitting(newState: boolean) {
    this._isSubmitting = newState;
    this.host.requestUpdate();
  }

  // Validation Error handling state
  get hasValidationError() {
    return this._hasValidationError;
  }

  set hasValidationError(newState: boolean) {
    this._hasValidationError = newState;
    this.host.requestUpdate();
  }

  // General Error handling state
  get hasGeneralError() {
    return this._hasGeneralError;
  }

  set hasGeneralError(newState: boolean) {
    this._hasGeneralError = newState;
    this.host.requestUpdate();
  }

  // Resend Code Success handling state
  get isResendCodeSuccessVisible() {
    return this._isResendCodeSuccessVisible;
  }

  set isResendCodeSuccessVisible(newState: boolean) {
    this._isResendCodeSuccessVisible = newState;
    this.host.requestUpdate();
  }

  /**
   * Set multiple error properties with one rerender.
   */
  set errors(newState: { general?: boolean; validation?: boolean }) {
    this._hasGeneralError = newState.general ?? this._hasGeneralError;
    this._hasValidationError = newState.validation ?? this._hasValidationError;
    this.host.requestUpdate();
  }

  /**
   * Returns current page's phone question, if it exists
   */
  get phoneQuestion() {
    return getQuestionWithKeyVal({
      questions: this.props.page.questions,
      key: 'type',
      value: QUESTION_TYPES.PHONE,
    });
  }

  /**
   * Calls two touch handler or opt-in endpoint handler. Returns statuses that
   * can be used to prevent nextScreen.
   */
  private async submitForm(submitCommand: FormSubmitCommand): Promise<boolean> {
    this.isSubmitting = true;
    handleCustomAttributes(submitCommand);
    trackEmailConversion(submitCommand);
    handleKeywordMessageOptIn(submitCommand);

    const showExistingOtpSubscriberStaticPageCallback = () => {
      this.staticPageMessage =
        submitCommand.existingSubscriberSuccessMessage as StaticPageMessageOrNull;
    };

    const showNewSubscriberStaticPageMessage = () => {
      this.staticPageMessage =
        submitCommand.newSubscriberSuccessMessage as StaticPageMessageOrNull;
    };

    const optInPreventingNextScreen = await handleOptInEndpoint({
      submitCommand,
      showOneTimePasscodeInputCallback: () => {
        this.isOneTimePasscodeVisible = true;
      },
      showExistingOtpSubscriberStaticPageCallback,
      updatePhoneNumberStateValueCallback: (value) => {
        this._phoneNumber = value;
      },
    });

    const otpPreventingNextScreen = await handleOtpValidation({
      submitCommand,
      autoApplyOfferEnabled:
        this.props.popupTemplateProps.autoApplyOfferEnabled,
      updateErrorsCallback: (value) => {
        this.errors = value;
      },
      showNewSubscriberStaticPageMessage,
    });

    this.isSubmitting = false;

    return optInPreventingNextScreen || otpPreventingNextScreen;
  }

  // Handle submit with OTP phone question / showing OTP input
  async handleSubmit(event: SubmitEvent, hostComponent: CustomForm) {
    event.preventDefault();
    const {
      nextScreen,
      newSubscriberSuccessMessage,
      existingSubscriberSuccessMessage,
    } = this.props.popupTemplateProps;

    const formData = new FormData(event.target as HTMLFormElement);

    const shouldPreventNextScreen = await this.submitForm({
      event,
      formData,
      hostComponent,
      optInMethod: this.phoneQuestion?.optInMethod,
      phoneNumber: this._phoneNumber,
      newSubscriberSuccessMessage: newSubscriberSuccessMessage as string,
      existingSubscriberSuccessMessage:
        existingSubscriberSuccessMessage as string,
    });

    if (!shouldPreventNextScreen) nextScreen();
  }
}
