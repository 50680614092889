import { html } from 'lit';
/* This is a temporary stub. We may not end up needing this block type at all,
but we added it early just to not need the BE if we do decide we want it. If we
don't end up needing it, this will go away, but for now, this stub will make TS
happy. */
const psnBlockHelpers = {
    defaultStyles: {},
    render: ({ block }) => (block ? html `` : html ``),
};
export default psnBlockHelpers;
