import { html } from 'lit';

const closeButton = ({ text, onClick }) => html`
  <button
    @click=${onClick}
    id="ps-desktop-widget__close-link"
    data-cy="ps-desktop-widget__close-link"
    type="button"
    aria-label="Dismiss popup"
  >
    ${text}
  </button>
`;

export default closeButton;
