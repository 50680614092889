import { TemplateResult, html } from 'lit';
import {
  handleKeyDownForClickableDivs,
  FADE_IN_CLASS,
} from '../../../helpers/ui';

export const closedBubbleBlock = (content: TemplateResult<1>) => html`
  <div id="ps-desktop-widget__container" class="${FADE_IN_CLASS}">
    ${content}
  </div>
`;

type ClosedBubbleV2Args = {
  text: string;
  onExit: () => void;
  onOpen: () => void;
};
export const closedBubbleV2 = ({
  text,
  onOpen,
  onExit,
}: ClosedBubbleV2Args) => html`
  <div id="ps-desktop-widget__container" class="${FADE_IN_CLASS}">
    <div id="ps-desktop-widget__closed-body">
      <div>
        <div
          id="ps-desktop-widget__close-text"
          role="button"
          tabindex="0"
          data-cy="popup-open-button"
          @click=${onOpen}
          @keydown=${handleKeyDownForClickableDivs}
        >
          ${text}
        </div>
        <div
          id="ps-desktop-widget__close-widget"
          role="button"
          tabindex="0"
          aria-label="Dismiss marketing prompt"
          data-cy="popup-dismiss-button"
          @click=${onExit}
          @keydown=${handleKeyDownForClickableDivs}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
`;
