const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const REFERENCE_LEAP_YEAR = 1904;
export const generateMonthOptions = () => [
    { label: 'Month', value: '' },
    ...MONTHS.map((month, index) => ({
        label: month,
        value: (index + 1).toString(),
    })),
];
export const getDaysInMonth = (month = 1) => new Date(REFERENCE_LEAP_YEAR, month, 0).getDate();
export const generateDayOptions = (month) => {
    const numberDays = getDaysInMonth(month);
    const options = [
        {
            label: 'Day',
            value: '',
        },
    ];
    for (let i = 0; i < numberDays; i += 1) {
        const value = (i + 1).toString();
        options.push({ value, label: value });
    }
    return options;
};
export const parseMonthDayFromValue = (value) => {
    if (!value) {
        return [undefined, undefined];
    }
    const split = value.split('-');
    if (split.length !== 2) {
        return [undefined, undefined];
    }
    return split.map((v) => {
        const numValue = parseInt(v, 10);
        return !Number.isNaN(numValue) ? numValue : undefined;
    });
};
export const generateValueFromMonthDay = (month, day) => {
    if (month === undefined || day === undefined) {
        return undefined;
    }
    return `${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`;
};
