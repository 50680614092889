import { html, LitElement } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { generateDayOptions, generateMonthOptions, getDaysInMonth, generateYearOptions, generateValueFromDates, } from './utils';
import '../SelectInput';
const BASE_10 = 10;
class DateInput extends LitElement {
    constructor() {
        super(...arguments);
        this.updateDayForMonthYearChange = () => {
            const numberDays = getDaysInMonth(this.month, this.year);
            if (this.day !== undefined && this.day > numberDays) {
                this.day = undefined;
            }
        };
    }
    static get properties() {
        return {
            id: { type: String },
            inputClasses: {
                type: Object,
            },
            ariaDescribedBy: { type: String, attribute: 'aria-describedby' },
            label: { type: String },
            value: { type: String },
            required: { type: Boolean },
            error: { type: Boolean },
            arrow: { type: String },
            readonly: { type: Boolean },
            month: { type: Number, state: true },
            day: { type: Number, state: true },
            year: { type: Number, state: true },
        };
    }
    update(changedProperties) {
        super.update(changedProperties);
    }
    dispatchChangeEvent() {
        const event = new CustomEvent('change', {
            detail: generateValueFromDates(this.month, this.day, this.year),
            bubbles: true,
            composed: true,
            cancelable: true,
        });
        this.dispatchEvent(event);
    }
    handleMonthChange(e) {
        var _a;
        e.stopPropagation();
        const target = e.target;
        const parsedMonth = parseInt((_a = target.value) !== null && _a !== void 0 ? _a : '', BASE_10);
        this.month = Number.isNaN(parsedMonth) ? undefined : parsedMonth;
        this.updateDayForMonthYearChange();
        this.dispatchChangeEvent();
    }
    handleDayChange(e) {
        var _a;
        e.stopPropagation();
        const target = e.target;
        const parsedDay = parseInt((_a = target.value) !== null && _a !== void 0 ? _a : '', BASE_10);
        this.day = Number.isNaN(parsedDay) ? undefined : parsedDay;
        this.dispatchChangeEvent();
    }
    handleYearChange(e) {
        var _a;
        e.stopPropagation();
        const target = e.target;
        const parsedYear = parseInt((_a = target.value) !== null && _a !== void 0 ? _a : '', BASE_10);
        this.year = Number.isNaN(parsedYear) ? undefined : parsedYear;
        this.updateDayForMonthYearChange();
        this.dispatchChangeEvent();
    }
    createRenderRoot() {
        return this;
    }
    render() {
        var _a, _b, _c;
        return html `<style>
        ps-date-input ps-select-input {
          flex: 1 1 34%;
        }

        ps-date-input[readonly] ps-select-input {
          pointer-events: none;
        }
      </style>
      <ps-select-input
        aria-label=${this.label}
        aria-describedby=${ifDefined(this.ariaDescribedBy)}
        aria-invalid=${ifDefined(this.error)}
        .inputClasses=${this.inputClasses}
        .arrow=${this.arrow}
        .options=${generateMonthOptions()}
        data-popup-engagement="true"
        ?required=${this.required}
        @change=${this.handleMonthChange}
        .value=${ifDefined((_a = this.month) === null || _a === void 0 ? void 0 : _a.toString())}
      ></ps-select-input>
      <ps-select-input
        aria-label=${this.label}
        aria-describedby=${ifDefined(this.ariaDescribedBy)}
        aria-invalid=${ifDefined(this.error)}
        .inputClasses=${this.inputClasses}
        .arrow=${this.arrow}
        .options=${generateDayOptions(this.month, this.year)}
        data-popup-engagement="true"
        ?disabled=${this.month === undefined}
        ?required=${this.required}
        @change=${this.handleDayChange}
        .value=${ifDefined((_b = this.day) === null || _b === void 0 ? void 0 : _b.toString())}
      >
      </ps-select-input>
      <ps-select-input
        aria-label=${this.label}
        aria-describedby=${ifDefined(this.ariaDescribedBy)}
        aria-invalid=${ifDefined(this.error)}
        .inputClasses=${this.inputClasses}
        .arrow=${this.arrow}
        .options=${generateYearOptions()}
        data-popup-engagement="true"
        ?required=${this.required}
        @change=${this.handleYearChange}
        .value=${ifDefined((_c = this.year) === null || _c === void 0 ? void 0 : _c.toString())}
      >
      </ps-select-input>`;
    }
}
const ELEMENT_NAME = 'ps-date-input';
if (!customElements.get(ELEMENT_NAME)) {
    customElements.define(ELEMENT_NAME, DateInput);
}
