import { POPUP_SDK_INITIALIZED } from '../../../../sdk/core/popups/constants';
import { generateFindPopupListener } from './find';
import { generateOpenPopupListener } from './open';
import { SDKEventListenerConfig } from './types';

export const publishPostscriptPopupInitializedEvent = () => {
  window.parent.postMessage(
    {
      type: POPUP_SDK_INITIALIZED,
    },
    '*',
  );
};

export const addSdkEventListeners = (config: SDKEventListenerConfig) => {
  window.addEventListener('message', generateOpenPopupListener(config));
  window.addEventListener('message', generateFindPopupListener(config));
  publishPostscriptPopupInitializedEvent();
};
