import { html, LitElement } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { classMap } from 'lit/directives/class-map.js';
import { DropdownArrows } from '../utils/styles/dropdownArrows';
class SelectInput extends LitElement {
    static get properties() {
        return {
            id: { type: String },
            inputClasses: {
                type: Object,
            },
            label: { type: String },
            arrow: { type: String },
            options: { type: Array },
            value: { type: String },
            required: { type: Boolean },
            disabled: { type: Boolean },
        };
    }
    createRenderRoot() {
        return this;
    }
    render() {
        var _a, _b;
        return html `
      <style>
        ps-select-input {
          width: 100%;
        }
        ps-select-input > div {
          position: relative;
        }
        ps-select-input select {
          font-family: inherit;
          font-size: inherit;
          color: inherit;
          background-color: inherit;
          border: none;
          width: 100%;
          appearance: none;

          &:focus-visible {
            outline: none;
          }
        }
        ps-select-input svg {
          position: absolute;
          right: 3px;
          top: 50%;
          height: 24px;
          width: auto;
          transform: translateY(-50%);
          pointer-events: none;
        }
      </style>
      <div
        class=${ifDefined(this.inputClasses ? classMap(this.inputClasses) : undefined)}
      >
        <select
          id=${ifDefined(this.id)}
          data-popup-engagement="true"
          .value=${(_a = this.value) !== null && _a !== void 0 ? _a : ''}
          ?required=${ifDefined(this.required ? true : undefined)}
          ?disabled=${ifDefined(this.disabled ? true : undefined)}
        >
          ${this.options.map(({ value, label }) => html `<option value=${value}>${label}</option>`)}
        </select>
        ${unsafeHTML(DropdownArrows[(_b = this.arrow) !== null && _b !== void 0 ? _b : 'chevron'])}
      </div>
    `;
    }
}
const ELEMENT_NAME = 'ps-select-input';
if (!customElements.get(ELEMENT_NAME)) {
    customElements.define(ELEMENT_NAME, SelectInput);
}
