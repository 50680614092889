import { SCREEN_SIZES } from '@stodge-inc/block-rendering';
import { getAllPopupsByType } from '../../services/popup/popupService';
import { filterSplitTestPopups } from '../../services/popup/helper';
import {
  addCustomTriggerV2PopupMessageListener,
  initializeV2ExitIntentPopup,
  initializeV2TimeDelayPopup,
} from '../common/helpers/popupBase';
import { initializeIframeGlobalState } from '../helpers/initialize-popup-helper';
import './desktop.css';
import {
  initializeV2DesktopPopup,
  renderV2DesktopPopupPreview,
} from './render/v2';
import initializeBlockPopups from '../common/block-popups/utils/initializePopups';

(async function render() {
  const {
    shopId,
    status: statuses,
    origin,
    sessionId,
    previewData,
    splitTestCookies,
    currentCountry,
    subscriberId,
  } = await initializeIframeGlobalState();

  // If previewing in popup editor, we use the previewData to render the popup
  if (previewData) {
    renderV2DesktopPopupPreview(previewData, shopId, statuses, sessionId);
    return;
  }

  /* If the block popup flag is set, attempt to render the block popups, and
  return early so the v2 popups aren't fetched. */
  if (
    await initializeBlockPopups(
      statuses,
      currentCountry,
      origin,
      sessionId,
      subscriberId,
      shopId,
      SCREEN_SIZES.DESKTOP,
      splitTestCookies,
    )
  )
    return;

  // Fetch all v2 desktop popups
  const popupsResponse = await getAllPopupsByType(shopId, 'desktop');
  if (popupsResponse.length === 0) {
    return;
  }

  // Filter popups based on split test cookies
  const popupsToChooseFrom = filterSplitTestPopups(
    popupsResponse,
    splitTestCookies,
    currentCountry,
  );

  const openV2Popup = (popup) =>
    initializeV2DesktopPopup(
      popup,
      shopId,
      statuses,
      sessionId,
      previewData,
      currentCountry,
    );

  // intentionally not awaiting here to allow for parallel initialization
  initializeV2TimeDelayPopup(
    popupsToChooseFrom,
    statuses,
    origin,
    currentCountry,
    subscriberId,
    openV2Popup,
  );

  initializeV2ExitIntentPopup(
    popupsToChooseFrom,
    statuses,
    origin,
    currentCountry,
    subscriberId,
    openV2Popup,
  );

  // We listen for messages from the parent page to programmatically trigger the popup
  addCustomTriggerV2PopupMessageListener({
    popups: popupsResponse,
    shopId,
    statuses,
    origin,
    sessionId,
    previewData,
    initializePopup: initializeV2DesktopPopup,
  });
})();
