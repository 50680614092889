import { html, nothing } from 'lit';
/* For closed beta, we're just going to render errors with a static style. */
export const renderError = (blockState, inputId) => {
    const { error } = blockState !== null && blockState !== void 0 ? blockState : {};
    return html `<div
    class="${error ? 'block-error' : nothing}"
    id=${`${inputId}-error`}
    role="alert"
  >
    ${error || nothing}
  </div>`;
};
export const renderResendOtpSuccessMessage = (blockState, inputId) => {
    const { isResendOtpSuccessVisible } = blockState !== null && blockState !== void 0 ? blockState : {};
    return html `<span id=${`${inputId}-success`} role="status"
    >${isResendOtpSuccessVisible ? 'Code sent!' : nothing}</span
  >`;
};
