import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, DEFAULT_COLORS, STYLE_RULE_NAMES, } from '../constants';
const ROOT_BLOCK_STYLE_ELEMENTS = Object.assign({}, BLOCK_STYLE_ELEMENT);
const defaultStyles = {
    [ROOT_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.POPUP_BG,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
            [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'center',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MAX_WIDTH]: '340px',
            [STYLE_RULE_NAMES.MIN_HEIGHT]: '240px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '30px 30px 30px 30px',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'flex',
            [STYLE_RULE_NAMES.POSITION]: 'relative',
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
        desktop: {
            [STYLE_RULE_NAMES.MAX_WIDTH]: '450px',
        },
    },
};
function isEmpty({ children = [] }) {
    return children.length === 0;
}
function render(renderData) {
    const { blockActions, classes } = renderData;
    const { block: blockClasses } = classes;
    return html `
    <form
      class=${classMap(blockClasses)}
      novalidate
      @click=${ifDefined(blockActions.selectBlock)}
      @submit=${(event) => event.preventDefault()}
    >
      ${renderData.children}
    </form>
  `;
}
const rootBlockHelpers = {
    defaultStyles,
    isEmpty,
    render,
    styleElements: ROOT_BLOCK_STYLE_ELEMENTS,
};
export default rootBlockHelpers;
