/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */

import { html, LitElement, svg, nothing } from 'lit';
import { getPopupElementById } from '../../../helpers/next-screen-helper';
import {
  CUSTOM_CSS_SELECTOR_PREFIX,
  ONE_TIME_PASSCODE_INPUT_NAME,
  ONE_TIME_PASSCODE_HIDDEN_TEXT_ID,
  ONE_TIME_PASSCODE_INPUT_LABEL,
} from '../../constants';
import { defineElementSafely } from '../../utils';
import './oneTimePasscodeInput.css';
import './oneTimePasscodeInputError';
import { dispatchValidateFormEvent } from '../../customEvents';
import { CustomForm } from '../../customForm';
import { resendOTP } from '../../../../sdk/core/onsite-opt-in/service';

class OneTimePasscodeInput extends LitElement {
  private readonly _customForm;
  private readonly _customFormSubmitButton;

  readonly buffer = 4; // Helps with mobile device variance in input width
  readonly dashGap = 8;
  readonly paddingLeft = 26;
  readonly paddingRight;
  readonly requiredCharacterCount = 4;
  readonly svgHeight = 49;

  declare characterWidth;
  declare svgViewBox;
  declare value;
  declare visibleDashes: boolean[];
  declare hasValidationError: boolean;
  declare hasGeneralError: boolean;
  declare shopId: number;
  declare phoneNumber: string;
  declare isResendCodeSuccessVisible: boolean;

  constructor() {
    super();
    this._customForm = document.querySelector<CustomForm>('ps-popup-form');
    this._customFormSubmitButton = document.querySelector<HTMLButtonElement>(
      'ps-popup-form [type="submit"]',
    );
    this.characterWidth = 10;
    this.paddingRight = this.paddingLeft - this.dashGap;
    this.svgViewBox = `0 0 ${this.dynamicSvgWidth} ${this.svgHeight}`;
    this.value = '';
    this.visibleDashes = Array.from({
      length: this.requiredCharacterCount,
    }).fill(true) as boolean[];
  }

  static get properties() {
    return {
      characterWidth: { state: true, type: Number },
      svgViewBox: { state: true, type: String },
      visibleDashes: { state: true, type: Array },
      hasValidationError: { type: Boolean },
      hasGeneralError: { type: Boolean },
      phoneNumber: { type: String },
      isResendCodeSuccessVisible: { type: Boolean },
    };
  }

  get input() {
    return this.renderRoot.querySelector(
      `#${ONE_TIME_PASSCODE_INPUT_NAME}`,
    ) as HTMLInputElement;
  }

  get hasRequiredCharacterCount() {
    return this.valueLength === this.requiredCharacterCount;
  }

  get hiddenText() {
    return this.renderRoot.querySelector(
      `#${ONE_TIME_PASSCODE_HIDDEN_TEXT_ID}`,
    ) as HTMLInputElement;
  }

  get valueLength() {
    return this.value.length;
  }

  /**
   * Dynamic width of the input, which includes inline-padding and the dynamic
   * SVG footprint, plus an extra dash gap. The extra gap is automatically added
   * to the input at the end of the last character.
   */
  get dynamicInputWidth() {
    return Math.ceil(
      this.paddingLeft +
        this.dynamicSvgWidth +
        this.buffer +
        this.dashGap +
        this.paddingRight,
    );
  }

  /**
   * Dynamic width of the SVG, which includes the number of required characters
   * and each dash gap between them. The reason we don't add the last gap that
   * the browser would add (letter-spacing) to the SVG footprint is because that
   * would visually uncenter the SVG in the background;
   *
   * Can be NaN or less than 0, in which case we default to 0 to avoid RTE.
   */
  get dynamicSvgWidth() {
    const width =
      this.requiredCharacterCount * this.characterWidth +
      (this.requiredCharacterCount - 1) * this.dashGap;

    return width >= 0 ? width : 0;
  }

  get svgDashes() {
    const lines = [];

    for (let i = 0; i < this.requiredCharacterCount; i += 1) {
      const line = svg`<line
        x1=${this.characterWidth * i + this.dashGap * i}
        x2=${this.characterWidth * (i + 1) + this.dashGap * i}
        y1=${this.svgHeight / 2}
        y2=${this.svgHeight / 2}
        stroke=${
          this.visibleDashes[i]
            ? 'var(--ps-popup-field-text-color)'
            : 'transparent'
        }
      />`;
      lines.push(line);
    }

    return lines;
  }

  /**
   * hasValidationError and hasGeneralError result in an error message
   * displaying. We give the input error styling if there is a validation error
   * and the input is empty, or whenever there is a general error.
   */
  get hasInputErrorStyle() {
    return (this.hasValidationError && !this.value) || this.hasGeneralError;
  }

  /**
   * When the user types, we replace non-digits, including spaces, and set our
   * state, and the inputs value in the DOM. Without resetting the DOM's value,
   * the input can have replaceable characters even if our state does not. If
   * the user attempts to input more than the required number of characters, we
   * set the input's value back to what it previously was without updating
   * state.
   */
  handleInput(event: Event) {
    const sanitizedValue =
      (event.target as HTMLInputElement)?.value
        .replace(/\D/g, '')
        .slice(0, this.requiredCharacterCount) ?? '';

    // Prevents adding new characters when at max, but still allow for deletion
    if (
      this.hasRequiredCharacterCount &&
      sanitizedValue.length >= this.requiredCharacterCount
    ) {
      this.input.value = this.value;
      return;
    }

    // Update state, input value, dashes, and maybe make form submittable
    this.input.value = sanitizedValue;
    this.value = sanitizedValue;
    this.setDashVisibility();

    dispatchValidateFormEvent(this);

    // Focus submit when hitting required number of characters
    if (this._customFormSubmitButton && this.hasRequiredCharacterCount)
      this._customFormSubmitButton.focus();
  }

  /**
   * Creates an array of booleans with a length equal to the number of required
   * characters. If there is no input value state, then all booleans are true.
   * When we have the required number of characters, all the booleans are false.
   * This is used to determine what dashes are shown later based on the boolean
   * at a particular index.
   */
  setDashVisibility() {
    this.visibleDashes = Array.from(
      { length: this.requiredCharacterCount },
      (_, i) => i >= this.valueLength,
    );
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  /**
   * Set some CSS variables, and show all dashes. The reason we can't initialize
   * this.visibleDashes with the correct array of dashes (instead of calling
   * setDashVisibility here) is because the required number of characters is set
   * by a parent as a property, which makes it unavailable to the constructor
   * method.
   */
  connectedCallback() {
    super.connectedCallback();
    this.id = `${CUSTOM_CSS_SELECTOR_PREFIX}onsite-wrapper`;
    this.style.setProperty('--_gap-width', `${this.dashGap}px`);
    this.style.setProperty('--_padding-left', `${this.paddingLeft}px`);
    this.style.setProperty('--_padding-right', `${this.paddingRight}px`);
    this.style.setProperty('--_input-width', `${this.dynamicInputWidth}px`);
  }

  /* After initial render, we look for a visually-hidden span of text with one
    monospace character in it, and ascertain its footprint. This character's
    width influences how wide the SVG is, where each dash in the SVG gets
    rendered, and how wide the input is as a whole.

    While we have sensible defaults to everything mentioned above before we
    select it from the DOM, we can't guarantee that all monospace fonts on all
    devices will be as wide as others, so there is a chance our SVG and input
    widths could be too narrow/wide, and look off.

    The reason we await and use a timeout is that firstUpdated does not
    guarantee the element has been rendered with a width by the browser yet,
    just parsed and created, which can result in an width of 0 – which can
    definitely throw off the size of everything mentioned above. */
  protected async firstUpdated() {
    await this.updateComplete;

    getPopupElementById(ONE_TIME_PASSCODE_INPUT_NAME)?.focus();

    setTimeout(() => {
      dispatchValidateFormEvent(this);
      if (this.hiddenText?.getBoundingClientRect().width) {
        // All of these have default values in the event this doesn't execute
        this.characterWidth = this.hiddenText.getBoundingClientRect().width;
        this.svgViewBox = `0 0 ${this.dynamicSvgWidth} ${this.svgHeight}`;
        this.style.setProperty('--_input-width', `${this.dynamicInputWidth}px`);
      }
    }, 250);
  }

  protected async updated(
    _changedProperties: Map<string | number | symbol, unknown>,
  ) {
    if (
      _changedProperties.has('hasValidationError') &&
      this.hasValidationError
    ) {
      // Clear the input field when we have validation error
      this.input.value = '';
      this.value = '';
      this.setDashVisibility();
      await this.updateComplete;
      dispatchValidateFormEvent(this);
    }
  }

  async handleClick() {
    const { success } = await resendOTP(this.shopId, this.phoneNumber);
    if (success) {
      this.isResendCodeSuccessVisible = true;
    }
  }

  disconnectedCallback() {
    /* Just to be safe, set form to submittable on the way out. We can't
    dispatch a custom event for this because the component won't be in the dom
    */
    super.disconnectedCallback();
    if (this._customForm) this._customForm.hasInvalidField = false;
  }

  // VSCode lit-plugin extension doesn't recognize autocomplete "one-time-code"
  render() {
    return html`
      <p id=${`${CUSTOM_CSS_SELECTOR_PREFIX}onsite-label`}>
        ${ONE_TIME_PASSCODE_INPUT_LABEL}
      </p>
      <div
        class="resend-code-button-container"
        id=${`${CUSTOM_CSS_SELECTOR_PREFIX}onsite-resend-wrapper`}
      >
        <span>Didn't get a code?</span>
        <button
          data-popup-engagement="true"
          id=${`${CUSTOM_CSS_SELECTOR_PREFIX}onsite-resend-button`}
          @click=${this.handleClick}
          type="button"
        >
          <span>Resend code</span>
        </button>
        ${this.isResendCodeSuccessVisible
          ? html`<span role="alert">Code sent!</span>`
          : nothing}
      </div>
      <span aria-hidden="true" id=${ONE_TIME_PASSCODE_HIDDEN_TEXT_ID}>4</span>
      <div>
        <input
          ?data-error=${this.hasInputErrorStyle}
          aria-labelledby=${`${CUSTOM_CSS_SELECTOR_PREFIX}onsite-label`}
          autocomplete="one-time-code"
          data-popup-engagement="true"
          id=${ONE_TIME_PASSCODE_INPUT_NAME}
          inputmode="numeric"
          name=${ONE_TIME_PASSCODE_INPUT_NAME}
          pattern=${`[0-9]{${this.requiredCharacterCount}}`}
          required
          type="text"
          @input=${this.handleInput}
        />
        <svg
          aria-hidden
          fill="none"
          height=${this.svgHeight}
          viewBox=${this.svgViewBox}
          width=${this.dynamicSvgWidth}
          xmlns="http://www.w3.org/2000/svg"
        >
          ${this.svgDashes}
        </svg>
      </div>
      ${html`<ps-one-time-passcode-input-error
        .hasValidationError=${this.hasValidationError}
        .hasGeneralError=${this.hasGeneralError}
      ></ps-one-time-passcode-input-error>`}
    `;
  }
}

defineElementSafely('ps-one-time-passcode-input', OneTimePasscodeInput);
