import { html, LitElement, nothing } from 'lit';
import { PopupPageQuestion } from '../../types/popup';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../constants';
import { defineElementSafely } from '../utils';
import './textInput.css';

/**
 * We use the :placeholder-shown selector for styling the labels, but that only
 * works if the input has a placeholder. A single space is enough to satisfy the selector
 */
const EMPTY_PLACEHOLDER_FOR_STYLING = ' ';

class TextInput extends LitElement {
  declare ariaDescribedBy?: string;
  declare question: PopupPageQuestion;
  declare type: 'text' | 'email' | 'tel';
  declare onFirstUpdate: () => void;

  static get properties() {
    return {
      ariaDescribedBy: { type: String },
      question: { type: Object },
      type: { type: String },
      onFirstUpdate: { type: Function },
    };
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  protected firstUpdated(
    _changedProperties: Map<string | number | symbol, unknown>,
  ): void {
    super.firstUpdated(_changedProperties);
    this.onFirstUpdate?.();
  }

  render() {
    return html`
      <div>
        <input
          class="${CUSTOM_CSS_SELECTOR_PREFIX}text-question-input"
          type="${this.type}"
          id=${this.question.attributeKey}
          name=${this.question.attributeKey}
          aria-describedby="${this.ariaDescribedBy ?? nothing}"
          data-popup-engagement="true"
          placeholder="${EMPTY_PLACEHOLDER_FOR_STYLING}"
          autocomplete="${this.type === 'tel'
            ? 'tel-national'
            : this.type === 'email'
            ? 'email'
            : 'on'}"
        />
        <label
          class="${CUSTOM_CSS_SELECTOR_PREFIX}text-question-label"
          for="${this.question.attributeKey}"
          >${this.question.text}</label
        >
      </div>
    `;
  }
}

defineElementSafely('ps-input', TextInput);
