/* eslint-disable @typescript-eslint/no-explicit-any */
import { SUBSCRIBER_COHORTS } from '../../helpers/constants';
import { CLOUDFLARE_BUSINESS_PLAN_URL } from '../../helpers/utility';
import { STATIC_PAGE_MESSAGES } from '../../widget/forms/constants';
import { Popup } from '../../widget/types/popup';
import { DEFAULT_API_VALUES } from './constants';
import { LowerCasePopupType, MayHaveSplitTest } from './types';
import {
  groupPopupsBySplitTest,
  pickSplitTestByWeight,
} from '../../helpers/utils';

export const getUrlForShop = (
  shopId: number,
  popupType: LowerCasePopupType,
): URL => {
  const url = new URL(
    `/v2/public/popups/${shopId}/${popupType}`,
    CLOUDFLARE_BUSINESS_PLAN_URL,
  );
  return url;
};

export const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );

export const mapKeys = <T>(
  object: Record<string, T>,
  iteratee: (value: T, key: string, obj: Record<string, T>) => string,
): Record<string, T> => {
  const castObject = Object(object);
  const result: Record<string, T> = {};

  Object.keys(castObject).forEach((key) => {
    const value = castObject[key];
    result[iteratee(value, key, castObject)] = value;
  });
  return result;
};

export const camelCaseKeys = (object: any): Record<string, any> =>
  mapKeys(object, (v: any, key: any) => snakeToCamel(key));

export const camelCaseKeysDeep = (object: any): Record<string, any> => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((item) => camelCaseKeysDeep(item));
  }

  return camelCaseKeys(
    Object.entries(object).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: camelCaseKeysDeep(value),
      }),
      {},
    ),
  );
};

// Temporary until we run a migration to updates these in the DB
export const replaceLegacyItiClassesWithUpdatedClasses = (
  customCss?: string,
) => {
  if (!customCss) return customCss;

  let newCss = customCss;

  newCss = newCss.replace(
    /iti--separate-dial-code/g,
    'iti--show-selected-dial-code',
  );

  newCss = newCss.replace(/iti__selected-flag/g, 'iti__selected-country');

  newCss = newCss.replace(/iti__flag-container/g, 'iti__country-container');

  return newCss;
};

export const applyDefaultsToPopups = (
  popups: any,
  popupType: LowerCasePopupType,
): Array<Popup> => {
  const popupsWithDefaults = popups.map((data: any) => {
    const valuesWithDefaults = {
      ...DEFAULT_API_VALUES,
      ...data,
      button_radius: data.button_radius,
      font: data.font || DEFAULT_API_VALUES.font[popupType],
      subscriberCohort:
        data.subscriber_cohort || SUBSCRIBER_COHORTS.NEW_SMS_USERS,
      newSubscriberSuccessMessage:
        data.new_subscriber_success_message ||
        STATIC_PAGE_MESSAGES.NEW_SUBSCRIBER,
      existingSubscriberSuccessMessage:
        data.existing_subscriber_success_message ||
        STATIC_PAGE_MESSAGES.EXISTING_SUBSCRIBER,
      pages:
        data.pages?.map((page: any) => ({
          ...camelCaseKeys(page),
          questions: page.questions.map((q: any) => camelCaseKeys(q)),
        })) ?? [],
      customCss: replaceLegacyItiClassesWithUpdatedClasses(data.custom_css),
    };
    return camelCaseKeys(valuesWithDefaults);
  });
  return popupsWithDefaults;
};

export const filterSplitTestPopups = <T extends MayHaveSplitTest>(
  availablePopups: T[],
  splitTestCookies: string | null,
): Array<T> => {
  const splitTestPopups = groupPopupsBySplitTest(availablePopups);

  const splitTestsToInclude = pickSplitTestByWeight(
    splitTestPopups,
    splitTestCookies,
  );

  const popupsWithoutSplitTests = availablePopups.filter(
    (popup) => !popup.splitTest,
  );

  const popupsToChooseFrom = [
    ...splitTestsToInclude,
    ...popupsWithoutSplitTests,
  ];

  return popupsToChooseFrom;
};

export const getPopupForPreview = (previewData: Popup) =>
  camelCaseKeys({
    ...DEFAULT_API_VALUES,
    ...previewData,
    includedPages: '',
    excludedPages: '',
    enabled: true,
    countriesAllowed: 'All',
  });
