import { hasRole, STEP_ROLES } from '@stodge-inc/block-rendering';
import { ApplyToOfferAndRouteToData } from '../../types';
import { psnOptIn } from '../../utils/api';
import { getOptInSource } from '../../utils/environment';
import { getNextStep } from '../../utils/steps';
import { attemptAutoApplyFondueCashback } from '../../../../helpers/fondue-helpers';

export const applyOfferAndRouteTo = async ({
  block,
  persistAttributesFn,
  popup: { id, sourceKey, steps },
  routeToStep,
  shopId,
  state: { currentStepId, stepData },
  viewport,
}: ApplyToOfferAndRouteToData): Promise<void> => {
  // Attributes
  persistAttributesFn(stepData);

  // PSN
  const currentStep = steps.find((step) => step.id === currentStepId);

  if (hasRole(currentStep, STEP_ROLES.PSN)) {
    const { offer: fondueCode } =
      (await psnOptIn({
        popupId: id,
        referrer: {
          shopId: +shopId,
          subscriberId: (window as Window).ps__subscriber_id,
        },
        source: getOptInSource(viewport),
        sourceKey,
      })) ?? {};

    // Always auto applies, not related to popup.autoApplyOfferEnabled
    if (fondueCode) await attemptAutoApplyFondueCashback(fondueCode);
  }

  // Routing
  const nextStep = getNextStep({
    block,
    currentStepId,
    steps,
  });

  routeToStep(nextStep, stepData);
};
