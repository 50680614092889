import { html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, CUSTOM_STYLE_RULE_NAMES, DEFAULT_COLORS, INPUT_STYLE_ELEMENT, LABEL_STYLE_ELEMENT, LABEL_TEXT_ELEMENT, PLACEHOLDER_TEXT_ELEMENT, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLES, } from '../constants';
import { renderError } from '../utils/errorOrSuccess';
import { getDraggableAttributeValue } from '../utils/dragdrop';
const TEXT_INPUT_TEXT_ELEMENTS = Object.assign(Object.assign({}, LABEL_TEXT_ELEMENT), PLACEHOLDER_TEXT_ELEMENT);
const TEXT_INPUT_TEXT_VARIANT = {
    SINGLE_LINE: 'single line',
    MULTI_LINE: 'multi line',
};
const TEXT_INPUT_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign({}, LABEL_STYLE_ELEMENT), INPUT_STYLE_ELEMENT), BLOCK_STYLE_ELEMENT);
const defaultStyles = {
    [TEXT_INPUT_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
        },
    },
    [TEXT_INPUT_STYLE_ELEMENTS.INPUT]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.INPUT_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            [CUSTOM_STYLE_RULE_NAMES.TEXT_VARIANT]: TEXT_INPUT_TEXT_VARIANT.SINGLE_LINE,
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
    [TEXT_INPUT_STYLE_ELEMENTS.LABEL]: {
        mobile: {
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '14px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 4px 0px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.LINE_HEIGHT]: '1.2',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.updateStepData = (event) => {
        const { value } = event.target;
        popupActions.updateStepData(block.id, value);
    };
};
const validateBlockData = (block, data = '') => {
    var _a, _b, _c;
    if (((_a = block.config) === null || _a === void 0 ? void 0 : _a.required) && !data.trim()) {
        return (_c = (_b = block.config) === null || _b === void 0 ? void 0 : _b.requiredMessage) !== null && _c !== void 0 ? _c : 'This field is required';
    }
    return null;
};
function render(renderData) {
    var _a, _b, _c, _d, _e, _f;
    const { blockActions, blockData, block, classes, content, environment, state, } = renderData;
    const { block: blockClasses, input: inputClasses } = classes;
    const labelContent = (_a = content[TEXT_INPUT_TEXT_ELEMENTS.LABEL]) !== null && _a !== void 0 ? _a : 'New input';
    const placeholderContent = (_b = content[TEXT_INPUT_TEXT_ELEMENTS.PLACEHOLDER]) !== null && _b !== void 0 ? _b : '';
    const inputId = `input-${block.id}`;
    const inputStyle = ((_d = (_c = block.styles) === null || _c === void 0 ? void 0 : _c[TEXT_INPUT_STYLE_ELEMENTS.INPUT]) === null || _d === void 0 ? void 0 : _d.mobile) || {};
    const isMultiLine = (inputStyle[CUSTOM_STYLE_RULE_NAMES.TEXT_VARIANT] || '') ===
        TEXT_INPUT_TEXT_VARIANT.MULTI_LINE;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(getDraggableAttributeValue(environment, blockActions))}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${isMultiLine
        ? html `
            <textarea
              id=${inputId}
              aria-label=${labelContent}
              aria-describedby=${ifDefined((state === null || state === void 0 ? void 0 : state.error) ? `${inputId}-error` : undefined)}
              aria-invalid=${ifDefined(!!(state === null || state === void 0 ? void 0 : state.error))}
              class=${classMap(inputClasses)}
              data-popup-engagement="true"
              placeholder=${placeholderContent}
              required=${ifDefined(((_e = block.config) === null || _e === void 0 ? void 0 : _e.required) ? true : undefined)}
              @change=${ifDefined(blockActions.updateStepData)}
            >
${blockData !== null && blockData !== void 0 ? blockData : nothing}</textarea
            >
          `
        : html `
            <input
              id=${inputId}
              aria-label=${labelContent}
              aria-describedby=${ifDefined((state === null || state === void 0 ? void 0 : state.error) ? `${inputId}-error` : undefined)}
              aria-invalid=${ifDefined(!!(state === null || state === void 0 ? void 0 : state.error))}
              autocomplete="off"
              class=${classMap(inputClasses)}
              data-popup-engagement="true"
              placeholder=${placeholderContent}
              required=${ifDefined(((_f = block.config) === null || _f === void 0 ? void 0 : _f.required) ? true : undefined)}
              type="text"
              @change=${ifDefined(blockActions.updateStepData)}
              value=${blockData !== null && blockData !== void 0 ? blockData : nothing}
            />
          `}
      ${renderError(state, inputId)}
    </div>
  `;
}
const textInputBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: TEXT_INPUT_STYLE_ELEMENTS,
    textElements: TEXT_INPUT_TEXT_ELEMENTS,
    validateBlockData,
};
export default textInputBlockHelpers;
