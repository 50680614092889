export const BLOCK_POPUP_CONTAINER_ID = 'ps-block-popup__container';

export const POPUP_EVENT_TYPES = {
  ENGAGEMENT: 'ENGAGEMENT',
  IMPRESSION: 'IMPRESSION',
  SUBMIT_EMAIL: 'SUBMIT_EMAIL',
  UNIQUE_IMPRESSION: 'UNIQUE_IMPRESSION',
} as const;

export const PAGE_EVENT_TYPES = {
  UNIQUE_VISITOR: 'UNIQUE_VISITOR',
  SESSION: 'SESSION',
} as const;

export const CLOSE_POPUP_STEP_ID = 'CLOSE_POPUP_STEP';
