import { Popup } from '../../types/popup';
import { VariantTheme } from '../../types/theme';

// unmapped desktop + sideImgUrl (only available on modal size) CSS var JS
// theme
const getDesktopSideImgVariantTheme = (
  styleDefaultVersion: Popup['styleDefaultVersion'] = 1,
): VariantTheme => ({
  '--ps-popup-close-button-text-color': 'var(--ps-popup-primary-color)',
  '--ps-popup-container-height': 'auto',
  '--ps-popup-container-margin-top': '0rem',
  '--ps-popup-container-margin-right': '0rem',
  '--ps-popup-container-margin-bottom': '0rem',
  '--ps-popup-container-margin-left': '0rem',
  '--ps-popup-container-width':
    'calc(25rem - var(--ps-popup-container-margin-left) - var(--ps-popup-container-margin-right))',
  '--ps-popup-container-padding-top': '2.625rem',
  '--ps-popup-container-padding-right': '3rem',
  '--ps-popup-container-padding-bottom': '3rem',
  '--ps-popup-container-padding-left': '3rem',
  '--ps-popup-form-max-height': '100%',
  ...(styleDefaultVersion <= 2
    ? {
        '--ps-popup-container-height': '600px',
      }
    : {}),
});

export default getDesktopSideImgVariantTheme;
