export const isDragDropEnabled = (environment, blockActions) => !!environment.isDevelopment &&
    !!(blockActions.handleReorderDragEnd ||
        blockActions.handleReorderDragStart ||
        blockActions.handleReorderDrop);
export const getDraggableAttributeValue = (environment, blockActions) => {
    if (!isDragDropEnabled(environment, blockActions)) {
        return undefined;
    }
    return 'true';
};
