import { LitElement, html, nothing } from 'lit';
import './button.css';
import '../loader';
import { defineElementSafely } from '../utils';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../constants';

export class Button extends LitElement {
  declare id: HTMLButtonElement['id'];
  declare primaryText: string;

  declare isDisabled?: boolean;
  declare isEngagement?: boolean;
  declare isLoading?: boolean;
  declare onClick?: (event: Event) => void;
  declare secondaryText?: string;
  declare type?: HTMLButtonElement['type'];

  constructor() {
    super();
    this.isEngagement = true;
    this.isLoading = false;
  }

  static get properties() {
    return {
      id: { type: String },
      isDisabled: { type: Boolean },
      isEngagement: { type: Boolean },
      isLoading: { type: Boolean },
      onClick: { type: Function },
      primaryText: { type: String },
      secondaryText: { type: String },
      type: { type: String },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.classList.add(`${CUSTOM_CSS_SELECTOR_PREFIX}button-wrapper`);
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  render() {
    return html`
      <button
        ?data-loading=${this.isLoading}
        data-popup-engagement=${this.isEngagement ? 'true' : nothing}
        ?disabled=${this.isDisabled}
        @click=${this.onClick}
        class=${`${CUSTOM_CSS_SELECTOR_PREFIX}button`}
        id=${this.id}
        type=${this.type ?? 'button'}
      >
        <span
          >${this.primaryText}
          ${this.secondaryText
            ? html`<small>${this.secondaryText}</small>`
            : nothing}
        </span>
      </button>
      ${this.isLoading
        ? html`<ps-loader
            .color=${this.type === 'submit'
              ? 'var(--ps-popup-button-text-color)'
              : 'var(--ps-popup-close-button-text-color)'}
          ></ps-loader>`
        : nothing}
    `;
  }
}

defineElementSafely('ps-button', Button);
