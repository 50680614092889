import { VariantTheme } from '../../types/theme';

// unmapped desktop + fullscreen size CSS var JS theme
const desktopFullscreen: VariantTheme = {
  '--ps-popup-close-button-text-color': 'var(--ps-popup-primary-color)',
  '--ps-popup-container-height': '100%',
  '--ps-popup-container-margin-top': '0rem',
  '--ps-popup-container-margin-right': '0rem',
  '--ps-popup-container-margin-bottom': '0rem',
  '--ps-popup-container-margin-left': '0rem',
  '--ps-popup-container-width':
    'calc(100vw - var(--ps-popup-container-margin-left) - var(--ps-popup-container-margin-right))',
  // TODO(Kameron Ahler): why is padding so different for fullscreen?
  '--ps-popup-container-padding-top': '0rem',
  '--ps-popup-container-padding-right': '10%',
  '--ps-popup-container-padding-bottom': '15vh',
  '--ps-popup-container-padding-left': '10%',
  '--ps-popup-form-max-height': '100%', // somehow this works on full screen
};

export default desktopFullscreen;
