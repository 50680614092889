import { captureException } from '@sentry/browser';
import {
  BlockPopup,
  ScreenSize,
  loadFontsForPopup,
} from '@stodge-inc/block-rendering';
import { BlockPopupStateManager } from '../BlockPopupStateManager';
import {
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  POPUP_TRIGGER_TYPES,
  POPUP_UNIQUE_IMPRESSION_COOKIE_NAME,
} from '../../../../helpers/constants';
import { configStateStore } from '../../helpers/stateManager';
import {
  getCookieValueByKey,
  extractCookieValuesByPrefix,
} from '../../../../helpers/utils';
import { getCustomFonts } from './api';
import { findBlockPopup } from '../../helpers/find';
import {
  pageIncludedFilter,
  countryFilter,
  statusFilter,
  platformFilter,
  subscriberCohortFilter,
  generateTriggerTypeFilter,
  customerStatusFilter,
} from '../../helpers/find/filters';
import { MayHaveSplitTest } from '../../../../services/popup/types';

const fetchAndInitializeBlockPopups = async (
  blockPopups: (MayHaveSplitTest & BlockPopup)[],
  statuses: string,
  currentCountry: string,
  origin: string,
  sessionId: string,
  subscriberId: string,
  shopId: number,
  viewport: ScreenSize,
  splitTestCookies: string | null,
  uniqueImpressionCookiesAsString: string | null,
  customerOrderCount: number | null,
): Promise<boolean> => {
  const { disclaimer, flags } = configStateStore.getState();

  if (!disclaimer) return false;

  try {
    const filters = [
      pageIncludedFilter,
      countryFilter,
      statusFilter,
      subscriberCohortFilter,
      customerStatusFilter,
      platformFilter,
      generateTriggerTypeFilter(POPUP_TRIGGER_TYPES.DELAY),
    ];
    const config = {
      splitTestCookies,
      currentCountry,
      origin,
      statuses,
      subscriberId,
      viewport,
      customerOrderCount,
    };

    const targetPopup = findBlockPopup(blockPopups, filters, config);

    if (!targetPopup) {
      return false;
    }

    // TODO: call in BlockPopupStateManager to keep everything encapsulated?
    // Start loading fonts as soon as possible
    loadFontsForPopup(targetPopup, viewport, await getCustomFonts(shopId));

    const timeDelayBlockPopupStatus = getCookieValueByKey(
      POPUP_STATUS_COOKIE_NAME_PREFIX,
      targetPopup.id,
      statuses,
    );

    const uniqueImpressionCookiesObject = extractCookieValuesByPrefix(
      POPUP_UNIQUE_IMPRESSION_COOKIE_NAME,
      uniqueImpressionCookiesAsString,
    );

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const timeDelayBlockPopupStateManager = new BlockPopupStateManager({
      currentCountry,
      disclaimer,
      enabledFeatureFlags: flags?.split(',') ?? [],
      origin,
      popup: targetPopup,
      sessionId,
      shopId,
      status: timeDelayBlockPopupStatus ?? null,
      subscriberId,
      theme: targetPopup.theme,
      viewport,
      uniqueImpressionCookies: uniqueImpressionCookiesObject,
    });

    return true;
  } catch (error) {
    // no op
    // eslint-disable-next-line no-console
    console.error(`Error initializing block popups: ${error}`);
    captureException(error);
    return false;
  }
};

export default fetchAndInitializeBlockPopups;
