import { svg } from 'lit';
import { SPIN_TO_WIN_CIRCUMFERENCE, SPIN_TO_WIN_DIAMETER, SPIN_TO_WIN_RADIUS, SPIN_TO_WIN_RADIUS_ADJUSTED_FOR_STROKE_WIDTH, } from '../../constants';
import { getFlattenedViewportProps } from '../../utils';
import { getRotationForSection } from '../../utils/spinToWin';
/**
 * The background sections of the wheel. Each section is a circle with a stroke
 * that is as wide as the radius of the circle, and a dash pattern that uses one
 * dash that's the length of a section arc, and then a gap that is the remainder
 * of the circle's circumference.
 */
const spinToWinSections = (sections, viewport) => {
    const strokeDasharray = `${SPIN_TO_WIN_CIRCUMFERENCE / sections.length} ${SPIN_TO_WIN_CIRCUMFERENCE}`;
    return svg `
    <svg
      style="display: block"
      viewBox="0 0 ${SPIN_TO_WIN_DIAMETER} ${SPIN_TO_WIN_DIAMETER}"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="A roulette style wheel with ${sections.length} sections, starting at the ${sections[0].text} section."
      role="img"
    >
      ${sections.map(({ styles }, index) => {
        var _a;
        const { sectionColor = '#FFFFFF' } = (_a = getFlattenedViewportProps(viewport, styles)) !== null && _a !== void 0 ? _a : {};
        return svg `
          <circle
            cx=${SPIN_TO_WIN_RADIUS}
            cy=${SPIN_TO_WIN_RADIUS}
            fill="transparent"
            r=${SPIN_TO_WIN_RADIUS_ADJUSTED_FOR_STROKE_WIDTH}
            stroke=${sectionColor}
            stroke-dasharray=${strokeDasharray}
            stroke-width=${SPIN_TO_WIN_RADIUS}
            transform=${`rotate(${getRotationForSection(index, sections)}, ${SPIN_TO_WIN_RADIUS}, ${SPIN_TO_WIN_RADIUS})`}
          />
        `;
    })}
    </svg>
  `;
};
export default spinToWinSections;
