import { BlockPopup } from '../../../types/blockPopup';
import { Popup } from '../../types/popup';

export const isBlockPopup = (value: unknown): value is BlockPopup =>
  typeof value === 'object' &&
  value !== null &&
  'id' in value &&
  'steps' in value &&
  'stepBlocks' in value;

export const isLegacyPopup = (value: unknown): value is Popup =>
  typeof value === 'object' &&
  value !== null &&
  'id' in value &&
  'styleDefaultVersion' in value;
