import { VariantTheme } from '../../types/theme';

// unmapped desktop + banner size CSS var JS theme
const desktopBanner: VariantTheme = {
  '--ps-popup-close-button-text-color': 'var(--ps-popup-primary-color)',
  '--ps-popup-container-height': 'auto',
  '--ps-popup-container-margin-top': '0rem',
  '--ps-popup-container-margin-right': '0rem',
  '--ps-popup-container-margin-bottom': '0rem',
  '--ps-popup-container-margin-left': '0rem',
  /* TODO(Kameron Ahler): We're fighting inline styles for this variable -
  reexamine this selector when they're removed */
  '--ps-popup-container-width':
    'calc(100% - var(--ps-popup-container-margin-left) - var(--ps-popup-container-margin-right))',
  '--ps-popup-container-padding-top': '2.625rem',
  '--ps-popup-container-padding-right': '3.375rem',
  '--ps-popup-container-padding-bottom': '3.375rem',
  '--ps-popup-container-padding-left': '3.375rem',
  '--ps-popup-form-max-height':
    'calc(100vh - var(--ps-popup-container-padding-top) - var(--ps-popup-container-padding-bottom))',
};

export default desktopBanner;
