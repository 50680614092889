import { CUSTOM_EVENTS } from './constants';

const DEFAULT_EVENT_CONFIG = {
  bubbles: true,
  cancelable: true,
  composed: true,
};

export const dispatchValidateFormEvent = (target: HTMLElement) => {
  target.dispatchEvent(
    new CustomEvent(CUSTOM_EVENTS.VALIDATE_FORM, {
      ...DEFAULT_EVENT_CONFIG,
    }),
  );
};
