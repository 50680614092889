import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, DEFAULT_COLORS, INPUT_STYLE_ELEMENT, LABEL_STYLE_ELEMENT, LABEL_TEXT_ELEMENT, STYLE_ELEMENT_TYPES, STYLE_RULE_NAMES, SYSTEM_FONT_FAMILY_STYLES, } from '../constants';
import { getDraggableAttributeValue } from '../utils/dragdrop';
import { renderError } from '../utils/errorOrSuccess';
import '../web-components/date';
const AGE_GATE_INPUT_TEXT_ELEMENTS = Object.assign({}, LABEL_TEXT_ELEMENT);
const AGE_GATE_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), INPUT_STYLE_ELEMENT), LABEL_STYLE_ELEMENT);
const defaultStyles = {
    [AGE_GATE_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.OPACITY]: '1',
            [STYLE_RULE_NAMES.PADDING]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'row',
            [STYLE_RULE_NAMES.FLEX_WRAP]: 'nowrap',
            [STYLE_RULE_NAMES.GAP]: '6px 6px',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'flex',
            [STYLE_RULE_NAMES.HEIGHT]: 'fit-content',
            [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'stretch',
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [AGE_GATE_BLOCK_STYLE_ELEMENTS.INPUT]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: DEFAULT_COLORS.INPUT_BG,
            [STYLE_RULE_NAMES.BORDER_COLOR]: DEFAULT_COLORS.BORDER,
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'none',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.COLOR]: DEFAULT_COLORS.TEXT,
            [STYLE_RULE_NAMES.FONT_FAMILY]: SYSTEM_FONT_FAMILY_STYLES.ARIAL,
            [STYLE_RULE_NAMES.FONT_SIZE]: '16px',
            [STYLE_RULE_NAMES.FONT_STYLE]: 'normal',
            [STYLE_RULE_NAMES.FONT_WEIGHT]: 'normal',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.PADDING]: '8px 8px 8px 8px',
            [STYLE_RULE_NAMES.TEXT_ALIGN]: 'left',
            [STYLE_RULE_NAMES.TEXT_DECORATION]: 'none',
            // private
            [STYLE_RULE_NAMES.WIDTH]: '100%',
        },
    },
};
const addBlockActions = ({ block, blockActions, popupActions, }) => {
    // eslint-disable-next-line no-param-reassign
    blockActions.updateStepData = (value) => {
        popupActions.updateStepData(block.id, value);
    };
};
const DEFAULT_LABEL_VALUE = 'Birthdate field';
const validateBlockData = (block, data) => {
    var _a, _b, _c;
    if (!(data === null || data === void 0 ? void 0 : data.trim())) {
        return `${(_c = (_b = (_a = block.content) === null || _a === void 0 ? void 0 : _a[AGE_GATE_INPUT_TEXT_ELEMENTS.LABEL]) === null || _b === void 0 ? void 0 : _b.default) !== null && _c !== void 0 ? _c : DEFAULT_LABEL_VALUE} is required`;
    }
    return null;
};
function render(renderData) {
    var _a, _b;
    const { block, blockActions, blockData, classes, content, environment, finalStyleRules, state, } = renderData;
    const { block: blockClasses, input: inputClasses } = classes;
    const labelContent = (_a = content[AGE_GATE_INPUT_TEXT_ELEMENTS.LABEL]) !== null && _a !== void 0 ? _a : DEFAULT_LABEL_VALUE;
    const arrow = (_b = finalStyleRules[STYLE_ELEMENT_TYPES.INPUT]) === null || _b === void 0 ? void 0 : _b[STYLE_RULE_NAMES.DROPDOWN_ARROW];
    const handleChange = (e) => {
        blockActions.updateStepData(e.detail);
    };
    const inputId = `input-${block.id}`;
    return html `
    <ps-date-input
      class=${classMap(blockClasses)}
      draggable=${ifDefined(getDraggableAttributeValue(environment, blockActions))}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
      .id=${inputId}
      .label=${labelContent}
      .inputClasses=${inputClasses}
      .aria-describedBy=${(state === null || state === void 0 ? void 0 : state.error) ? `${inputId}-error` : undefined}
      .arrow=${arrow}
      ?error=${ifDefined(!!(state === null || state === void 0 ? void 0 : state.error))}
      ?required=${true}
      ?readonly=${ifDefined(environment.isDevelopment ? true : undefined)}
      .value=${blockData}
      @change=${handleChange}
    ></ps-date-input>
    ${renderError(state, inputId)}
  `;
}
const ageGateBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: AGE_GATE_BLOCK_STYLE_ELEMENTS,
    textElements: AGE_GATE_INPUT_TEXT_ELEMENTS,
    validateBlockData,
};
export default ageGateBlockHelpers;
