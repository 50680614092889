const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const REFERENCE_LEAP_YEAR = 1904;
const YEAR_DURATION = 150;
export const generateMonthOptions = () => [
    { label: 'Month', value: '' },
    ...MONTHS.map((month, index) => ({
        label: month,
        value: (index + 1).toString(),
    })),
];
export const getDaysInMonth = (month = 1, year = REFERENCE_LEAP_YEAR) => new Date(year, month, 0, 0, 0, 0, 0).getDate();
export const generateDayOptions = (month, year) => {
    const numberDays = getDaysInMonth(month, year);
    const options = [
        {
            label: 'Day',
            value: '',
        },
    ];
    for (let i = 0; i < numberDays; i += 1) {
        const value = (i + 1).toString();
        options.push({ value, label: value });
    }
    return options;
};
export const parseDateFromValue = (value) => {
    if (!value) {
        return [undefined, undefined, undefined];
    }
    const date = new Date(value);
    const month = Number.isNaN(date.getMonth()) ? undefined : date.getMonth() + 1;
    const day = Number.isNaN(date.getDate()) ? undefined : date.getDate();
    const year = Number.isNaN(date.getFullYear())
        ? undefined
        : date.getFullYear();
    return [month, day, year];
};
export const generateValueFromDates = (month, day, year) => {
    if (month === undefined || day === undefined || year === undefined) {
        return undefined;
    }
    return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0)).toISOString();
};
export const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - YEAR_DURATION;
    const options = [
        {
            label: 'Year',
            value: '',
        },
    ];
    for (let i = currentYear; i >= startingYear; i -= 1) {
        const value = i.toString();
        options.push({ value, label: value });
    }
    return options;
};
