/* eslint-disable @typescript-eslint/no-explicit-any */
// This file contains shared helpers that are used to configure common behavior for desktop and mobile popups
import {
  POPUP_CONTAINER_ID,
  POPUP_DESKTOP_WIDGET_CONTAINER,
  POPUP_HARD_CLOSE_STATE,
  POPUP_SOFT_CLOSE_STATE,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  POPUP_TRIGGER_TYPES,
} from '../../../helpers/constants';
import { FADE_OUT_CLASS } from '../../../helpers/ui';
import { delay } from '../../../helpers/utility';
import { getCookieValueByKey, preloadImages } from '../../../helpers/utils';
import {
  ACTIVE_POPUP_BEHAVIORS,
  CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE,
} from '../../../sdk/core/popups/constants';
import { PopupOpenOptions } from '../../../sdk/core/popups/types';
import { selectAppropriatePopupForPage } from '../../../services/popup/helper';
import { Popup } from '../../types/popup';
import {
  popupStateStore,
  ActivePopupContext,
  POPUP_STATUS_FRIENDLY_NAMES,
} from './stateManager';

const FORCED_OPEN_POPUP_STATUS = '';

type PopupInitializationParams = {
  shopId: number;
  statuses: string;
  sessionId: string;
  previewData: any;
  currentCountry: string;
};

type PopupListenerConfig = {
  popups: Popup[];
  initializePopup: (
    popup: Popup,
    shopId: number,
    status: string,
    sessionId: string,
    previewData: any,
    forceOpen?: boolean,
  ) => void;
} & PopupInitializationParams;

const isValidEvent = (event: MessageEvent): boolean => {
  if (
    event?.data?.type !== CUSTOM_POPUP_TRIGGER_MESSAGE_TYPE ||
    !event?.data?.id
  ) {
    return false;
  }

  return true;
};

const isPopupTriggerAllowed = (
  option: PopupOpenOptions,
  activePopupState: Partial<ActivePopupContext>,
): boolean => {
  if (
    !activePopupState.id ||
    activePopupState.status === POPUP_STATUS_FRIENDLY_NAMES.HARD_CLOSE
  ) {
    return true;
  }

  switch (option.activePopupBehavior) {
    case ACTIVE_POPUP_BEHAVIORS.ALWAYS_DISMISS:
      return true;
    case ACTIVE_POPUP_BEHAVIORS.DISMISS_WHEN_SOFT_CLOSED:
      return activePopupState.status === POPUP_STATUS_FRIENDLY_NAMES.SOFT_CLOSE;
    case ACTIVE_POPUP_BEHAVIORS.NEVER_DISMISS:
      return false;
  }
};

const removeFadeOutClass = (): void => {
  // The class is present on entirely different elements on mobile and desktop
  const containerWithFadeOutClass =
    document.getElementById(POPUP_CONTAINER_ID) ??
    document.getElementById(POPUP_DESKTOP_WIDGET_CONTAINER);

  containerWithFadeOutClass?.classList.remove(FADE_OUT_CLASS);
};

const clearWindowVariables = (): void => {
  window.ps__email = undefined;
  window.ps__token = undefined;
  window.ps__subscriber_id = undefined;
  window.ps__server_id = undefined;
};

export const clearActivePopupContext = () => {
  // End engagement analytics session of current popup before we programmatically open a new one
  const state = popupStateStore.getState();
  state.engagementTracker?.endSession();

  clearWindowVariables();
  popupStateStore.reset();
};

export const addCustomTriggerV2PopupMessageListener = (
  config: PopupListenerConfig,
): void => {
  const { shopId, statuses: statusCookies, sessionId, previewData } = config;

  window.addEventListener('message', async (event) => {
    const { data: { id, options } = {} } = event;

    if (
      !isValidEvent(event) ||
      !isPopupTriggerAllowed(options, popupStateStore.getState())
    ) {
      return;
    }

    const popup = config.popups.find((p) => p.id === id);

    if (!popup) {
      // eslint-disable-next-line no-console
      console.error(`Popup with id ${id} was not found.`);
      return;
    }

    const currentPopupStatus = getCookieValueByKey(
      POPUP_STATUS_COOKIE_NAME_PREFIX,
      id,
      statusCookies,
    );

    if (
      options.respectPopupStatus &&
      currentPopupStatus === POPUP_HARD_CLOSE_STATE
    ) {
      // Exit early if the previous status is HARD_CLOSE
      return;
    }

    const { logoUrl, sideImgUrl, backgroundImage } = popup;
    preloadImages([logoUrl, sideImgUrl, backgroundImage]);

    /* If a popup is configured to hard close on exit, .fade-out is added a
    container to animate the popup closing. On mobile, the class is removed
    after a period of time, but on desktop it persists. If you try to
    programmatically open another desktop popup afterwards, it will immediately
    disappear because the container will still have the .fade-out class on it.
    So, to be safe, we preemptively attempt to remove that class before
    initializing a popup via programmatic trigger (regardless of mobile vs.
    desktop). */
    removeFadeOutClass();

    config.initializePopup(
      popup,
      shopId,
      options.respectPopupStatus ? statusCookies : FORCED_OPEN_POPUP_STATUS,
      sessionId,
      previewData,
    );
  });
};

export const initializeV2TimeDelayPopup = async (
  popupsToChooseFrom: Popup[],
  status: string,
  parentOrigin: string,
  currentCountry: string,
  subscriberId: string,
  openPopup: (popup: Popup) => void,
) => {
  // Old popups don't have a trigger set, so we default to time delay
  const timeDelayPopups = popupsToChooseFrom.filter(
    (p) => !p.trigger || p.trigger === POPUP_TRIGGER_TYPES.DELAY,
  );
  const timeDelayPopup = selectAppropriatePopupForPage(
    timeDelayPopups,
    status,
    parentOrigin,
    currentCountry,
    subscriberId,
  );

  if (timeDelayPopup) {
    const { logoUrl, sideImgUrl, backgroundImage } = timeDelayPopup;
    preloadImages([logoUrl, sideImgUrl, backgroundImage]);
    await delay(timeDelayPopup.delay);

    // If a popup is already open  and new popup is soft closed we should not open the new one
    const state = popupStateStore.getState();
    if (state.status === POPUP_STATUS_FRIENDLY_NAMES.OPEN) {
      const timeDelayPopupStatus = getCookieValueByKey(
        POPUP_STATUS_COOKIE_NAME_PREFIX,
        timeDelayPopup.id,
        status,
      );

      if (timeDelayPopupStatus === POPUP_SOFT_CLOSE_STATE) {
        return;
      }
    }

    openPopup(timeDelayPopup);
  }
};

export const initializeV2ExitIntentPopup = (
  popupsToChooseFrom: Popup[],
  status: string,
  parentOrigin: string,
  currentCountry: string,
  subscriberId: string,
  openPopup: (popup: Popup) => void,
) => {
  const exitIntentPopups = popupsToChooseFrom.filter(
    (p) => p.trigger === POPUP_TRIGGER_TYPES.EXIT,
  );

  const exitIntentPopup = selectAppropriatePopupForPage(
    exitIntentPopups,
    status,
    parentOrigin,
    currentCountry,
    subscriberId,
  );

  if (!exitIntentPopup) {
    return;
  }

  const { logoUrl, sideImgUrl, backgroundImage } = exitIntentPopup;
  preloadImages([logoUrl, sideImgUrl, backgroundImage]);

  const currentPopupStatus = getCookieValueByKey(
    POPUP_STATUS_COOKIE_NAME_PREFIX,
    exitIntentPopup.id,
    status,
  );

  if (!currentPopupStatus) {
    window.parent.postMessage(
      {
        trigger: exitIntentPopup.trigger,
        id: exitIntentPopup.id,
      },
      '*',
    );
  } else if (currentPopupStatus === POPUP_SOFT_CLOSE_STATE) {
    openPopup(exitIntentPopup);
  }
};
