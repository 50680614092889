import { html, nothing, unsafeCSS } from 'lit';
import SDKBlockHelpers from '../blocks';
import { BLOCK_TYPES, STYLE_ELEMENT_TYPES } from '../constants';
import renderDropZone from '../development/dropZone';
import { getBlockContent } from './content';
import { createBasePopupCSS, createBlockInstanceStyleElementClass, createEditorCSS, createInstanceCSSForBlocks, getFinalStyleRulesForBlock, getLayoutDirection, } from './styles';
const getEditorActions = (block, renderContext) => {
    const { selectBlock, handleReorderDragStart, handleReorderDragEnd, setEmailIntegration, } = renderContext.popupActions;
    const blockActions = {};
    if (selectBlock) {
        // eslint-disable-next-line no-param-reassign
        blockActions.selectBlock = (event) => {
            event.stopPropagation();
            selectBlock(block.id);
        };
    }
    if (handleReorderDragStart) {
        // eslint-disable-next-line no-param-reassign
        blockActions.handleReorderDragStart = (event) => {
            handleReorderDragStart(event, block.id);
        };
    }
    if (handleReorderDragEnd) {
        // eslint-disable-next-line no-param-reassign
        blockActions.handleReorderDragEnd = handleReorderDragEnd;
    }
    if (setEmailIntegration) {
        // eslint-disable-next-line no-param-reassign
        blockActions.setEmailIntegration = setEmailIntegration;
    }
    return blockActions;
};
/* TODO(Kameron Ahler): This function is messy and unfocused, with quite a bit
of overlapping systems in it. It needs a refactor that considers how classes are
generated, in particular editor, "development" (editor), and dynamic classes
(animation, anything we generate at render), as well as instance styles. */
function getBlockClasses(block, renderContext) {
    var _a, _b;
    const { blockState, environment: { isDevelopment }, } = renderContext;
    const styleElements = SDKBlockHelpers.getStyleElements(block.type);
    const isContainer = block.type === BLOCK_TYPES.CONTAINER;
    const isRootBlock = block.type === BLOCK_TYPES.ROOT || block.type === BLOCK_TYPES.ROOT_TEMP;
    const isTeaserRootBlock = block.type === BLOCK_TYPES.TEASER_ROOT;
    const isEmpty = SDKBlockHelpers.isEmpty(block);
    const layoutDirection = getLayoutDirection(block, renderContext);
    const isHorizontalLayout = layoutDirection === 'row';
    const isVerticalLayout = layoutDirection === 'column';
    const isRootTempBlock = block.type === BLOCK_TYPES.ROOT_TEMP;
    /* TODO(fullscreen): once the original root block type has a main popup
        container, this first check for the block style element should be changed
        to target containers only. It may be an opportune time to pull the logic
        for this map out, and refactor it. It's gotten convoluted over time. */
    const developmentClasses = {
        [STYLE_ELEMENT_TYPES.BLOCK]: Object.assign({ 'root-block': isRootBlock, 'teaser-root-block': isTeaserRootBlock, container: isContainer }, (isRootTempBlock
            ? {}
            : {
                'empty-layout': isEmpty,
                'horizontal-layout': isHorizontalLayout,
                'vertical-layout': isVerticalLayout,
            })),
        [STYLE_ELEMENT_TYPES.MAIN_POPUP_CONTAINER]: Object.assign({ 'main-popup-container': isRootTempBlock }, (isRootTempBlock
            ? {
                'empty-layout': isEmpty,
                'horizontal-layout': isHorizontalLayout,
                'vertical-layout': isVerticalLayout,
            }
            : {})),
    };
    const editorNodeClasses = {
        'editor-node': true,
        error: !!((_a = blockState[block.id]) === null || _a === void 0 ? void 0 : _a.error),
        selected: !!((_b = blockState[block.id]) === null || _b === void 0 ? void 0 : _b.selected),
    };
    const baseClasses = {
        editorNode: editorNodeClasses,
    };
    return Object.values(styleElements).reduce((acc, styleElement) => {
        const hasDevelopmentClasses = styleElement === STYLE_ELEMENT_TYPES.BLOCK ||
            styleElement === STYLE_ELEMENT_TYPES.MAIN_POPUP_CONTAINER;
        const instanceBlockStyleElementClass = createBlockInstanceStyleElementClass(block, styleElement);
        return Object.assign(Object.assign({}, acc), { [styleElement]: Object.assign(Object.assign({}, (isDevelopment && hasDevelopmentClasses
                ? developmentClasses[styleElement]
                : {})), { [instanceBlockStyleElementClass]: true }) });
    }, baseClasses);
}
function getBlockActions(block, renderContext) {
    const { popupActions, environment } = renderContext;
    const blockActions = environment.isDevelopment
        ? getEditorActions(block, renderContext)
        : {};
    SDKBlockHelpers.addBlockActions({
        block,
        blockActions,
        environment,
        popupActions,
        stepBlocks: renderContext.stepBlocks,
    });
    return blockActions;
}
const addDropZonesToChildrenTemplates = (block, renderedChildren, renderContext) => {
    const { environment: { isDevelopment }, } = renderContext;
    const isRootBlock = block.type === BLOCK_TYPES.ROOT || block.type === BLOCK_TYPES.ROOT_TEMP;
    const isContainerBlock = block.type === BLOCK_TYPES.CONTAINER;
    if (!isDevelopment || !(isContainerBlock || isRootBlock))
        return renderedChildren;
    return renderedChildren.reduce((acc, child, index) => {
        const dropZone = renderDropZone(renderContext, block.id, index + 1);
        acc.push(child, dropZone);
        return acc;
    }, 
    /* Root block can never be empty because of undeletable icon close button,
    and thusly always has at least one dropzone in it. A container block can be
    empty, so we ensure it always has at least one drop zone. */
    isRootBlock ? [] : [renderDropZone(renderContext, block.id, 0)]);
};
function renderChildren(block, renderContext) {
    if (!block.children)
        return null;
    const { blocks, reorderingIds } = renderContext;
    const childrenRenderTemplates = block.children.map((childId) => {
        const childBlock = blocks.find((block) => block.id === childId);
        if (!childBlock)
            throw new Error(`Block with id ${childId} not found`);
        // eslint-disable-next-line no-use-before-define
        return renderBlock(childBlock, renderContext);
    }, []);
    return (reorderingIds === null || reorderingIds === void 0 ? void 0 : reorderingIds.includes(block.id))
        ? childrenRenderTemplates
        : addDropZonesToChildrenTemplates(block, childrenRenderTemplates, renderContext);
}
function renderBlock(block, renderContext) {
    const classes = getBlockClasses(block, renderContext);
    const content = getBlockContent(block);
    const children = renderChildren(block, renderContext);
    const blockActions = getBlockActions(block, renderContext);
    const state = renderContext.blockState[block.id];
    const { disclaimer, environment, theme, sharedBlockConfig } = renderContext;
    const renderData = {
        blockActions,
        block,
        children,
        classes,
        content,
        disclaimer,
        environment,
        finalStyleRules: getFinalStyleRulesForBlock(block, theme, environment.viewport),
        state,
        sharedBlockConfig,
    };
    return SDKBlockHelpers.render(renderData);
}
export const renderPopup = (renderContext) => {
    const rootBlockOrTeaserRootBlock = renderContext.blocks.find((block) => block.type === BLOCK_TYPES.ROOT ||
        block.type === BLOCK_TYPES.TEASER_ROOT ||
        block.type === BLOCK_TYPES.ROOT_TEMP);
    if (!rootBlockOrTeaserRootBlock) {
        throw new Error('No root block found');
    }
    /*
      @TODO - Could we memoize the CSS creation somehow, when not in the editor?
      Or mount it to the DOM outside of the render function so we don't have to re-render it every time?
  
      @TODO - Support multiple breakpoints/screen sizes - for now just doing mobile as thats the default size
    */
    const blockInstanceCSS = createInstanceCSSForBlocks(renderContext.blocks, renderContext.environment.viewport, renderContext.theme);
    return html `
    <style>
      ${createBasePopupCSS()}
      ${renderContext.environment.isDevelopment ? createEditorCSS() : nothing}
      ${unsafeCSS(blockInstanceCSS)}
    </style>

    ${renderBlock(rootBlockOrTeaserRootBlock, renderContext)}
  `;
};
