import { html, LitElement } from 'lit';
import { PopupPageQuestion } from '../../types/popup';
import { CUSTOM_CSS_SELECTOR_PREFIX } from '../constants';
import { defineElementSafely } from '../utils';
import './monthAndDayInput.css';
import { SelectOption } from './selectInput';

const MONTH_OPTIONS: Readonly<SelectOption[]> = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
] as const;

const DAYS_IN_EACH_MONTH: Record<string, number> = {
  '01': 31,
  '02': 29,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  '10': 31,
  '11': 30,
  '12': 31,
};

const getMonthOptions = (month?: string): SelectOption[] => {
  if (!month) {
    return [];
  }

  const daysInMonth = DAYS_IN_EACH_MONTH[month];
  return [...Array(daysInMonth).keys()].map((i) => ({
    label: (i + 1).toString(),
    value: (i + 1).toString().padStart(2, '0'),
  }));
};

class MonthAndDayInput extends LitElement {
  declare question: PopupPageQuestion;
  declare month: string;
  declare day: string;

  static get properties() {
    return {
      question: { type: Object },
      month: { state: true },
      day: { state: true },
    };
  }

  constructor() {
    super();
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  protected createRenderRoot(): Element | ShadowRoot {
    return this;
  }

  handleMonthChange(event: Event) {
    this.month = (event.target as HTMLSelectElement).value as string;
    const dayDropdown = document.querySelector(
      `#${this.question.attributeKey}_day`,
    ) as HTMLSelectElement;
    dayDropdown.value = '';
    dayDropdown.dataset.value = '';
    this.day = '';
  }

  handleDayChange(event: Event) {
    this.day = (event.target as HTMLSelectElement).value as string;
  }

  render() {
    return html`
      <fieldset>
        <legend
          class="${CUSTOM_CSS_SELECTOR_PREFIX}month-and-day-question-legend"
        >
          ${this.question.text}
        </legend>
        <div class="${CUSTOM_CSS_SELECTOR_PREFIX}month-and-day-input-wrapper">
          <ps-select
            .question="${this.question}"
            .id="${this.question.attributeKey}_month"
            label="Month"
            .options="${MONTH_OPTIONS}"
            .handleChange="${this.handleMonthChange}"
            ?required="${!!this.day}"
          ></ps-select>
          <ps-select
            .question="${this.question}"
            .id="${this.question.attributeKey}_day"
            label="Day"
            .options="${getMonthOptions(this.month)}"
            .handleChange="${this.handleDayChange}"
            ?required="${!!this.month}"
            ?disabled="${!this.month}"
          ></ps-select>
          <input
            type="hidden"
            name="${this.question.attributeKey}"
            value="${this.month && this.day ? `${this.month}-${this.day}` : ''}"
          />
        </div>
      </fieldset>
    `;
  }
}

defineElementSafely('ps-month-and-day-input', MonthAndDayInput);
