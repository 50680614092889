import {
  BLOCK_TYPES,
  CONFIG_ONCLICK_ROUTE_DIRECTIONS,
  STEP_ROLES,
  Step,
  Block,
  StepBlock,
} from '@stodge-inc/block-rendering';
import { CLOSE_POPUP_STEP_ID } from '../constants';

const CLOSE_POPUP_STEP = {
  id: CLOSE_POPUP_STEP_ID,
  name: 'Close Popup',
  order: 9999999,
  role: STEP_ROLES.DEFAULT,
};

export const getNextStep = ({
  block,
  currentStepId,
  isExistingSubscriber,
  steps,
}: {
  block: Block;
  currentStepId: string;
  steps: Step[];
  isExistingSubscriber?: boolean;
}): Step => {
  const { existingSubscriberRouteTo, routeTo } = block.config?.onClick ?? {};
  if (!existingSubscriberRouteTo && !routeTo) return CLOSE_POPUP_STEP;

  const hasRouteToNext = routeTo === CONFIG_ONCLICK_ROUTE_DIRECTIONS.NEXT;
  const hasRouteToPrevious =
    routeTo === CONFIG_ONCLICK_ROUTE_DIRECTIONS.PREVIOUS;

  // Existing subscriber route to specific step.
  if (isExistingSubscriber)
    return (
      steps.find((step) => step.id === existingSubscriberRouteTo) ??
      CLOSE_POPUP_STEP
    );

  // Route to next or previous
  if (hasRouteToNext || hasRouteToPrevious) {
    const currentStep = steps.find((step) => step.id === currentStepId);
    if (!currentStep) return CLOSE_POPUP_STEP;

    return (
      steps.find(
        (step) => step.order === currentStep.order + (hasRouteToNext ? 1 : -1),
      ) ?? CLOSE_POPUP_STEP
    );
  }

  // Route to specific step
  return steps.find((step) => step.id === routeTo) ?? CLOSE_POPUP_STEP;
};

export const hasAnyNextStep = ({
  currentStepId,
  stepBlocks,
  steps,
}: {
  currentStepId: string;
  stepBlocks: StepBlock[];
  steps: Step[];
}): boolean => {
  // Eventually we'd probably want to check if any input blocks are on the step as well. But right now there's no way to submit
  // the form without a button block, so we can just check for button blocks.
  const buttonBlocksOnCurrentStep = stepBlocks.filter(
    (block) =>
      block.stepId === currentStepId && block.type === BLOCK_TYPES.BUTTON,
  );

  if (!buttonBlocksOnCurrentStep.length) return false;

  return buttonBlocksOnCurrentStep.some((buttonBlock) => {
    const directNextStep = getNextStep({
      block: buttonBlock,
      currentStepId,
      isExistingSubscriber: false,
      steps,
    });
    const existingSubscriberNextStep = getNextStep({
      block: buttonBlock,
      currentStepId,
      isExistingSubscriber: true,
      steps,
    });

    return (
      directNextStep.id !== CLOSE_POPUP_STEP_ID ||
      existingSubscriberNextStep.id !== CLOSE_POPUP_STEP_ID
    );
  });
};
