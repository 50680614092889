import BlockHelpers from '../blocks';
/*
  Gets the text content for each of a block's content elements.
  For now, we only have one potential piece of content for each of a block's content elements,
  which we call the `default` content. In the future, we might support mulitple versions of
  of a piece of text content, and this code would figure out which version to use.

  Example:
  ```
  const block = {
    id: '123',
    version: 1,
    type: 'title',
    content: {
      title: {
        default: 'Hello, world!',
      },
    },
  };

  const content = getBlockContent(block); // content.title === 'Hello, world!'
  ```
*/
export const getBlockContent = (block) => {
    const textElements = BlockHelpers.getTextElements(block.type);
    if (!block.content || !textElements) {
        return {};
    }
    const blockTextElements = Object.values(textElements);
    return blockTextElements.reduce((acc, element) => {
        var _a, _b;
        // eslint-disable-next-line no-param-reassign
        acc[element] = ((_b = (_a = block.content) === null || _a === void 0 ? void 0 : _a[element]) === null || _b === void 0 ? void 0 : _b.default) || '';
        return acc;
    }, {});
};
