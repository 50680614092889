import { OtpVerifyErrors, VerifyResponse } from '../helpers/types';

export const getOtpVerifyErrors = (
  verifyResponse: VerifyResponse,
): OtpVerifyErrors => {
  // This covers both network error and unsuccessful status code from API
  const general = verifyResponse === null || verifyResponse.status !== 200;

  // If we failed to validate the OTP
  const verification = !verifyResponse?.data?.success;

  if (general) return { general };
  if (verification) return { verification };
  return {};
};
