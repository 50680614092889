const DropdownArrowChevron = `<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.86635 8.50033C7.11929 8.26546 7.51475 8.2801 7.74962 8.53305L9.99996 10.9565L12.2503 8.53305C12.4852 8.2801 12.8806 8.26546 13.1336 8.50033C13.3865 8.73521 13.4012 9.13067 13.1663 9.38361L10.458 12.3003C10.3397 12.4276 10.1738 12.5 9.99996 12.5C9.82617 12.5 9.66022 12.4276 9.54197 12.3003L6.83363 9.38361C6.59876 9.13067 6.6134 8.73521 6.86635 8.50033Z"
      fill="currentColor"
    />
  </svg>`;
const DropdownArrowCaret = `<svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.89737 7.84952C6.00242 7.63557 6.22003 7.5 6.45838 7.5H13.5417C13.7801 7.5 13.9977 7.63557 14.1027 7.84952C14.2078 8.06347 14.182 8.31855 14.0363 8.50715L10.4946 13.0905C10.3762 13.2436 10.1936 13.3333 10 13.3333C9.80648 13.3333 9.62384 13.2436 9.50549 13.0905L5.96383 8.50715C5.81809 8.31855 5.79231 8.06347 5.89737 7.84952ZM7.73119 8.75L10 11.6862L12.2689 8.75H7.73119Z"
        fill="currentColor"
      />
    </svg>`;
const DropdownArrowCaretFilled = `<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.89737 7.84952C6.00242 7.63557 6.22003 7.5 6.45838 7.5H13.5417C13.7801 7.5 13.9977 7.63557 14.1027 7.84952C14.2078 8.06347 14.182 8.31855 14.0363 8.50715L10.4946 13.0905C10.3762 13.2436 10.1936 13.3333 10 13.3333C9.80648 13.3333 9.62384 13.2436 9.50549 13.0905L5.96383 8.50715C5.81809 8.31855 5.79231 8.06347 5.89737 7.84952ZM7.73119 8.75L10 11.6862L12.2689 8.75H7.73119Z"
      fill="currentColor"
    />
    <path
      d="M7.63867 8.6969H12.3072L9.97944 11.8361L7.63867 8.6969Z"
      fill="currentColor"
    />
  </svg>`;
export const DropdownArrows = {
    chevron: DropdownArrowChevron,
    caret: DropdownArrowCaret,
    caretFilled: DropdownArrowCaretFilled,
};
