import { VariantTheme } from '../../types/theme';

// unmapped mobile + partial size CSS var JS theme
const mobilePartial: VariantTheme = {
  '--ps-popup-close-button-text-color': 'var(--ps-popup-text-color)',
  '--ps-popup-container-height': '100vh',
  '--ps-popup-container-margin-top': '0rem', // used in calc so must be '0rem'
  '--ps-popup-container-margin-right': '1rem',
  '--ps-popup-container-margin-bottom': '1rem', // only needed for legacy
  '--ps-popup-container-margin-left': '1rem',
  '--ps-popup-container-width':
    'calc(100% - var(--ps-popup-container-margin-left) - var(--ps-popup-container-margin-right))',
  '--ps-popup-container-padding-top': '3rem',
  '--ps-popup-container-padding-right': '2.25rem',
  '--ps-popup-container-padding-bottom': '3rem',
  '--ps-popup-container-padding-left': '2.25rem',
  '--ps-popup-form-max-height':
    'calc(100vh - var(--ps-popup-container-padding-top) - var(--ps-popup-container-padding-bottom) - var(--ps-popup-container-margin-top) - var(--ps-popup-container-margin-bottom))',
};

export default mobilePartial;
