import * as Sentry from '@sentry/browser';
import { ErrorEvent } from '@sentry/types';
import { HttpClient } from '@sentry/integrations';

export const initializeSentry = (isPreview: boolean) => {
  // Sentry release is only set for dev/prod deploys so this prevents logging errors locally.
  if (!process.env.SENTRY_RELEASE) {
    return;
  }

  const ignoredTypeErrors = [
    /*
  These are by far our most common errors, but sentry does not attach a stack trace so it's difficult to diagnose.
  We can't reproduce this so best guess is that it's caused by users leaving the page before a request finishes.
  The lack of stack trace is a known sentry issue, we should keep an eye on https://github.com/getsentry/sentry-javascript/discussions/5334
  and re-enable this (probably behind a flag) if/when it gets fixed
  */
    'Load failed',
    'Failed to fetch',
    // The following are all 'cancelled' in various languages
    'cancelled',
    'annulé',
    '已取消',
    'Abgebrochen',
    'キャンセルしました',
    'cancelado',
    'avbruten',
    'geannuleerd',
  ];

  Sentry.init({
    dsn: 'https://1f67009d53b34b6e9118ddf500f45208@o142330.ingest.sentry.io/4504340660355072',
    integrations: [
      new HttpClient({
        failedRequestStatusCodes: [[400, 599]],
      }),
    ],
    environment: process.env.ENV,
    release: process.env.SENTRY_RELEASE,
    sampleRate: isPreview ? 1 : 0.1,
    beforeSend: (event: ErrorEvent) => {
      const isIgnoredError = event.exception?.values?.some(
        (value) =>
          value.type === 'TypeError' &&
          value.value &&
          ignoredTypeErrors.includes(value.value),
      );

      return isIgnoredError ? null : event;
    },
  });
};
