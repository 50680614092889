// This file contains shared helpers that are used to configure common behavior for desktop and mobile popups
import { SCREEN_SIZES } from '@stodge-inc/block-rendering';
import {
  POPUP_SOFT_CLOSE_STATE,
  POPUP_STATUS_COOKIE_NAME_PREFIX,
  POPUP_TRIGGER_TYPES,
} from '../../../helpers/constants';
import { delay } from '../../../helpers/utility';
import { getCookieValueByKey, preloadImages } from '../../../helpers/utils';
import { Popup } from '../../types/popup';
import { findLegacyPopup } from './find';
import {
  pageIncludedFilter,
  countryFilter,
  statusFilter,
  subscriberCohortFilter,
  generateTriggerTypeFilter,
} from './find/filters';
import { popupStateStore, POPUP_STATUS_FRIENDLY_NAMES } from './stateManager';
import { MayHaveSplitTest } from '../../../services/popup/types';

const clearWindowVariables = (): void => {
  window.ps__email = undefined;
  window.ps__token = undefined;
  window.ps__subscriber_id = undefined;
  window.ps__server_id = undefined;
};

export const clearActivePopupContext = () => {
  // End engagement analytics session of current popup before we programmatically open a new one
  const state = popupStateStore.getState();
  state.engagementTracker?.endSession();

  clearWindowVariables();
  popupStateStore.reset();
};

export const initializeV2TimeDelayPopup = async (
  popupsToChooseFrom: Popup[],
  statuses: string,
  origin: string,
  currentCountry: string,
  subscriberId: string,
  splitTestCookies: string | null,
  openPopup: (popup: Popup) => void,
) => {
  const filters = [
    pageIncludedFilter,
    countryFilter,
    statusFilter,
    subscriberCohortFilter,
    generateTriggerTypeFilter(POPUP_TRIGGER_TYPES.DELAY),
  ];
  const config = {
    splitTestCookies,
    currentCountry,
    origin,
    statuses,
    subscriberId,
    viewport: SCREEN_SIZES.DESKTOP,
  };

  const timeDelayPopup = findLegacyPopup(
    popupsToChooseFrom as (MayHaveSplitTest & Popup)[],
    filters,
    config,
  );

  if (timeDelayPopup) {
    const { logoUrl, sideImgUrl, backgroundImage } = timeDelayPopup;
    preloadImages([logoUrl, sideImgUrl, backgroundImage]);
    await delay(timeDelayPopup.delay);

    // If a popup is already open  and new popup is soft closed we should not open the new one
    const state = popupStateStore.getState();
    if (state.status === POPUP_STATUS_FRIENDLY_NAMES.OPEN) {
      const timeDelayPopupStatus = getCookieValueByKey(
        POPUP_STATUS_COOKIE_NAME_PREFIX,
        timeDelayPopup.id,
        statuses,
      );

      if (timeDelayPopupStatus === POPUP_SOFT_CLOSE_STATE) {
        return;
      }
    }

    openPopup(timeDelayPopup);
  }
};

export const initializeV2ExitIntentPopup = (
  popupsToChooseFrom: Popup[],
  statuses: string,
  origin: string,
  currentCountry: string,
  subscriberId: string,
  splitTestCookies: string | null,
  openPopup: (popup: Popup) => void,
) => {
  const filters = [
    pageIncludedFilter,
    countryFilter,
    statusFilter,
    subscriberCohortFilter,
    generateTriggerTypeFilter(POPUP_TRIGGER_TYPES.EXIT),
  ];
  const config = {
    splitTestCookies,
    currentCountry,
    origin,
    statuses,
    subscriberId,
    viewport: SCREEN_SIZES.DESKTOP,
  };

  const exitIntentPopup = findLegacyPopup(
    popupsToChooseFrom as (MayHaveSplitTest & Popup)[],
    filters,
    config,
  );

  if (!exitIntentPopup) {
    return;
  }

  const { logoUrl, sideImgUrl, backgroundImage } = exitIntentPopup;
  preloadImages([logoUrl, sideImgUrl, backgroundImage]);

  const currentPopupStatus = getCookieValueByKey(
    POPUP_STATUS_COOKIE_NAME_PREFIX,
    exitIntentPopup.id,
    statuses,
  );

  if (!currentPopupStatus) {
    window.parent.postMessage(
      {
        trigger: exitIntentPopup.trigger,
        id: exitIntentPopup.id,
      },
      '*',
    );
  } else if (currentPopupStatus === POPUP_SOFT_CLOSE_STATE) {
    openPopup(exitIntentPopup);
  }
};
