import { html } from 'lit';
import { V2_OPT_IN_SOURCES } from '../../../helpers/constants';
import emailForm from '../../common/emailForm';
import closeButton from './closeButton';
import emailInput from './emailInput';
import exitButton from './exitButton';
import logoHeader from './logoHeader';
import submitButton from './submitButton';

const email = ({
  headlineComponent,
  onExit,
  logoUrl,
  emailSubscribeText,
  emailCloseText,
  ...rest
}) => html`
  <main>
    ${exitButton({ onClick: onExit })} ${logoHeader({ logoUrl })}
    ${headlineComponent}
    ${emailForm({
      content: html`
        ${emailInput()} ${submitButton({ text: emailSubscribeText })}
        ${closeButton({ text: emailCloseText, onClick: onExit })}
      `,
      source: V2_OPT_IN_SOURCES.DESKTOP,
      ...rest,
    })}
  </main>
`;

export default email;
