import { BlockStateMap, PopupData } from '@stodge-inc/block-rendering';
import { POPUP_EVENT_TYPES } from '../constants';

export type PopupEventTypes =
  typeof POPUP_EVENT_TYPES[keyof typeof POPUP_EVENT_TYPES];

export const BlockPopupStatuses = {
  CLOSED: 'closed',
  OPEN: 'visible',
  TEASER: 'teaser',
} as const;

export type BlockPopupStatus =
  typeof BlockPopupStatuses[keyof typeof BlockPopupStatuses];

export type PopupState = {
  currentStepId: string; // pageId UUID
  status: BlockPopupStatus;
  blockState: BlockStateMap;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepData: Record<string, any>;
  popupData: PopupData;
};
