import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BLOCK_STYLE_ELEMENT, STYLE_RULE_NAMES } from '../constants';
import { getDraggableAttributeValue } from '../utils/dragdrop';
const CONTAINER_BLOCK_STYLE_ELEMENTS = Object.assign({}, BLOCK_STYLE_ELEMENT);
const defaultStyles = {
    [CONTAINER_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: 'transparent',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.LAYOUT_DIRECTION]: 'column',
            [STYLE_RULE_NAMES.LAYOUT_SPACING]: 'flex-start',
            [STYLE_RULE_NAMES.MARGIN]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.MAX_WIDTH]: '100%',
            [STYLE_RULE_NAMES.PADDING]: '12px 12px 12px 12px',
            // private
            [STYLE_RULE_NAMES.DISPLAY]: 'flex',
            [STYLE_RULE_NAMES.FLEX]: '1',
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
};
function isEmpty({ children = [] }) {
    return children.length === 0;
}
function render(renderData) {
    const { children, blockActions, classes, environment } = renderData;
    const { block: blockClasses } = classes;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(getDraggableAttributeValue(environment, blockActions))}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${children}
    </div>
  `;
}
const containerBlockHelpers = {
    defaultStyles,
    isEmpty,
    render,
    styleElements: CONTAINER_BLOCK_STYLE_ELEMENTS,
};
export default containerBlockHelpers;
