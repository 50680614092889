import { BlockPopup, TRIGGER_TYPES } from '@stodge-inc/block-rendering';
import {
  POPUP_FIND_MESSAGE_TYPE,
  POPUP_FOUND_MESSAGE_TYPE,
} from '../../../../sdk/core/popups/constants';
import { PopupFindOptions } from '../../../../sdk/core/popups/types';
import { SDKEventListenerConfig } from './types';
import { PopupFilter } from '../find/types';
import {
  countryFilter,
  generateTriggerTypeFilter,
  pageIncludedFilter,
  platformFilter,
  statusFilter,
  subscriberCohortFilter,
} from '../find/filters';
import { findBlockPopup, findLegacyPopup } from '../find';
import { MayHaveSplitTest } from '../../../../services/popup/types';
import { Popup } from '../../../types/popup';

type FindPopupMessageData = {
  type: typeof POPUP_FIND_MESSAGE_TYPE;
  options: PopupFindOptions;
};

const isFindPopupMessage = (
  event: MessageEvent,
): event is MessageEvent<FindPopupMessageData> => {
  if (event?.data?.type !== POPUP_FIND_MESSAGE_TYPE) {
    return false;
  }

  return true;
};

const postFoundMessage = (popupId: string | number | undefined) => {
  window.parent.postMessage(
    {
      type: POPUP_FOUND_MESSAGE_TYPE,
      popupId,
    },
    '*',
  );
};

const buildFiltersForOptions = (options: PopupFindOptions): PopupFilter[] => {
  const filters: PopupFilter[] = [];

  if (options.triggerType) {
    filters.push(generateTriggerTypeFilter(options.triggerType));
  }

  if (options.respectPage) {
    filters.push(pageIncludedFilter);
  }

  if (options.respectSubscriber) {
    filters.push(subscriberCohortFilter);
  }

  if (options.respectCountry) {
    filters.push(countryFilter);
  }

  if (options.respectPlatform) {
    filters.push(platformFilter);
  }

  if (options.respectStatus) {
    filters.push(statusFilter);
  }

  return filters;
};

const DEFAULT_FIND_OPTIONS: PopupFindOptions = {
  triggerType: TRIGGER_TYPES.CUSTOM,
  respectPage: true,
  respectSubscriber: true,
  respectCountry: true,
  respectPlatform: true,
  respectStatus: true,
};

export const generateFindPopupListener =
  (config: SDKEventListenerConfig): ((event: MessageEvent) => Promise<void>) =>
  async (event) => {
    if (!isFindPopupMessage(event)) {
      return;
    }

    const {
      data: { options },
    } = event;

    const findOptions = { ...DEFAULT_FIND_OPTIONS, ...options };

    const { type } = options;

    const filters = buildFiltersForOptions(findOptions);

    const findConfig = {
      splitTestCookies: config.splitTestCookies,
      currentCountry: config.currentCountry,
      origin: config.origin,
      statuses: config.statuses,
      subscriberId: config.subscriberId,
      viewport: config.screenSize,
    };

    if (!type || type === 'standard') {
      const blockPopup = findBlockPopup(
        config.popups.block as (MayHaveSplitTest & BlockPopup)[],
        filters,
        findConfig,
      );

      if (blockPopup) {
        postFoundMessage(blockPopup.id);
        return;
      }
    }

    if (!type || type === 'legacy') {
      const legacyPopup = findLegacyPopup(
        config.popups.legacy as (MayHaveSplitTest & Popup)[],
        filters,
        findConfig,
      );
      if (legacyPopup) {
        postFoundMessage(legacyPopup.id);
        return;
      }
    }

    postFoundMessage(undefined);
  };
