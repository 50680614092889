import { STYLE_RULE_NAMES } from '../../constants';
import { getUniqueStyleRuleValuesInPopup, } from '../styles';
/**
 * Returns whatever string is enclosed in single quotes from a `font-family` CSS
 * declaration. If no single quotes are found, or nothing is found inside them,
 * an empty string is returned.
 *
 * Example return: `'Montserrat'`
 */
export const separateFontFamilyFromFallback = (fontFamilyString) => {
    const match = fontFamilyString.match(/'([^']*)'/);
    return match ? match[1] : '';
};
export const getUniqueFontFamiliesInPopup = ({ stepBlocks, teaserBlocks, theme, viewport, }) => {
    const uniqueFontFamilyStyleRuleValues = getUniqueStyleRuleValuesInPopup({
        stepBlocks,
        teaserBlocks,
        styleRuleName: STYLE_RULE_NAMES.FONT_FAMILY,
        theme,
        viewport,
    });
    const uniqueFontFamilies = uniqueFontFamilyStyleRuleValues.map((fontFamilyStyleRuleValue) => separateFontFamilyFromFallback(fontFamilyStyleRuleValue));
    return uniqueFontFamilies;
};
